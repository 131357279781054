const getRoles = () => {
  return {
    status: 'ok',
    errores: [],
    roles: [
      {
        id: '725182c4-abcd-43c1-ac72-075e4e5c4ddb',
        nombre: '22',
        descripcion: '222',
        privilegios: []
      },
      {
        id: '7cac9caf-71ec-4be2-9ad6-5cce572876da',
        nombre: 'Nuevo Rol Prueba',
        descripcion: 'rol de prueba',
        privilegios: [
          {
            id: '779db497-4a5a-446d-ad43-d858fccdedf8',
            nombre: 'GET_USUARIOS'
          },
          {
            id: '0ee9396f-8e68-4904-9ac5-40cccd5393f5',
            nombre: 'GET_USUARIO'
          },
          {
            id: '4ad12c9a-3b07-4887-a8f6-3994cef4d2f7',
            nombre: 'UPDATE_USUARIO_DATA'
          }
        ]
      },
      {
        id: '947c950f-5759-49cf-8f28-1ed642d51f59',
        nombre: 'Prueba Rol',
        descripcion: 'Rol de prueba',
        privilegios: [
          {
            id: 'df217965-238c-4291-8df4-fd5c7c76950d',
            nombre: 'GET_OPERADOR'
          },
          {
            id: '300b6463-f3dd-4c5a-9285-4adde29a08cf',
            nombre: 'GET_AGENCIA'
          }
        ]
      },
      {
        id: '3302072d-e3a9-405c-9410-c00a7fe8fea5',
        nombre: 'qwerty',
        descripcion: 'rol de test',
        privilegios: [
          {
            id: '724b51bc-624a-4464-adc5-b14090095e80',
            nombre: 'GET_TIPOS_MOVIMIENTOS'
          },
          {
            id: 'b3b76153-17c6-44cf-a0e0-510dfb09a8dc',
            nombre: 'AG_FAV_EJECUTAR_PEND'
          },
          {
            id: 'f6422e7e-2939-44e0-9d83-b91bdaba2165',
            nombre: 'GET_CUPONES'
          }
        ]
      },
      {
        id: 'c2425956-4ef2-4a30-95ed-fd23eb4edee0',
        nombre: 'ROLE_ADMIN',
        descripcion: 'Admin role',
        privilegios: [
          {
            id: '7ae49389-fe5f-4abe-aef0-19fb799bd19e',
            nombre: 'GET_PRIVILEGIOS'
          },
          {
            id: '81cb4d95-e02e-4b4a-9284-fba006440c69',
            nombre: 'GET_JUEGOS'
          },
          {
            id: 'cc21d1e6-065a-42be-bc36-aee36a79ba8f',
            nombre: 'WRITE_PRIVILEGE'
          },
          {
            id: '6ef9a5d7-6fe3-40b2-941c-0d8fbc404263',
            nombre: 'READ_PRIVILEGE'
          }
        ]
      },
      {
        id: 'acac4855-5498-4ed3-851b-8d4b05148999',
        nombre: 'ROLE_OPERA',
        descripcion: 'Opera role',
        privilegios: []
      },
      {
        id: '164a345f-8187-46d4-86c1-1bac36cf87ed',
        nombre: 'ROLE_USER',
        descripcion: 'User role',
        privilegios: [
          {
            id: '6ef9a5d7-6fe3-40b2-941c-0d8fbc404263',
            nombre: 'READ_PRIVILEGE'
          }
        ]
      },
      {
        id: 'ef608047-5015-4d2b-bab2-88590bd28fa7',
        nombre: 'rol prueba 2',
        descripcion: 'prueba',
        privilegios: [
          {
            id: 'e9d9a4e5-ad15-4e4f-b167-32877ecc0614',
            nombre: 'GET_OPERADORES'
          },
          {
            id: '300b6463-f3dd-4c5a-9285-4adde29a08cf',
            nombre: 'GET_AGENCIA'
          },
          {
            id: '1a52218c-6437-4d57-a092-790288ae5888',
            nombre: 'GET_AGENCIAS'
          },
          {
            id: 'b3b76153-17c6-44cf-a0e0-510dfb09a8dc',
            nombre: 'AG_FAV_EJECUTAR_PEND'
          },
          {
            id: '0fa05de4-0d5a-4c7e-845d-38019c4d8248',
            nombre: 'AG_FAV_CAMBIO_MASIVO'
          }
        ]
      },
      {
        id: '3623dd9a-cb2d-40f3-b203-dfad541d7a88',
        nombre: 'ROL PRUEBA 2',
        descripcion: ' 222',
        privilegios: []
      },
      {
        id: '75f567d5-0dd8-4e21-924a-d2fa4af5f394',
        nombre: 'rol prueba 3',
        descripcion: 'rol',
        privilegios: [
          {
            id: 'b3b76153-17c6-44cf-a0e0-510dfb09a8dc',
            nombre: 'AG_FAV_EJECUTAR_PEND'
          },
          {
            id: 'e9d9a4e5-ad15-4e4f-b167-32877ecc0614',
            nombre: 'GET_OPERADORES'
          },
          {
            id: '1a52218c-6437-4d57-a092-790288ae5888',
            nombre: 'GET_AGENCIAS'
          },
          {
            id: '0fa05de4-0d5a-4c7e-845d-38019c4d8248',
            nombre: 'AG_FAV_CAMBIO_MASIVO'
          },
          {
            id: '300b6463-f3dd-4c5a-9285-4adde29a08cf',
            nombre: 'GET_AGENCIA'
          }
        ]
      },
      {
        id: '319f47c1-74f4-4021-94ad-c4f022e393b4',
        nombre: 'weqwewq',
        descripcion: 'qweqwe',
        privilegios: []
      }
    ]
  }
}

export default getRoles
