import { ChangeEvent, SetStateAction } from 'react'

/* PRE-CONDITION: It receives the event from the form and the form setter function.
 * POS-CONDITION: It modify the form data through the function passed.
 */
export function handleOnChange(
  event:
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLSelectElement>
    | ChangeEvent<HTMLTextAreaElement>,
  setFromData: {
    (
      value: SetStateAction<{
        organization: any
        oldAgency: string
        newAgency: string
      }>
    ): void
    (
      value: SetStateAction<{
        organization: any
        oldAgency: string
        newAgency: string
      }>
    ): void
    (
      value: SetStateAction<{
        organization: any
        oldAgency: string
        newAgency: string
      }>
    ): void
    (value: SetStateAction<{ issues: any; observations: string }>): void
    (value: SetStateAction<{ issues: any; observations: string }>): void
    (
      value: SetStateAction<{
        flaws: string
        observations: string
        state: string
      }>
    ): void
    (
      value: SetStateAction<{
        flaws: string
        observations: string
        state: string
      }>
    ): void
    (
      value: SetStateAction<{
        flaws: string
        observations: string
        state: string
      }>
    ): void
    (value: SetStateAction<{ user: string; organization: string }>): void
    (value: SetStateAction<{ user: string; organization: string }>): void
    (arg0: (prevFormData: any) => any): void
  }
) {
  setFromData((prevFormData) => {
    return {
      ...prevFormData,
      [event.target.name]: event.target.value
    }
  })
}

export function convertDateFormat(inputDate: string) {
  const token = inputDate.includes('/') ? '/' : '-'

  const parts = inputDate.split(token)
  let formattedDate = ''

  if (token === '/') formattedDate = `${parts[2]}${parts[1]}${parts[0]}`
  else formattedDate = `${parts[0]}${parts[1]}${parts[2]}`

  return formattedDate
}

export const currencyFormat = (value: number) => {
  let numberFormat = new Intl.NumberFormat('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  })

  return numberFormat.format(value)
}
