// react imports
import { useState } from 'react'
// icons imports
import { AiOutlinePlusCircle } from 'react-icons/ai'
// components imports
import SimpleTable from '../tables/SimpleTable'
// libs imports
import getPrivilege from '../../libs/system/getPrivilege'

const SystemRolesModalContent = ({
  formFields,
  infoRoles,
  isAdditionAction,
  editOperator,
  setRoleData,
  setEditOperator,
  setTriggerPreview
}) => {
  const [optionSelected, setOptionSelected] = useState({ privilege: '' })
  const [editItems, setEditItems] = useState(false)
  const [buttonName, setButtonName] = useState(
    isAdditionAction ? 'Guardar' : 'Editar'
  )

  const tableHeaders = ['Nombre', 'Id']

  const handleAdderOnClick = (event) => {
    event.preventDefault()
    setEditItems(true)
  }

  const handleOnChangeOption = (event) => {
    setOptionSelected({ privilege: event.target.value })
  }

  const listOptions = (getter) => {
    const rawElements = getter()
    const elements = rawElements.privilegios
    return {
      options: elements.map((item, index) => (
        <option key={index} value={item.nombre || item.name}>
          {item.nombre || item.name}
        </option>
      )),
      info: rawElements
    }
  }

  const onClickAdder = (event, rawData) => {
    event.preventDefault()

    const parsedData = rawData.privilegios.find(
      (element) => element.nombre === optionSelected.privilege
    )
    setRoleData((prev) => {
      return {
        ...prev,
        privilegios: [...prev.privilegios, parsedData]
      }
    })
  }

  const adderButton = (name) => {
    let optionData = listOptions(getPrivilege)
    const options = optionData.options
    const rawData = optionData.info
    return (
      editOperator &&
      (!editItems ? (
        <div className="flex--container">
          <button
            title={`Agregar ${name}`}
            className="flex--button button--transparent"
            onClick={(event) => handleAdderOnClick(event)}
          >
            <AiOutlinePlusCircle className="icon--style" />
          </button>
        </div>
      ) : (
        <div className="flex--container">
          <select
            title={`Seleccione un item de ${name}`}
            className="form--input"
            name={name}
            value={optionSelected.privilege}
            onChange={(event) => handleOnChangeOption(event)}
          >
            {options}
          </select>
          <button
            onClick={(event) => onClickAdder(event, rawData, name)}
            className="button--form"
          >
            <AiOutlinePlusCircle className="icon--style" />
          </button>
        </div>
      ))
    )
  }

  const handleOnClickModal = (event) => {
    event.preventDefault()

    if (event.target.name === 'Editar') {
      setEditOperator(true)
      setButtonName('Guardar')
    }

    if (event.target.name === 'Guardar') {
      setButtonName('Editar')
      setTriggerPreview(false)
      if (!isAdditionAction) setEditOperator(false)
    }

    if (event.target.name === 'cancel') {
      setTriggerPreview(false)
      if (!isAdditionAction) setEditOperator(false)
      setButtonName('Editar')
    }
  }

  return (
    <>
      <form>
        <legend> Información de Rol </legend>
        {formFields}
      </form>
      {infoRoles.length > 0 || isAdditionAction ? (
        <SimpleTable
          addButton={adderButton('Privilegio')}
          tableHeaders={tableHeaders}
          tableContent={infoRoles}
          tableName="Información de privilegios"
        />
      ) : (
        <h2>No Privilegios</h2>
      )}
      <div className="buttons--container">
        <button
          name="cancel"
          onClick={(event) => handleOnClickModal(event)}
          className="button--form"
        >
          Cancelar
        </button>
        <button
          name={buttonName}
          onClick={(event) => handleOnClickModal(event)}
          className="button--form"
        >
          {buttonName}
        </button>
      </div>
    </>
  )
}

export default SystemRolesModalContent
