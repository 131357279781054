import GTable from "components/tables/generic/GTable";
import { agencySystem } from "components/tables/table-columns-structure/systemTableColumns";
import React from "react";
import { FaEye } from "react-icons/fa";
import { Status } from "utils/constants";

const ATable = (props: any) => {

  const { itemData, setTriggerFetch, dataStatus, page, setPage } = props

  return (
    <div>
      {dataStatus === Status.success ? (
        <GTable>
          <GTable.Header columns={agencySystem} />
          <GTable.Body
            content={itemData.items}
            columns={agencySystem}
            Icon={FaEye}
            handleOnClick={setTriggerFetch}
          />
          <GTable.Paginator
            data={itemData}
            page={page}
            setPage={setPage}
          />
        </GTable>
      ) : dataStatus === Status.initial ? (
        <p></p>
      ) : (
        <p className="flex--container--message">
          No hay datos disponibles para el rango de fechas ingresada.
        </p>
      )}
    </div>
  )
}

export default ATable