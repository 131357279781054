// React imports
import React, {
  useState,
  useEffect,
  type ReactElement,
  useCallback
} from 'react'

// Third party libraries
import { AnimatePresence } from 'framer-motion'

// Components
import AgencyEdition from '../../../modals/AgencyEdition'
import ErrorHandler from '../../../utils/ErrorHandler'
import Information from '../../../modals/Information'
import AForm from './AForm'
import ATable from './ATable'

// utils imports
import { useTableData } from '../../../utils/custom-hooks/useDataClient'
import { Status } from '../../../utils/constants'

// type import
import {
  type GetError,
  type GetInfo,
  type Agency,
  type FormFieldsAgencies,
  type SystemProps
} from '../SystemTypes'

// service
import systemServices from 'services/system/systemServices'

// ======================= || SYSTEM AGENCY || ===============================//
/**
 * @param setIsLogged - It set whether the user is logged or not
 * @param org - It is the organization selected at the login step
 * @returns - It returns a TSX that renders the agency component
 */
const SystemAgency: React.FC<SystemProps> = ({
  setIsLogged,
  org
}): ReactElement => {

  // Section: Defining constants
  const logoutCode = process.env.REACT_APP_CODIGO_CERRO_SESION ?? '500'

  // Section: Using Custom Hooks
  const { itemData, setItemData, page, setPage } = useTableData()

  // Section: State
  const [dataStatus, setDataStatus] = useState<Status>(Status.initial)
  const [preview, setPreview] = useState<boolean>(false)
  const [agency, setAgency] = useState<Agency | null>(null)
  const [formFields, setFormFields] = useState<FormFieldsAgencies | null>(null)
  const [triggerFetch, setTriggerFetch] = useState<string | null>(null)

  // This status handles the errors
  const [errorModal, setErrorModal] = useState<GetError>({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // This state handles information about the action the user wants to execute.
  const [infoModal, setInfoModal] = useState<GetInfo>({
    hadError: false,
    errorMessage: 'Error conexión',
    isThereInfo: false
  })

  // Section: Services
  const getAgency = useCallback(async (id: string): Promise<void> => {
    systemServices.getAgency(id)
      .then((response) => {
        setAgency({ response, id })
        setPreview(true)
      }).catch((error: any) => {
        const sessionExpired = parseInt(logoutCode)
        if (error.code === sessionExpired) {
          setErrorModal({ hadError: true, errorMessage: error.message })
        } else {
          setInfoModal({
            isThereInfo: true,
            errorMessage: error.message
          })
        }
      })
  }, [logoutCode])

  const getAgencies = async (): Promise<void> => {
    systemServices.getAgencies(formFields!, 0)
      .then((response) => {
        setItemData(response)
      }).catch((error: any) => {
        const sessionExpired = parseInt(logoutCode)
        if (error?.code === sessionExpired) {
          setErrorModal({ hadError: true, errorMessage: error.message })
        } else {
          setInfoModal({
            isThereInfo: true,
            errorMessage: error?.message
          })
        }
      })
  }

  // Section: Effects
  useEffect(() => {
    if (itemData !== null) {
      if (itemData.totalItems !== 0) {
        setDataStatus(Status.success)
      } else {
        setDataStatus(Status.error)
      }
    }
  }, [itemData])

  useEffect(() => {
    if (triggerFetch !== null) {
      void getAgency(triggerFetch)
    }
  }, [getAgency, triggerFetch])

  useEffect(() => {
    if (formFields !== null) {
      systemServices.getAgencies(formFields, page)
        .then((response) => {
          setItemData(response)
        }).catch((error) => {
          setErrorModal({ hadError: true, errorMessage: error.message })
        })
    }
  }, [formFields, page, setItemData])

  // Section: TSX
  return (
    <div className="data--result--container">
      <AForm
        org={org}
        itemData={itemData}
        setItemData={setItemData}
        setErrorModal={setErrorModal}
        setFormFields={setFormFields}
        formFields={formFields}
      />
      <ATable
        itemData={itemData}
        setTriggerFetch={setTriggerFetch}
        dataStatus={dataStatus}
        page={page}
        setPage={setPage}
      />
      <AnimatePresence>
        {preview && (
          <AgencyEdition
            setPreview={setPreview}
            preview={preview}
            data={agency!.response}
            id={agency!.id}
            setErrorModal={setErrorModal}
            setInfoModal={setInfoModal}
            getAgencies={getAgencies}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {errorModal.hadError && (
          <ErrorHandler
            setIsLogged={setIsLogged}
            errorMessage={errorModal.errorMessage}
          />
        )}
        {Boolean(infoModal.isThereInfo) && (
          <Information setInfoModal={setInfoModal} infoModal={infoModal} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SystemAgency
