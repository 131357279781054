import React, { useEffect, useState } from 'react'
import { Table, Form, Button, Modal } from 'react-bootstrap'
import TransferenciaServices from 'services/TransfetenciaService'
import { Motivo, Transferencia } from 'types/Transferencia.types'
import { dateddmmyyyy } from 'utils/formatDate'
import { PiCircleDashedBold } from 'react-icons/pi'
import ModalCustom from 'modals/modal-custom/ModalCustom'
import { STATUS } from 'utils/constants'
import ErrorHandler from 'utils/ErrorHandler'
import { useAppContext } from 'context/AppContext'
import { Paginator } from 'components/paginator/Paginator'
import { currencyFormat } from 'utils/utils'

type Props = {
  organizationId: number | null | undefined
}

const ITEMS_PER_PAGE = 10

const ListadoTransferencias = ({ organizationId }: Props) => {
  const transferenciaServices = new TransferenciaServices()
  const { setIsLogged } = useAppContext()
  const [accion, setAccion] = useState<string | null>(null)
  const [transferencias, setTransferencias] = useState<Transferencia[]>([])
  const [loading, setLoading] = useState(false)
  const [motivosRechazo, setMotivosRechazo] = useState<Motivo[]>([])
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [userExist, setUserExist] = useState({
    status: STATUS.initial,
    message: 'Error de conexión'
  })

  useEffect(() => {
    if (organizationId) {
      getTransferencias()
      getMotivos()
    }
  }, [currentPage])

  const getMotivos = () => {
    organizationId &&
      transferenciaServices
        .getMotivos(organizationId)
        .then((resp) => {
          setMotivosRechazo(resp.motivos)
        })
        .catch((err) => {
          console.log(err)
        })
  }

  const getTransferencias = () => {
    setLoading(true)
    organizationId &&
      transferenciaServices
        .getTransferencias(organizationId, currentPage, ITEMS_PER_PAGE)
        .then((resp) => {
          setTransferencias(resp.items)
          setTotalPages(resp.totalPages)
        })
        .catch((err) => {
          handleError(err)
        })
        .finally(() => {
          setLoading(false)
        })
  }

  const handleError = (error: any) => {
    const sessionExpired =
      error.errores[0].codigo ===
      parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION || '0')

    if (sessionExpired) {
      setUserExist((prev) => ({
        status: STATUS.expired,
        message: error.errores[0].error || prev.message
      }))
    } else {
      setUserExist((prev) => ({
        status: STATUS.error,
        message: error.errores[0].error || prev.message
      }))
    }
  }

  // Manejar selección individual
  const handleCheckboxChange = (id: string) => {
    setTransferencias((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    )
  }

  // Manejar selección de todos los checkboxes
  const handleSelectAll = () => {
    const allSelected = transferencias.every((item) => item.selected)
    setTransferencias((prev) =>
      prev.map((item) => ({ ...item, selected: !allSelected }))
    )
  }

  // Manejar cambios en los motivos
  const handleMotivoChange = (id: string, motivo: string) => {
    setTransferencias((prev) =>
      prev.map((item) => (item.id === id ? { ...item, motivo } : item))
    )
  }

  // Enviar datos
  const handleSubmit = () => {
    setOpenModalConfirm(false)
    const seleccionados = transferencias.filter((item) => item.selected)
    accion && procesar(accion, seleccionados)
  }

  const procesar = (accion: string, seleccionados: Transferencia[]) => {
    setLoading(true)
    if (accion === 'Aprobado') {
      const idList = seleccionados.map((item) => item.id)
      organizationId &&
        transferenciaServices
          .postProcessTransferConfirm(organizationId, idList)
          .then(() => {})
          .catch((err) => {
            handleError(err)
          })
          .finally(() => {
            getTransferencias()
          })
    } else if (accion === 'Rechazado') {
      const dataList = seleccionados.map((item) => ({
        codigoMotivo: item.motivo ?? '',
        pedidoId: item.id
      }))
      organizationId &&
        transferenciaServices
          .postProcessTransferReject({
            codigoOrganizacion: organizationId,
            pedidos: dataList
          })
          .then(() => {})
          .catch((err) => {
            handleError(err)
          })
          .finally(() => {
            getTransferencias()
          })
    }
  }

  const validarBoton = () => {
    const seleccionados = transferencias.filter((item) => item.selected)
    if (accion === 'Rechazado') {
      const motivos = seleccionados.every((item) => item.motivo)
      return seleccionados.length && motivos
    }
    return accion && seleccionados.length
  }

  return (
    <div className="forms--template">
      <div>
        <h1>Transferencias</h1>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 90px)' }}
        >
          <PiCircleDashedBold
            className="icon--spin"
            style={{ fontSize: '100px' }}
          />
        </div>
      ) : (
        <div style={{ marginLeft: '2.25rem', marginRight: '2.25rem' }}>
          <div style={{ borderRadius: '30px', overflow: 'hidden' }}>
            <Table hover className="transfer-custom-table">
              <thead>
                <tr>
                  <th className="text-center align-middle">
                    <Form.Check
                      type="checkbox"
                      checked={transferencias.every((item) => item.selected)}
                      onChange={() => handleSelectAll()}
                    />
                  </th>
                  <th className="text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Select
                        value={accion ?? ''}
                        onChange={(e) => setAccion(e.target.value || null)}
                        style={{ width: '120px' }}
                      >
                        <option value="" disabled>
                          Acción
                        </option>
                        <option value="Aprobado">Aprobado</option>
                        <option value="Rechazado">Rechazado</option>
                      </Form.Select>
                    </div>
                  </th>
                  <th className="text-center align-middle">Usuario</th>
                  <th className="text-center align-middle">Monto</th>
                  <th className="text-center align-middle">CBU</th>
                  <th className="text-center align-middle">CUIL</th>
                  <th className="text-center align-middle">
                    Fecha de Solicitud
                  </th>
                  <th className="text-center align-middle">Motivo</th>
                </tr>
              </thead>
              <tbody className="transparent">
                {transferencias.map((item) => (
                  <tr key={item.id}>
                    <td className="text-center">
                      <Form.Check
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </td>
                    <td></td>
                    <td className="text-center">{`${item.nombre} ${item.apellido}`}</td>
                    <td className="text-end">{currencyFormat(item.importe)}</td>
                    <td className="text-center">{item.cbu}</td>
                    <td className="text-center">
                      {item.cuil.replaceAll('-', '')}
                    </td>
                    <td className="text-center">
                      {dateddmmyyyy(item.fechaPedido)}
                    </td>
                    <td className="text-center">
                      {accion === 'Rechazado' && (
                        <Form.Select
                          value={item.motivo ?? ''}
                          onChange={(e) =>
                            handleMotivoChange(item.id, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Seleccione un motivo
                          </option>
                          {motivosRechazo.map((motivo, index) => (
                            <option key={index} value={motivo.codigo}>
                              {motivo.mensaje}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-center">
            <Paginator
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              totalPages={totalPages}
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setOpenModalConfirm(true)}
              disabled={!validarBoton()}
              variant={validarBoton() ? 'success' : 'secondary'}
              className="mt-3"
              style={{ width: '100px', marginTop: '1rem' }}
            >
              Procesar
            </Button>
          </div>
        </div>
      )}

      {userExist.status === STATUS.expired && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={userExist.message}
        />
      )}

      <ModalCustom
        title="Confirmar acción"
        openModal={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
      >
        <>
          <div className="text-center p-4">
            <h5>{`¿Estas seguro de ${accion === 'Aprobado' ? 'aprobar' : 'rechazar'} esta transferencia?`}</h5>
          </div>
          <Modal.Footer style={{ padding: '0' }}>
            <Button
              onClick={handleSubmit}
              variant="success"
              className="mt-3"
              style={{ width: '100px' }}
            >
              Aceptar
            </Button>
            <Button
              onClick={() => setOpenModalConfirm(false)}
              variant="secondary"
              className="mt-3"
              style={{ width: '100px' }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </>
      </ModalCustom>
    </div>
  )
}

export default ListadoTransferencias
