// locals imports
import { handleOnChange } from '../../utils/utils'
// icons imports
import { BiSolidErrorCircle } from 'react-icons/bi'

const LoginForm = ({ setFromData, postErrors, postStatus, status }) => {
  return (
    <form className="form--container">
      <div className="form--field">
        <label>USUARIO</label>
        <input
          type="text"
          name="user"
          onChange={(event) => handleOnChange(event, setFromData)}
        />
      </div>
      <div className="form--field">
        <label>CLAVE</label>
        <input
          type="password"
          name="password"
          onChange={(event) => handleOnChange(event, setFromData)}
        />
      </div>
      {postStatus === status.error && (
        <p className="pop--message">
          <BiSolidErrorCircle />
          {postErrors.errores[0].error}
        </p>
      )}
    </form>
  )
}

export default LoginForm
