import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import BonusServices from 'services/BonusServices'
import { Bonus } from 'types/Bonus.types'
import { PiCircleDashedBold } from 'react-icons/pi'
import { SubType } from 'utils/enumerators'
import { dateddmmyyyy } from 'utils/formatDate'

type Props = {
  bonusId: string
  handleError: (value: any) => void
}

export const BonusDetails = ({ bonusId, handleError }: Props) => {
  const bonusServices = new BonusServices()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Bonus | null>(null)

  useEffect(() => {
    getBonus()
  }, [])

  const getBonus = () => {
    setLoading(true)
    bonusServices
      .getBonusId(bonusId)
      .then((response) => {
        setData(response)
      })
      .catch((err) => {
        handleError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '18.75rem' }}
    >
      <PiCircleDashedBold
        className="icon--spin"
        style={{ fontSize: '100px' }}
      />
    </div>
  ) : (
    <ListGroup>
      <ListGroup.Item>
        <strong>Nombre:</strong> {data?.nombre}
      </ListGroup.Item>
      <ListGroup.Item>
        <strong>Descripción:</strong> {data?.descripcion}
      </ListGroup.Item>
      <ListGroup.Item>
        <strong>Fecha de Inicio:</strong>{' '}
        {data && dateddmmyyyy(data.fechaInicio)}
      </ListGroup.Item>
      <ListGroup.Item>
        <strong>Fecha de Fin:</strong> {data && dateddmmyyyy(data.fechaFin)}
      </ListGroup.Item>
      <ListGroup.Item>
        <strong>Tipo:</strong> {data?.tipoBonificacion.nombre}
      </ListGroup.Item>
      {data?.monto && (
        <ListGroup.Item>
          <strong>Monto:</strong> {data.monto}
        </ListGroup.Item>
      )}
      {data?.porcentaje && (
        <ListGroup.Item>
          <strong>Porcentaje:</strong> {data.porcentaje}%
        </ListGroup.Item>
      )}
      {data?.subTipo && (
        <ListGroup.Item>
          <strong>SubTipo: </strong>
          {data.subTipo === SubType.INACTIVE_INDISTINCT
            ? 'Indistinto'
            : 'Depositado'}
        </ListGroup.Item>
      )}
      {data?.montoMinimo && (
        <ListGroup.Item>
          <strong>Monto Mínimo:</strong> {data.montoMinimo}
        </ListGroup.Item>
      )}
      {data?.montoMaximo && (
        <ListGroup.Item>
          <strong>Monto Máximo:</strong> {data.montoMaximo}
        </ListGroup.Item>
      )}
      {data?.fechaLimite && (
        <ListGroup.Item>
          <strong>Fecha Límite:</strong> {dateddmmyyyy(data.fechaLimite)}
        </ListGroup.Item>
      )}
      {data?.juegos && data.juegos.length > 0 && (
        <ListGroup.Item>
          <strong>Juegos:</strong>{' '}
          {data.juegos.length
            ? data.juegos.map((item) => item.nombre).join(', ')
            : 'Ninguno'}
        </ListGroup.Item>
      )}
    </ListGroup>
  )
}
