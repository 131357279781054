import { AiFillCloseCircle } from 'react-icons/ai'
import { useEffect, useRef, useState } from 'react'
import { useAnimate, usePresence } from 'framer-motion'
import { FaExclamationCircle } from 'react-icons/fa'
import postCancelExclusion from '../libs/clients/postCancelExclusion'
import postData from '../libs/postData'
import fetchData from '../libs/clients/fetchData'

const CancelExclusion = ({
  cancelExclusion,
  setCancelExclusion,
  walletId,
  setErrorModal,
  requestPath,
  setFormData,
  setInfoModal
}) => {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()
  const reference = useRef()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        cancelExclusion &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setCancelExclusion(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [cancelExclusion])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  // http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/billetera/a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb/autoexclusion/cancelar
  const handleAction = async (event) => {
    event.preventDefault()

    console.log('in here')
    if (event.target.name === 'assertive') {
      const postUrl = process.env.REACT_APP_WS_ANULAR_EXCLUSION.replace(
        ':idBilletera',
        walletId
      )
      try {
        const response = await postData(null, postUrl)
        if (response.status === 'ok') {
          const url = requestPath()
          const refreshData = await fetchData(url)

          setFormData((prev) => {
            return {
              ...prev,
              dataFetched: refreshData
            }
          })
        }
      } catch (error) {
        if (error.code === 179)
          setInfoModal({
            isThereInfo: true,
            errorMessage: error.message
          })
        else
          setErrorModal({
            hadError: true,
            errorMessage: error.message
          })
      }
      // postCancelExclusion('walletId');
    }
    setCancelExclusion(false)
  }

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content--message solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>Anular Exclusión</h3>
          <button onClick={() => setCancelExclusion(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <div className="cancel--container">
            <FaExclamationCircle className="alert--icon" />
            <p>¿Desea anular la exclusión?</p>
          </div>
          <div className="buttons--container">
            <button
              name="negative"
              onClick={(event) => handleAction(event)}
              className="button--form"
            >
              NO
            </button>
            <button
              name="assertive"
              onClick={(event) => handleAction(event)}
              className="button--form"
            >
              SI
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelExclusion
