// react imports
import { useState, useEffect } from 'react'

// libs imports
import getFormattedDate from '../../libs/shared/getFormattedDate'
import getCurrentDate from '../../libs/shared/getCurrentDate'
import getGamesCode from '../../libs/clients/getGameCode'
import getCoupons from '../../libs/clients/getCoupons'

// const imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// components imports
import FormClientResults from '../forms/clients/FormClientResults'
import TableWallet from '../tables/clients/TableWallet'
import Preview from '../../modals/Preview'

// icon imports
import { FaEye } from 'react-icons/fa'
import { AnimatePresence } from 'framer-motion'

// local imports
import { useDataClient } from '../../utils/custom-hooks/useDataClient'
import { useSelectOptions } from '../../utils/custom-hooks/useSelectOptions'

// third party imports
import { useOutletContext } from 'react-router-dom'
import fetchData from '../../libs/clients/fetchData'
import ErrorHandler from '../../utils/ErrorHandler'

// ====================================================================== || COUPONS || ====================================================================================== //

const Coupons = ({ setIsLogged }) => {
  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate(),
    type: 'Todos',
    nuc: ''
  }
  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.coupons.form,
    LOCAL_STORAGE_CLIENTS.coupons.tablePage,
    formStructure
  )

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.coupons.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const [previewData, setPreviewData] = useState(false)

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [preview, setPreview] = useState(false)
  const [options, setOptions] = useState(false)

  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  // It stores the movementTypes to features them as option in the form.
  // To make the fetch it requires the organization cod as parameter.
  const { movementTypes } = useSelectOptions(
    setErrorModal,
    process.env.REACT_APP_WS_CONS_JUEGOS
  )

  // Based on the gamesType it builds the options with this data.
  useEffect(() => {
    if (movementTypes) {
      const buildingOptions = movementTypes.juegos.map((game) => {
        return (
          <option key={game.codigo} value={game.nombre}>
            {game.nombre}
          </option>
        )
      })
      setOptions(buildingOptions)
    }
  }, [movementTypes])

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    },
    {
      type: 'select',
      label: 'Juegos',
      name: 'type',
      default: 'Todos',
      options: options
    },
    {
      type: 'text',
      label: 'Nuc',
      name: 'nuc'
    }
  ]
  const tableHeaders = [
    'Juego',
    'Apuestas',
    'Premios',
    'Estado Sorteo',
    'Fecha Sorteo',
    'Estado',
    'Fecha Emitido',
    'Total Cupón',
    'Numero Cupón',
    'Ver Cupón'
  ]
  const [tableContent, setTableContent] = useState(0)

  // It fetch data from an API
  const codeGames = getGamesCode().juegos

  const getGameName = (code) => {
    const gameName = codeGames.find((game) => game.codigo === code)
    return gameName?.nombre ?? "-"
  }

  const [couponData, setCouponData] = useState(0)

  const handlePreviewOnclick = async (event, id) => {
    event.preventDefault()

    const url = process.env.REACT_APP_WS_VER_CUPON.replace(':idCupon', id)

    try {
      const data = await fetchData(url)
      setPreviewData(data)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
      console.error(error)
    }
  }

  useEffect(() => {
    if (previewData) {
      setCouponData(previewData)
    }
  }, [previewData])

  useEffect(() => {
    if (couponData) {
      setPreview(true)
    }
  }, [couponData])

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((coupon, index) => {
        return (
          <tr
            key={coupon.id}
            className={index % 2 === 0 ? 'add--row--color' : ''}
          >
            <td>{getGameName(coupon.codigoJuego)}</td>
            <td className="item--center"> {coupon.cantidadApuestas} </td>
            <td className="item--center"> {coupon.importeGanado}</td>
            <td className="item--center">
              {' '}
              <input
                className="checkbox--table"
                type="checkbox"
                defaultChecked={coupon.sorteo.sorteado ? 'checked' : ''}
                disabled
              />{' '}
            </td>
            <td className="item--center">
              {' '}
              {getFormattedDate(coupon.sorteo.fechaEvento)}
            </td>
            <td className="item--center"> {coupon.estadoCupon}</td>
            <td className="item--center"> {getFormattedDate(coupon.fecha)}</td>
            <td className="item--center"> {coupon.total}</td>
            <td className="item--center"> {coupon.nuc}</td>
            <td className="item--center">
              {' '}
              <button
                className="button--preview"
                onClick={(event) => handlePreviewOnclick(event, coupon.id)}
              >
                {' '}
                <FaEye className="preview--icon" />{' '}
              </button>{' '}
            </td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const requestPath = (dataHolder) => {
    const walletId = data[0].billeteraId
    const types = movementTypes.juegos

    let typeSelected =
      dataHolder.type === 'Todos'
        ? ''
        : types.find((item) => item.nombre === dataHolder.type)
    const urlType = typeSelected ? `?codigoJuego=${typeSelected.codigo}&` : '?'
    const couponNumber = dataHolder.nuc ? `&nuc=${dataHolder.nuc}` : ''

    const parseUrl =
      process.env.REACT_APP_WS_CONS_CUPONES_BILLETERA.replace(
        ':idBilletera',
        walletId
      ) + urlType
    const urlDateReplaced = `desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`

    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: '',
      extra: couponNumber
    }

    setCommonUrl(url)

    localStorage.setItem(LOCAL_STORAGE_CLIENTS.coupons.url, JSON.stringify(url))

    const response = `${parseUrl}${urlDateReplaced}${0}${couponNumber}`

    return response
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          fetchData={getCoupons}
          storageName={LOCAL_STORAGE_CLIENTS.coupons.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para el rango de fechas ingresada.
          </p>
        )}
        <AnimatePresence>
          {preview && (
            <Preview
              setPreview={setPreview}
              preview={preview}
              couponData={couponData}
            />
          )}
        </AnimatePresence>
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </>
  )
}

export default Coupons
