// react imports
import { useEffect, useRef, useState } from 'react'

// react animation imports
import { useAnimate, usePresence } from 'framer-motion'

// icons imports
import { AiFillCloseCircle } from 'react-icons/ai'

// libs imports
import postData from '../libs/postData'

// utils imports
import { handleOnChange } from '../utils/utils'

// ====================================================================== || EDIT RECLAIM || ====================================================================================== //

const EditReclaim = ({
  editReclaim,
  setEditReclaim,
  flaws,
  reclaimId,
  setInfoModal,
  setErrorModal
}) => {
  // defining states
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        editReclaim &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setEditReclaim(false)
    }

    document.body.addEventListener('click', collapse)

    return () => document.body.removeEventListener('click', collapse)
  }, [editReclaim])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  const [dataForm, setDataForm] = useState({
    flaws: '',
    observations: '',
    state: ''
  })

  const reclaimEdition = async () => {
    const url = process.env.REACT_APP_WS_VER_RECLAMO.replace(
      ':idReclamo',
      reclaimId
    )

    const body = {
      abierto: dataForm.state,
      fallaId: dataForm.flaws,
      observaciones: dataForm.observations,
      problemaId: null
    }

    try {
      await postData(body, url)
      setEditReclaim(false)
    } catch (error) {
      setEditReclaim(false)
      if (error.code === process.env.REACT_APP_CODIGO_CERRO_SESION)
        setErrorModal({ hadError: true, errorMessage: error.message })
      else
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
    }
  }

  const handleAction = (event) => {
    event.preventDefault()

    if (event.target.name === 'send') reclaimEdition()
    else setEditReclaim(false)
  }

  const options = (() =>
    flaws.fallas.map((item, index) => (
      <option key={index} value={item.id}>
        {item.nombre}
      </option>
    )))()

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>Editar reclamo</h3>
          <button onClick={() => setEditReclaim(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <form>
            <div className="field--form form--row">
              <label className="form--label">Seleccione fallas</label>
              <select
                className="form--input"
                name="flaws"
                value={dataForm.flaws}
                onChange={(event) => handleOnChange(event, setDataForm)}
              >
                <option className="option--default" value="">
                  --Seleccione opción--
                </option>
                {options}
              </select>
            </div>
            <div className="field--form form--row">
              <label className="form--label">Observaciones</label>
              <textarea
                className="form--textarea form--input--modal"
                name="observations"
                onChange={(event) => handleOnChange(event, setDataForm)}
                value={dataForm.observations}
                placeholder="Observaciones"
                type="text"
              />
            </div>
            <div className="field--form form--row">
              <label className="form--label">Seleccione estado</label>
              <select
                className="form--input"
                name="state"
                value={dataForm.state}
                onChange={(event) => handleOnChange(event, setDataForm)}
              >
                <option className="option--default" value="">
                  --Seleccione opción--
                </option>
                <option value="true">Abierto</option>
                <option value="false">Cerrado</option>
              </select>
            </div>
          </form>
          <div className="buttons--container">
            <button
              name="send"
              onClick={(event) => handleAction(event)}
              className="button--form"
            >
              Enviar
            </button>
            <button
              name="cancel"
              onClick={(event) => handleAction(event)}
              className="button--form"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditReclaim
