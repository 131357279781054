export enum BonusType {
  BONF_REGISTER = 'BONF_REGISTER',
  BONF_INACTIVE = 'BONF_INACTIVE',
  BONF_DEPOSIT = 'BONF_DEPOSIT',
  BONF_REWARD = 'BONF_REWARD'
}

export enum SubType {
  INACTIVE_DEPOSITED = 'INACTIVE_DEPOSITED',
  INACTIVE_NOTDEPOSITED = 'INACTIVE_NOTDEPOSITED',
  INACTIVE_INDISTINCT = 'INACTIVE_INDISTINCT'
}
