import GTable from "components/tables/generic/GTable";
import React from "react";
import { FaEye } from "react-icons/fa";
import { STATUS } from "utils/constants";

const STable = (props: any) => {
  const {
    showTable,
    operatorsSystem,
    itemData,
    handlePreviewOnclick,
    page,
    setPage
  } = props

  // TSX
  return (
    <div>
      {showTable === STATUS.success ? (
        <GTable>
          <GTable.Header
            columns={operatorsSystem}
          />
          <GTable.Body
              content={itemData.items}
              columns={operatorsSystem}
              Icon={FaEye}
              handleOnClick={handlePreviewOnclick}
          />
          {itemData !== null && itemData.totalPages !== 1 &&
            <GTable.Paginator 
              data={itemData}
              page={page}
              setPage={setPage}
            />
          }
        </GTable>
      ): showTable === STATUS.initial ? (
        <p></p>
      ) : (
        <p className="flex--container--message">
          No hay datos disponibles para la información ingresada.
        </p>
      )}
    </div>
  )
}

export default STable