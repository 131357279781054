// react imports
import { useState, useEffect } from 'react'

/* this custom hook handles the data gotten from the endpoint in order to storage it in the localStorage to access if when the
   component is re-render.
*/

function validDataFromLocal(storageKey: string): any {
  const localData =
    localStorage.getItem(storageKey) != null
      ? localStorage.getItem(storageKey)
      : null

  const result = localData !== null ? JSON.parse(localData) : null

  return result
}

type ItemData = {
  dataForm: any
  dataFetched: any
}

export const useDataClient = (
  formStorage: string,
  pageStorage: string,
  formStructure: {
    fromDate?: string
    toDate?: string
    state?: string
    type?: string
    nuc?: string
    dataForm?: string
    organization?: string
    agencyCode?: string
    location?: string
    status?: string
  }
): any => {
  const dataPackage = validDataFromLocal(formStorage)
  const storagePage = validDataFromLocal(pageStorage)

  const [itemData, setItemData] = useState<ItemData>({
    dataForm: formStructure,
    dataFetched: dataPackage !== null ? dataPackage.dataFetched : null
  })

  const pageValue = storagePage !== null || 0

  const [page, setPage] = useState(pageValue)

  useEffect(() => {
    localStorage.setItem(pageStorage, JSON.stringify(page))
  }, [page, pageStorage])

  return {
    itemData,
    setItemData,
    page,
    setPage
  }
}

export const useTableData = (
): any => {

  const [itemData, setItemData] = useState<any>(null)
  const [page, setPage] = useState<any>(0)

  return {
    itemData,
    setItemData,
    page,
    setPage
  }
}
