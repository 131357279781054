import { useAnimate, usePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { AiFillWarning } from 'react-icons/ai'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { BiSolidErrorCircle } from 'react-icons/bi'

const ModalWarning = (props) => {
  // Hook that controls the modal behavior.
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        props.preview &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) props.setPreview(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [props.preview])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  return (
    <div id="modal-container" className="modal--container--warning" ref={scope}>
      <div className="modal--content--warning " ref={reference}>
        <div className="coupon--title--warning">
          <AiFillWarning className="warning--icon" />
          {/* <h3>{props.title}</h3> */}
        </div>
        <div className="solver--data">{props.children}</div>
      </div>
    </div>
  )
}

export default ModalWarning
