// react imports
import { useState } from 'react'

// local components imports
import ClientOptions from '../side-nav-bar/clients/ClientOptions'

// local libs
import getOptionsNavBarClient from '../../libs/clients/getOptionsNavBarClient'
import fetchData from '../../libs/clients/fetchData'

// third party libraries imports
import { Outlet, useLoaderData } from 'react-router'
import { useNavigate } from 'react-router'

/* This component shows all the data of the client found enabling a nav-bar and a section showing the nav-bar item selected. */
const ClientResult = () => {
  // This is how this component gets the data fetched by the loader.
  const rawData = useLoaderData()
  const [data, setData] = useState(rawData)

  localStorage.setItem(
    'userOrgCod',
    JSON.stringify(data.dataBilletera[0].codigoOrganizacion)
  )

  const navigate = useNavigate()

  // It sets the options contains in the nav-bar.
  const [options, setOptions] = useState(getOptionsNavBarClient)

  // It composes the option feature in the nav-bar.
  const clientOptions = options.map((element, index) => {
    return (
      <button
        key={index}
        onClick={() => handleOnclick(element)}
        className={
          element.clicked
            ? 'client--buttons selected--button'
            : 'client--buttons'
        }
      >
        {element.name}
      </button>
    )
  })

  // It handles the actions if the user click on of the options.
  const handleOnclick = (element) => {
    const optionClicked = options.find((item) => item.name === element.name)
    const path = optionClicked.path
    navigate(path)
    setOptions((options) => {
      const newOptions = options.map((option) => {
        return {
          ...option,
          clicked: option.name === element.name ? true : false
        }
      })
      return newOptions
    })
  }

  return (
    <div className="client--info--container">
      <div className="client--container--header">
        <h2 className="client--container--title">
          {`${data.nombre} ${data.apellido}`}
        </h2>
      </div>
      <div className="client--result--container">
        <div className="sideNavbar--list sideNavbar--list--clients">
          {clientOptions}
        </div>
        <Outlet context={[data, setData]} />
      </div>
    </div>
  )
}

export default ClientResult

// Getting the client's data before rendering this component.
export const clientLoader = async (params, key) => {
  const { id } = params
  const tokenToReplace = ':idCliente'
  const newToken = id
  const baseUrl = process.env.REACT_APP_WS_DATOS_USUARIO
  const url = baseUrl.replace(tokenToReplace, newToken)

  try {
    return await fetchData(url)
  } catch ({ message }) {
    throw Error(message)
  }
}
