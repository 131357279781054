// react imports
import { useEffect, useRef } from 'react'

// animation imports
import { useAnimate, usePresence } from 'framer-motion'

// icons imports
import { AiFillCloseCircle } from 'react-icons/ai'

// local libs imports
import getFormattedDate from '../libs/shared/getFormattedDate'

// ====================================================================== || VIEW RECLAIM || ====================================================================================== //

const ReclaimView = ({ previewReclaim, setPreviewReclaim, data }) => {
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        previewReclaim &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setPreviewReclaim(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [previewReclaim])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>View Reclaim</h3>
          <button onClick={() => setPreviewReclaim(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <div className="reclaim--content">
            <div className="reclaim--section">
              <h3>Detalles de reclamo</h3>
              <div>
                <div className="reclaim--field">
                  <span>Estado</span>{' '}
                  <span>{data.abierto ? '[Abierto]' : '[Cerrado]'}</span>
                </div>
                <div className="reclaim--field">
                  <span>Fecha de apertura </span>
                  <span>{getFormattedDate(data.fechaApertura)}</span>
                </div>
                <div className="reclaim--field">
                  <span>Fecha de modificación</span>
                  <span>{getFormattedDate(data.fechaModificacion)}</span>
                </div>
              </div>
            </div>
            <div className="reclaim--section">
              <h3>Detalle falla</h3>
              <div className="reclaim--field">
                <span>Error ID</span> <span>{data.falla.id}</span>
              </div>
              <div className="reclaim--field">
                <span>Nombre Error</span>
                <span>{data.falla.nombre}</span>
              </div>
            </div>
            <div className="reclaim--section">
              <h3>Observaciones</h3>
              <div>
                <div className="reclaim--field">
                  <span>Fecha</span>
                  <span>{getFormattedDate(data.detalles[0].fecha)}</span>
                </div>
                <div className="reclaim--field">
                  <span>Operador</span>
                  <span>{`${data.detalles[0].operador.nombre} ${data.detalles[0].operador.apellido} `}</span>
                </div>
                <div className="reclaim--field">
                  <span>Observaciones</span>
                  <span>{data.detalles[0].observaciones}</span>
                </div>
              </div>
            </div>
            <div className="reclaim--section">
              <h3>Informacion de usuario</h3>
              <div className="reclaim--field">
                <span>Usuario</span>
                <span>{`${data.usuario.nombre} ${data.usuario.apellido} `}</span>
              </div>
              <div className="reclaim--field">
                <span>Documento</span>
                <span>{`${data.usuario.documento} (${data.usuario.tipoDocumento})`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReclaimView
