const getOrganizations = () => {
  return [
    {
      name: 'TEST SAL - Oracle Cloud',
      id: 1001,
      codigo: 53,
      descripcion: 'TEST SAL - Oracle Cloud'
    },
    {
      name: 'TEST NQN - Oracle cloud',
      id: 1002,
      codigo: 42,
      descripcion: 'TEST NQN - Oracle cloud'
    },
    {
      name: 'TEST SC - Oracle Cloud',
      id: 1003,
      codigo: 41,
      descripcion: 'TEST SC - Oracle Cloud'
    },
    {
      name: 'TEST COR - Oracle Cloud',
      id: 1004,
      codigo: 50,
      descripcion: 'TEST COR - Oracle Cloud'
    },
    {
      name: 'TEST RN - Oracle Cloud',
      id: 1005,
      codigo: 43,
      descripcion: 'TEST RN - Oracle Cloud'
    }
  ]
}

export default getOrganizations
