// react imports
import React, { useEffect, useState } from 'react'

// styles
import './styles/global.scss'

// third party imports
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from 'react-router-dom'

// pages imports
import CustomReports from './components/pages/CustomReports'
import Clients from './components/pages/Clients'
import Home from './components/pages/Home'
import Login from './components/pages/Login'
import MassiveChange from './components/pages/MassiveChange'
import System from './components/pages/system/System'

// component imports
import ClientResult, { clientLoader } from './components/clients/ClientResult'
import FormClients from './components/forms/clients/FormClients'
import ErrorHandler from './utils/ErrorHandler'
import PrivateRoute from './utils/PrivateRoutes'
import Root from './components/Root'

// json import
import packageJson from '../package.json'

// component client import
import AgenciesChange from './components/clients/AgenciesChange'
import Coupons from './components/clients/Coupons'
import Exclusion from './components/clients/Exclusion'
import PersonalData from './components/clients/PersonalData'
import Reclaims from './components/clients/Reclaims'
import SystemAgency from './components/system/agency/SystemAgency'
import UfiList from './components/clients/UfiList'
import Wallet from './components/clients/wallet/Wallet'
import WithdrawalRequests from './components/clients/WithdrawalRequests'

// types importing
import type { UserData } from 'types/userData'
import SystemOperators from 'components/system/operators/SystemOperators'
import SystemRoles from 'components/system/systemRoles/SystemRoles'
import { BonusManager } from 'components/pages/bonus-manager/BonusManager'
import { useAppContext } from 'context/AppContext'
import { TransferenciasManager } from 'components/pages/transferencias-manager/TransferenciasManager'
// Project version gotten from the package json.
const version = ((global as any).appVersion = packageJson.version)

export const RouterMain = () => {
  const { setOrganizations, isLogged, setIsLogged } = useAppContext()

  useEffect(() => {
    const localData = localStorage.getItem('logged')
    const userState: boolean =
      localData !== null ? JSON.parse(localData) : false
    setIsLogged(userState)
  }, [])

  // user data
  const [userData, setUserData] = useState<UserData>({
    nombre: '',
    key: '',
    organizaciones: [{ codigo: 0, value: ' ', descripcion: ' ' }]
  })

  // Section: Effects
  useEffect(() => {
    sessionStorage.setItem('keySession', JSON.stringify(userData?.key))
    setOrganizations(userData?.organizaciones || [])
  }, [userData])

  // Using localStorage to store the user state
  useEffect(() => {
    const value = JSON.stringify(isLogged)
    localStorage.setItem('logged', value)
  }, [isLogged])

  // Section: Functions
  // Mocking the behavior that close the session after passed a the set time
  /*   setTimeout(() => {
    localStorage.removeItem('logged')
  }, 5000) */

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Root name={userData?.nombre} version={version} />}
      >
        <Route
          path="/login"
          element={
            <Login setIsLogged={setIsLogged} setUserData={setUserData} />
          }
        />
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/clientes" element={<Clients />}>
            <Route
              index
              element={<FormClients organizations={userData?.organizaciones} />}
            />
            <Route
              path="cliente-id/:id"
              loader={async ({ params }): Promise<any> => {
                return await clientLoader(params, userData?.key)
              }}
              element={<ClientResult />}
              errorElement={
                <ErrorHandler setIsLogged={setIsLogged} errorMessage={''} />
              }
            >
              <Route
                index
                element={<PersonalData setIsLogged={setIsLogged} />}
              />
              <Route
                path="billetera"
                element={<Wallet setIsLogged={setIsLogged} />}
              />
              <Route
                path="cupones"
                element={<Coupons setIsLogged={setIsLogged} />}
              />
              <Route
                path="solicitud-retiros"
                element={<WithdrawalRequests setIsLogged={setIsLogged} />}
              />
              <Route
                path="cambio-agencias"
                element={<AgenciesChange setIsLogged={setIsLogged} />}
              />
              <Route
                path="listado-uif"
                element={<UfiList setIsLogged={setIsLogged} />}
              />
              <Route
                path="exclusion"
                element={<Exclusion setIsLogged={setIsLogged} />}
              />
              <Route
                path="reclamos"
                element={<Reclaims setIsLogged={setIsLogged} />}
              />
            </Route>
          </Route>

          <Route path="/bonus" element={<BonusManager />} />
          <Route path="/transferencias" element={<TransferenciasManager />} />
          <Route
            path="/cambio-masivos"
            element={
              <MassiveChange
                setIsLogged={setIsLogged}
                organizations={userData?.organizaciones}
              />
            }
          />
          <Route
            path="/reportes-a-medida"
            element={
              <CustomReports
                setIsLogged={setIsLogged}
                organizations={userData?.organizaciones}
              />
            }
          />
          <Route path="/sistemas" element={<System />}>
            <Route
              index
              element={
                <SystemAgency
                  setIsLogged={setIsLogged}
                  org={userData.organizaciones}
                />
              }
            />
            <Route
              path="operadores"
              element={
                <SystemOperators
                  setIsLogged={setIsLogged}
                  org={userData.organizaciones}
                />
              }
            />
            <Route path="roles" element={<SystemRoles />} />
          </Route>
        </Route>
      </Route>
    )
  )

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}
