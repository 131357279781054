import React, { useEffect, useState } from 'react'
import TablaTransferencias from './ListadoTransferencias'
import { useAppContext } from 'context/AppContext'

export const TransferenciasManager = () => {
  const { organizations } = useAppContext()
  const [organizationSelected, setOrganizationSelected] = useState<
    number | null | undefined
  >()

  useEffect(() => {
    const orgFound = organizations.find((org) => org.codigo === 53)
    setOrganizationSelected(orgFound?.codigo)
  }, [])

  return (
    organizationSelected && (
      <TablaTransferencias organizationId={organizationSelected} />
    )
  )
}
