import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import BonusServices from 'services/BonusServices'
import {
  BonusRequest,
  JuegoDisponible,
  SubTipo,
  Types,
  UserName
} from 'types/Bonus.types'
import { BonusType, SubType } from 'utils/enumerators'
import Select, { MultiValue } from 'react-select'
import { PiCircleDashedBold } from 'react-icons/pi'
//import { BsExclamationCircle } from 'react-icons/bs'

type Props = {
  closeModal: () => void
  getBonusList: () => void
  organizationCode: number | undefined
  handleError: (value: any) => void
}

export type FormValues = {
  name: string
  description: string
  startDate: Date
  endDate: Date
  type: string
  monto: number
  porcentaje: number
  subTipo: string
  montoMinimo: number
  montoMaximo: number
  usarMonto: string
  fechaLimite: Date
  juegos: string[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es obligatorio'),
  description: Yup.string().required('El tipo es obligatorio'),
  startDate: Yup.date().required('Fecha de inicio es obligatoria'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'Debe ser posterior a la fecha de inicio')
    .required('Fecha de fin es obligatoria'),
  type: Yup.string().required('El tipo es requerido'),
  monto: Yup.number().when('type', {
    is: (val: string) => val !== 'Premio',
    then: (schema) => schema.required('El monto es requerido'),
    otherwise: (schema) => schema.notRequired()
  }),
  porcentaje: Yup.number().when('type', {
    is: (val: string) => val === 'Deposito' || val === 'Premio',
    then: (schema) => schema.required('El porcentaje es requerido'),
    otherwise: (schema) => schema.notRequired()
  }),
  subTipo: Yup.string().when('type', {
    is: (val: string) => val === 'Incentivo',
    then: (schema) => schema.required('El estado de deposito es requerido'),
    otherwise: (schema) => schema.notRequired()
  }),
  fechaLimite: Yup.date().when('type', {
    is: (val: string) => val === 'Incentivo',
    then: (schema) => schema.required('La fecha de ultima jugada es requerida'),
    otherwise: (schema) => schema.notRequired()
  }),
  montoMinimo: Yup.number().when('type', {
    is: (val: string) => val === 'Deposito',
    then: (schema) => schema.required('El monto minimo es requerido'),
    otherwise: (schema) => schema.notRequired()
  }),
  montoMaximo: Yup.number().when('type', {
    is: (val: string) => val === 'Depsito' || val === 'Premio',
    then: (schema) => schema.required('El monto maximo es requerido'),
    otherwise: (schema) => schema.notRequired()
  }),
  juegos: Yup.array().when('type', {
    is: (val: string) => val === 'Premio',
    then: (schema) =>
      schema
        .min(1, 'Debes seleccionar al menos una opción')
        .required('El juego es requerido'),
    otherwise: (schema) => schema.notRequired()
  })
})

const CreateBonusForm = ({
  closeModal,
  getBonusList,
  organizationCode,
  handleError
}: Props) => {
  const bonusServices = new BonusServices()
  const [typeList, setTypeList] = useState<Types[]>([])
  const [subTypes, setSubTypes] = useState<SubTipo[]>([])
  const [gameList, setGameList] = useState<JuegoDisponible[]>([])
  const [typeSelected, setTypeSelected] = useState('')
  const [fechaLimit, setFechaLimite] = useState('')
  const [subType, setSubType] = useState(SubType.INACTIVE_INDISTINCT.toString())
  const [userNameList, setUserNameList] = useState<UserName[]>([])
  const [loadingUserEmail, setLoadingUserEmail] = useState(false)

  useEffect(() => {
    fetchTypes()
  }, [])

  useEffect(() => {
    BonusType.BONF_REWARD === typeSelected &&
      organizationCode &&
      fetchGames(organizationCode)
  }, [organizationCode, typeSelected])

  useEffect(() => {
    fechaLimit && subType && fetchUsers()
  }, [fechaLimit, subType])

  const initialValues: FormValues = {
    name: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    type: BonusType.BONF_REGISTER,
    monto: 0,
    porcentaje: 0,
    subTipo: SubType.INACTIVE_INDISTINCT,
    montoMinimo: 0,
    montoMaximo: 0,
    usarMonto: 'monto',
    fechaLimite: new Date(),
    juegos: []
  }

  const fetchTypes = () => {
    bonusServices
      .getTypes()
      .then((response) => {
        setTypeList(response)
        setSubTypes(
          response.find((type) => type.codigo === BonusType.BONF_INACTIVE)
            ?.subTipo || []
        )
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const fetchGames = (code: number) => {
    bonusServices
      .getGames(code)
      .then((response) => {
        const list: JuegoDisponible[] = response.juegos.map((juego) => ({
          value: juego.codigo.toString(),
          label: juego.nombre
        }))
        setGameList(list)
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const fetchUsers = () => {
    setLoadingUserEmail(true)
    if (organizationCode) {
      bonusServices
        .getUsersInTypeInactive(organizationCode, fechaLimit, subType)
        .then((response) => {
          setUserNameList(response)
        })
        .catch((error) => {
          handleError(error)
        })
        .finally(() => {
          setLoadingUserEmail(false)
        })
    } else {
      console.error('Organization code or fechaLimit is undefined')
    }
  }

  const handleSubmit = (values: FormValues) => {
    const bonustype = typeList.find((tipo) => tipo.codigo === values.type)

    if (!organizationCode) {
      console.error('Organization code is undefined')
      return
    }

    let bonus: BonusRequest = {
      organizacionCodigo: organizationCode,
      nombre: values.name,
      descripcion: values.description,
      fechaInicio: new Date(values.startDate),
      fechaFin: new Date(values.endDate),
      tipoBonificacionId: bonustype ? bonustype.id : ''
    }
    switch (values.type) {
      case BonusType.BONF_REGISTER:
        bonus = { ...bonus, monto: values.monto }
        break
      case BonusType.BONF_INACTIVE:
        bonus = {
          ...bonus,
          monto: values.monto,
          fechaLimite: new Date(values.fechaLimite),
          subTipo: values.subTipo
        }
        break
      case BonusType.BONF_DEPOSIT:
        bonus = {
          ...bonus,
          subTipo: values.subTipo,
          montoMinimo: values.montoMinimo
        }
        if (values.usarMonto === 'monto') {
          bonus = { ...bonus, monto: values.monto }
        } else {
          bonus = {
            ...bonus,
            porcentaje: values.porcentaje,
            montoMaximo: values.montoMaximo
          }
        }
        break
      case BonusType.BONF_REWARD:
        bonus = {
          ...bonus,
          porcentaje: values.porcentaje,
          montoMaximo: values.montoMaximo,
          juegosCodigo: values.juegos.map((juego) => Number(juego))
        }
        break

      default:
        break
    }
    bonusServices
      .postBonus(bonus)
      .then(() => {
        getBonusList()
      })
      .catch((error) => {
        handleError(error)
      })
    closeModal()
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLSelectElement>) => {
    const option =
      event.currentTarget.options[event.currentTarget.selectedIndex]
    option.title = option.getAttribute('data-title') || ''
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, isSubmitting, setFieldValue }) => {
        const handleGames = (selectedOptions: MultiValue<JuegoDisponible>) => {
          const selectedValues = selectedOptions
            ? selectedOptions.map((option) => option.value)
            : []

          if (selectedValues.includes('all')) {
            if (selectedValues.length === gameList.length) {
              // Si ya están todos seleccionados, quitamos la selección
              setFieldValue('juegos', [])
            } else {
              // Seleccionar todos menos la opción "all"
              setFieldValue(
                'juegos',
                gameList
                  .map((juego) => juego.value)
                  .filter((value) => value !== 'all')
              )
            }
          } else {
            // Si no seleccionaron "all", actualizar normalmente
            setFieldValue('juegos', selectedValues)
          }
        }

        return (
          <FormikForm>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre</Form.Label>
                  <Field
                    name="name"
                    as={Form.Control}
                    placeholder={'Ingrese un nombre'}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Descripción</Form.Label>
                  <Field
                    name="description"
                    as={Form.Control}
                    placeholder={'Ingrese una descripción'}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha Inicio</Form.Label>
                  <Field name="startDate" type="date" as={Form.Control} />
                  <ErrorMessage
                    name="startDate"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha Fin</Form.Label>
                  <Field name="endDate" type="date" as={Form.Control} />
                  <ErrorMessage
                    name="endDate"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>Tipo</Form.Label>
                  <Field
                    name="type"
                    as="select"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue('type', e.target.value)
                      setTypeSelected(e.target.value)
                    }}
                  >
                    {typeList.map((tipo: Types) => (
                      <option key={tipo.id} value={tipo.codigo}>
                        {tipo.nombre}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
            </Row>

            {values.type === BonusType.BONF_REGISTER && (
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto</Form.Label>
                    <Field
                      name="monto"
                      type="number"
                      as={Form.Control}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'monto',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="monto"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {values.type === BonusType.BONF_INACTIVE && (
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto</Form.Label>
                    <Field
                      name="monto"
                      type="number"
                      as={Form.Control}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'monto',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="monto"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Fecha de ultima jugada</Form.Label>
                    <Field
                      name="fechaLimite"
                      type="date"
                      as={Form.Control}
                      disabled={loadingUserEmail}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange({
                          target: {
                            name: 'fechaLimite',
                            value: e.target.value
                          }
                        })
                        setFechaLimite(e.target.value)
                      }}
                    />
                    <ErrorMessage
                      name="fechaLimite"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Estado depósito</Form.Label>
                    <Field
                      name="subTipo"
                      as="select"
                      className="form-control"
                      disabled={loadingUserEmail}
                      onMouseOver={handleMouseOver}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChange({
                          target: {
                            name: 'subTipo',
                            value: e.target.value
                          }
                        })
                        setSubType(e.target.value)
                      }}
                    >
                      {subTypes.map((subType) => (
                        <option
                          key={subType.codigo}
                          value={subType.codigo}
                          data-title={subType.descripcion}
                        >
                          {subType.titulo}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="subTipo"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <label style={{ padding: '10px 0px 5px 0px' }}>
                    Usuarios
                  </label>
                  {loadingUserEmail ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <PiCircleDashedBold
                        className="icon--spin"
                        style={{ fontSize: '100px' }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        height: '10rem',
                        border: 'solid 1px #cccccc',
                        borderRadius: '10px',
                        padding: '10px'
                      }}
                    >
                      {userNameList.map((item) => (
                        <Row>
                          <Col md={6}>
                            <label>{`${item.nombre} ${item.apellido}`}</label>
                          </Col>
                          <Col md={6}>
                            <label>{item.email}</label>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            )}

            {values.type === BonusType.BONF_REWARD && (
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Porcentaje</Form.Label>
                    <Field
                      name="porcentaje"
                      type="number"
                      as={Form.Control}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'porcentaje',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="porcentaje"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto maximo</Form.Label>
                    <Field
                      name="montoMaximo"
                      type="number"
                      as={Form.Control}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'montoMaximo',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="montoMaximo"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <div>
                    <label htmlFor="juegos" className="pt-3 pb-2">
                      Selecciona los Juegos
                    </label>
                    <Field name="juegos">
                      {({ field }: any) => (
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Elegir juegos"
                          {...field}
                          isMulti
                          options={gameList}
                          value={gameList.filter((option) =>
                            field.value.includes(option.value)
                          )}
                          onChange={handleGames}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="juegos"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
              </Row>
            )}

            {values.type === BonusType.BONF_DEPOSIT && (
              <Row>
                <Col md={12}>
                  {/* Radio Buttons para elegir entre Monto y Porcentaje */}
                  <Form.Group className="mt-3">
                    <Form.Label>Selecciona qué usar:</Form.Label>
                    <div>
                      <Form.Check
                        type="radio"
                        label="Usar Monto"
                        name="usarMonto"
                        value="monto"
                        checked={values.usarMonto === 'monto'}
                        onChange={() => {
                          setFieldValue('usarMonto', 'monto')
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="Usar Porcentaje"
                        name="usarMonto"
                        value="porcentaje"
                        checked={values.usarMonto === 'porcentaje'}
                        onChange={() => {
                          setFieldValue('usarMonto', 'porcentaje')
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto minimo a depositar</Form.Label>
                    <Field
                      name="montoMinimo"
                      type="number"
                      as={Form.Control}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'montoMinimo',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="montoMinimo"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto</Form.Label>
                    <Field
                      name="monto"
                      type="number"
                      as={Form.Control}
                      disabled={values.usarMonto === 'porcentaje'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'monto',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="monto"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Porcentaje</Form.Label>
                    <Field
                      name="porcentaje"
                      type="number"
                      as={Form.Control}
                      disabled={values.usarMonto === 'monto'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'porcentaje',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="porcentaje"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Monto maximo a depositar</Form.Label>
                    <Field
                      name="montoMaximo"
                      type="number"
                      as={Form.Control}
                      disabled={values.usarMonto === 'monto'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({
                          target: {
                            name: 'montoMaximo',
                            value: Number(e.target.value)
                          }
                        })
                      }
                    />
                    <ErrorMessage
                      name="montoMaximo"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" onClick={closeModal} className="me-2">
                Cancelar
              </Button>
              <Button type="submit" variant="success" disabled={isSubmitting}>
                Guardar
              </Button>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default CreateBonusForm
