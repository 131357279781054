// react imports
import { useState, useEffect } from 'react'
// libs imports
import getRoles from '../../../libs/system/getRoles'
// icons imports
import { FaEye } from 'react-icons/fa'
// local components imports
import SimpleTable from '../../tables/SimpleTable'
import ModalVisualizer from './ModalVisualizer'

// The role section renders a table with buttons that triggers the ModalVisualizer component.
const SystemRoles = () => {
  // declaring hooks
  const [data] = useState(getRoles())
  // modal triggers states
  const [triggerPreview, setTriggerPreview] = useState(false)
  const [triggerNewRole, setTriggerNewRole] = useState(false)
  // form data modal
  const [roleData, setRoleData] = useState({
    nombre: ' ',
    descripcion: '',
    id: ''
  })
  // new data gotten from the edition
  const [dataEdited, setDataEdited] = useState(false)
  // defining constants
  const rolesHeaders = ['Nombre', 'Descripción', 'Detalle']

  useEffect(() => {
    if (roleData) {
      setDataEdited({
        id: roleData.id,
        nombre: roleData.nombre,
        descripcion: roleData.descripcion
      })
    }
  }, [roleData])

  const handleOnClick = (event, id) => {
    event.preventDefault()
    setTriggerPreview(true)
    setRoleData(data.roles.find((role) => role.id === id))
  }

  const rolesContent = data.roles.map((role, index) => {
    return (
      <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
        <td className="item--start"> {role.nombre} </td>
        <td className="item--start">{role.descripcion}</td>
        <td className="item--start">
          <button
            className="button--preview"
            onClick={(event) => handleOnClick(event, role.id)}
          >
            <FaEye className="preview--icon" />
          </button>
        </td>
      </tr>
    )
  })

  return (
    <div className="roles--container">
      <SimpleTable
        tableHeaders={rolesHeaders}
        tableContent={rolesContent}
        tableName=""
      />
      <div className="button--container">
        <button
          className="button--form"
          onClick={() => setTriggerNewRole(true)}
        >
          Nuevo Rol
        </button>
      </div>
      <ModalVisualizer
        triggerPreview={triggerPreview}
        triggerNewRole={triggerNewRole}
        dataEdited={dataEdited}
        roleData={roleData}
        setRoleData={setRoleData}
        setTriggerPreview={setTriggerPreview}
        setTriggerNewRole={setTriggerNewRole}
        setDataEdited={setDataEdited}
      />
    </div>
  )
}

export default SystemRoles
