// react imports
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router'

// react icons imports
import { TiCancelOutline } from 'react-icons/ti'
import { AiOutlineFileAdd } from 'react-icons/ai'

// local components imports
import NewExclusion from '../../modals/NewExclusion'
import TableWallet from '../tables/clients/TableWallet'
import CancelExclusion from '../../modals/CancelExclusion'
import FormClientResults from '../forms/clients/FormClientResults'
import ErrorHandler from '../../utils/ErrorHandler'
import Information from '../../modals/Information'

// third party libraries
import { AnimatePresence } from 'framer-motion'

// local libs imports
import getCurrentDate from '../../libs/shared/getCurrentDate'
import getFormattedDate from '../../libs/shared/getFormattedDate'

// constants imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// hooks imports
import { useDataClient } from '../../utils/custom-hooks/useDataClient'

// ====================================================================== || EXCLUSION || ====================================================================================== //

const Exclusion = ({ setIsLogged }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // This state handles information about the action the user wants to execute.
  const [infoModal, setInfoModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.exclusion.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate()
  }
  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.exclusion.form,
    LOCAL_STORAGE_CLIENTS.exclusion.tablePage,
    formStructure
  )

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  const id = data[0].billeteraId

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  const [newExclusion, setNewExclusion] = useState(false)
  const [cancelExclusion, setCancelExclusion] = useState(false)

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    }
  ]

  const tableHeaders = [
    'Desde',
    'Hasta',
    'Observación',
    'Fecha Generacion',
    'Creación Operador',
    'Anulación Operador',
    'Fecha Anulación',
    'Activa'
  ]
  const [tableContent, setTableContent] = useState(0)

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((exclusion, index) => {
        const rawObs = exclusion.observaciones.slice(37)
        const observation = rawObs.slice(0, rawObs.indexOf('-'))

        return (
          <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
            <td>{getFormattedDate(exclusion.desde)}</td>
            <td> {getFormattedDate(exclusion.hasta)} </td>
            <td> {observation} </td>
            <td> {getFormattedDate(exclusion.fechaGeneracion)}</td>
            <td> {getFormattedDate(exclusion.fechaAnulacion)} </td>
            <td className="item--center"> {exclusion.creacionOperador} </td>
            <td className="item--center"> {exclusion.anulacionOperador} </td>
            <td className="item--center">
              {' '}
              <input
                type="checkbox"
                defaultChecked={exclusion.active ? 'checked' : ''}
                disabled
              />{' '}
            </td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const extraButtons = [
    <button
      key={1}
      title="Nueva exclusión"
      className="button--form wallet--button"
      onClick={(event) => handleNewButton(event)}
    >
      <AiOutlineFileAdd className="edit--icon" />
    </button>,
    <button
      key={2}
      title="Anular exclusión"
      className="button--form wallet--button"
      onClick={(event) => handleCancelButton(event)}
    >
      <TiCancelOutline className="edit--icon" />
    </button>
  ]

  const requestPath = (dataHolder = itemData.dataForm) => {
    const parseUrl = process.env.REACT_APP_WS_CONS_EXCLUSIONES.replace(
      ':idBilletera',
      id
    )

    const urlDateReplaced = `?desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`
    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: ''
    }

    setCommonUrl(url)
    localStorage.setItem(
      LOCAL_STORAGE_CLIENTS.exclusion.url,
      JSON.stringify(url)
    )

    return `${parseUrl}${urlDateReplaced}${0}`
  }

  const handleNewButton = (event) => {
    event.preventDefault()
    setNewExclusion(true)
  }

  const handleCancelButton = (event) => {
    event.preventDefault()
    setCancelExclusion(true)
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          extraButtons={extraButtons}
          storageName={LOCAL_STORAGE_CLIENTS.exclusion.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        <AnimatePresence>
          {newExclusion && (
            <NewExclusion
              newExclusion={newExclusion}
              setNewExclusion={setNewExclusion}
              walletId={id}
              setErrorModal={setErrorModal}
              setFormData={setItemData}
              requestPath={requestPath}
              setInfoModal={setInfoModal}
            />
          )}
          {cancelExclusion && (
            <CancelExclusion
              cancelExclusion={cancelExclusion}
              setCancelExclusion={setCancelExclusion}
              walletId={id}
              setErrorModal={setErrorModal}
              setFormData={setItemData}
              requestPath={requestPath}
              setInfoModal={setInfoModal}
            />
          )}
        </AnimatePresence>
        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para la consulta ingresada.
          </p>
        )}
      </div>
      <AnimatePresence>
        {errorModal.hadError && (
          <ErrorHandler
            setIsLogged={setIsLogged}
            errorMessage={errorModal.errorMessage}
          />
        )}
        {infoModal.isThereInfo && (
          <Information setInfoModal={setInfoModal} infoModal={infoModal} />
        )}
      </AnimatePresence>
    </>
  )
}

export default Exclusion
