import React from "react"
import { ReactElement } from "react"
import { OrgProp } from "types/userData"

export function optionsInput(element: OrgProp[]): ReactElement[] {
  const data = element.map((element, index) => {
    return (
      <option
        key={index}
        value={element?.codigo ?? element?.value}
      >
        {element.descripcion}
      </option>
    )
  })

  return data
}