// constants imports
import { STATUS } from '../../utils/constants'

// icons imports
import { FaSearch } from 'react-icons/fa'
import { PiCircleDashedBold } from 'react-icons/pi'

const TriggerButton = ({ status, handleOnClick }) => {
  return (
    <button
      title="Buscar"
      className="button--form wallet--button"
      onClick={(event) => handleOnClick(event)}
      disabled={status === STATUS.loading ? true : false}
    >
      {status === STATUS.loading ? (
        <PiCircleDashedBold className="icon--spin" />
      ) : (
        <FaSearch />
      )}
    </button>
  )
}

export default TriggerButton
