import { ResponseType } from "./GenericOptions";
import { GenericRequest } from "./GenericRequest";
import { HttpRequest } from "./HttpRequest";

export class HttpClient {
  readonly initRequest: HttpRequest | undefined;

  constructor(initRequest?: HttpRequest) {
    this.initRequest = initRequest;
  }

  get<T>(
    url: string,
    params?: any,
    headers?: any,
    responseType?: ResponseType
  ): Promise<T> {
    const options: GenericRequest = {
      method: "GET",
      url,
      options: { params, headers, responseType },
    };
    return this.requestAll<T>(options);
  }
  post<T, K>(
    url: string,
    body?: T,
    headers?: any,
    params?: any,
    responseType?: ResponseType
  ): Promise<K> {
    const options: GenericRequest = {
      method: "POST",
      url,
      options: { params, body, headers, responseType },
    };
    return this.requestAll<K>(options);
  }
  patch<T, K>(
    url: string,
    body?: T,
    params?: any,
    headers?: any,
    responseType?: ResponseType
  ): Promise<K> {
    const options: GenericRequest = {
      method: "PATCH",
      url,
      options: { params, headers, body, responseType },
    };
    return this.requestAll<K>(options);
  }
  put<T, K>(
    url: string,
    body: T,
    params?: any,
    headers?: any,
    responseType?: ResponseType
  ): Promise<K> {
    const options: GenericRequest = {
      method: "PUT",
      url,
      options: { params, headers, body, responseType },
    };
    return this.requestAll<K>(options);
  }
  delete<T>(
    url: string,
    params?: any,
    headers?: any,
    body?: T,
    responseType?: ResponseType
  ): Promise<T> {
    const options: GenericRequest = {
      method: "DELETE",
      url,
      options: { body, params, headers, responseType },
    };
    return this.requestAll<T>(options);
  }
  requestAll<T>(requestOptions: GenericRequest): Promise<T> {
    const { method, url, options } = requestOptions;
    let others = {};
    if (options?.body?.formData) {
      others = {
        ...others,
        headers: {
          ...options.headers,
          ...this.initRequest?.headers,
        },
      };
    } else if (options.headers !== "none") {
      others = {
        ...others,
        headers: {
          ...options.headers,
          ...this.initRequest?.headers,
          "Content-Type": "application/json",
        },
      };
    }
    const param = new URLSearchParams(options?.params).toString();
    const params = param ? `?${param}` : "";

    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}${url}${params}`, {
        ...others,
        method: method,
        body: options?.body?.formData || JSON.stringify(options?.body),
      })
        .then((response) => {
          let isOk = true;
          if (!response.ok) isOk = false;
          response
            .json()
            .then((json) => {
              if (isOk) resolve(json);
              else reject(json);
            })
            .catch((err) => {
              if (response.status === 204) {
                const anyElement: any = "";
                resolve(anyElement);
              } else reject(err);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
