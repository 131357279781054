const getPrivilege = () => {
  return {
    status: 'ok',
    errores: [],
    privilegios: [
      {
        id: '0fa05de4-0d5a-4c7e-845d-38019c4d8248',
        nombre: 'AG_FAV_CAMBIO_MASIVO'
      },
      {
        id: 'b3b76153-17c6-44cf-a0e0-510dfb09a8dc',
        nombre: 'AG_FAV_EJECUTAR_PEND'
      },
      {
        id: 'ecd8de69-e5e2-49be-906c-b27a53edda53',
        nombre: 'ALTA_OPERADOR'
      },
      {
        id: '819ca19a-e4a7-48ff-8496-05b973ed495f',
        nombre: 'CANCELAR_EXCLUSION_USUARIO'
      },
      {
        id: 'c0dc28cc-e76c-4c5e-98be-b65033e49679',
        nombre: 'CREATE_EXCLUSION_USUARIO'
      },
      {
        id: 'd25a237b-a932-4c42-8d80-b1295a0dd51f',
        nombre: 'CREATE_NPS_REMINDER'
      },
      {
        id: '1fdeb987-d02d-4335-8875-184df54a3d2f',
        nombre: 'CREATE_RECLAMO'
      },
      {
        id: '6bab66dc-0aa5-433f-9408-9379bed21e31',
        nombre: 'CREATE_RECLAMO_INT'
      },
      {
        id: '7bf5438a-09b7-4ff1-bcc2-638e65f98608',
        nombre: 'CREATE_ROL'
      },
      {
        id: '9f199a0b-1ee1-467b-bb20-c9a3e6f5edd5',
        nombre: 'CREATE_TYC'
      },
      {
        id: '300b6463-f3dd-4c5a-9285-4adde29a08cf',
        nombre: 'GET_AGENCIA'
      },
      {
        id: '1a52218c-6437-4d57-a092-790288ae5888',
        nombre: 'GET_AGENCIAS'
      },
      {
        id: '6f28ac5f-1600-4a8f-a6e4-0da1b2715330',
        nombre: 'GET_CUPON'
      },
      {
        id: 'f6422e7e-2939-44e0-9d83-b91bdaba2165',
        nombre: 'GET_CUPONES'
      },
      {
        id: '77ba3a6b-6540-45a1-ba27-98e804513559',
        nombre: 'GET_EXCLUSIONES_INIT'
      },
      {
        id: '263e6803-385e-4bcb-bc0e-ec6e793f7758',
        nombre: 'GET_HISTORIAL_AGS_BILL'
      },
      {
        id: '3dc6f622-42fd-4db4-8222-dd4f71df9104',
        nombre: 'GET_HISTORIAL_NPS'
      },
      {
        id: '9dd8d762-7f5a-4c9a-a128-bae0180d832b',
        nombre: 'GET_HISTORIAL_NPS_REMINDER'
      },
      {
        id: '81cb4d95-e02e-4b4a-9284-fba006440c69',
        nombre: 'GET_JUEGOS'
      },
      {
        id: '9624d893-668b-48d4-b442-fbfdde2d94ea',
        nombre: 'GET_MENSAJES'
      },
      {
        id: '7e259c38-44da-4c0a-aa62-2fc6ea822eca',
        nombre: 'GET_MOVIMIENTOS'
      },
      {
        id: '24e92769-1585-468a-b1eb-ce4aba041ab3',
        nombre: 'GET_NPS'
      },
      {
        id: 'df217965-238c-4291-8df4-fd5c7c76950d',
        nombre: 'GET_OPERADOR'
      },
      {
        id: 'e9d9a4e5-ad15-4e4f-b167-32877ecc0614',
        nombre: 'GET_OPERADORES'
      },
      {
        id: '324de1c8-e509-4525-b6c7-15f43fe1537a',
        nombre: 'GET_ORGANIZACIONES'
      },
      {
        id: '63dea195-9aa4-40a8-8c85-cd920e6dde7f',
        nombre: 'GET_PEDIDOS_RETIRO'
      },
      {
        id: '7ae49389-fe5f-4abe-aef0-19fb799bd19e',
        nombre: 'GET_PRIVILEGIOS'
      },
      {
        id: '013a56ae-c24c-4744-a8fb-548ddea4b7da',
        nombre: 'GET_RECLAMO'
      },
      {
        id: 'f31126a8-8a4e-4fc7-ab47-6691eaa7e6d3',
        nombre: 'GET_RECLAMO_INT'
      },
      {
        id: 'ed825d04-8a7d-42e5-b6b2-77bca1caa3dc',
        nombre: 'GET_ROLES'
      },
      {
        id: '724b51bc-624a-4464-adc5-b14090095e80',
        nombre: 'GET_TIPOS_MOVIMIENTOS'
      },
      {
        id: '9fa7b9b4-f88b-4f91-8115-b7f967003663',
        nombre: 'GET_TYC'
      },
      {
        id: '83a7225e-6245-47c9-86a8-572fd10c606b',
        nombre: 'GET_TYCS'
      },
      {
        id: '94164cbf-b71a-427d-820f-ada6030e5204',
        nombre: 'GET_UIF'
      },
      {
        id: '0ee9396f-8e68-4904-9ac5-40cccd5393f5',
        nombre: 'GET_USUARIO'
      },
      {
        id: '56d5eb19-cf96-4836-a152-c328a38f35ab',
        nombre: 'GET_USUARIO_ESTADOS'
      },
      {
        id: '779db497-4a5a-446d-ad43-d858fccdedf8',
        nombre: 'GET_USUARIOS'
      },
      {
        id: '6ef9a5d7-6fe3-40b2-941c-0d8fbc404263',
        nombre: 'READ_PRIVILEGE'
      },
      {
        id: '815c75d9-fd9f-42ed-9ca7-dcd21b1fa73f',
        nombre: 'REASIG_PEDIDO_RETIRO'
      },
      {
        id: 'ae0d1a32-c69c-47f0-a193-bede7443faac',
        nombre: 'REPORTE_OPERA'
      },
      {
        id: '9f9dfc77-7662-47a3-85d6-f3d64591d4e0',
        nombre: 'RESEND_EMAIL_ACT'
      },
      {
        id: '3425839b-4a4d-4ba7-94b0-caedb208c565',
        nombre: 'RESEND_TOKEN'
      },
      {
        id: '2d3c5d1e-18c1-47d1-8fb8-d34a8309f4ee',
        nombre: 'RESET_PASS_OPERADOR'
      },
      {
        id: 'a0eaee9c-235b-482e-8bba-c892ea6383cd',
        nombre: 'SINCRO_AGENCIAS_SAA'
      },
      {
        id: '472d149a-a828-4b5f-b117-68910ff4c372',
        nombre: 'UPDATE_AGENCIA'
      },
      {
        id: '35df8e5c-3773-42e1-ace9-f9bb9335b8d0',
        nombre: 'UPDATE_OPERADOR_DATA'
      },
      {
        id: 'fd1fea18-dc06-4a7c-a59b-c6afa4915b06',
        nombre: 'UPDATE_RECLAMO'
      },
      {
        id: '81dbd22a-3b0c-4d3d-aeb5-a099d2f1d408',
        nombre: 'UPDATE_RECLAMO_INT'
      },
      {
        id: '23a7485a-dd2a-4ff2-be59-3f3b41f78249',
        nombre: 'UPDATE_ROL'
      },
      {
        id: '43580764-aa69-47ce-b947-572476533c18',
        nombre: 'UPDATE_UIF_PENDIENTES'
      },
      {
        id: '4ad12c9a-3b07-4887-a8f6-3994cef4d2f7',
        nombre: 'UPDATE_USUARIO_DATA'
      },
      {
        id: '00930060-4373-4b76-b207-780efe9381e1',
        nombre: 'UPDATE_USUARIO_ESTADO'
      },
      {
        id: 'cad721b7-a6d2-4601-bc2f-a69370adaa2b',
        nombre: 'UPDATE_USUARIO_ESTADO_WS'
      },
      {
        id: 'a4e36da7-9d5e-4c56-bdca-ed60516f6bd1',
        nombre: 'UPDATE_USUARIO_ID'
      },
      {
        id: 'cc21d1e6-065a-42be-bc36-aee36a79ba8f',
        nombre: 'WRITE_PRIVILEGE'
      }
    ]
  }
}

export default getPrivilege
