import { AiFillCloseCircle } from 'react-icons/ai'
import { useEffect, useRef, useState } from 'react'
import { useAnimate, usePresence } from 'framer-motion'
import postNewExclusion from '../libs/clients/postNewExclusion'
import getCurrentDate from '../libs/shared/getCurrentDate'
import postData from '../libs/postData'
import fetchData from '../libs/clients/fetchData'

const NewExclusion = ({
  newExclusion,
  setNewExclusion,
  walletId,
  setErrorModal,
  setFormData,
  requestPath,
  setInfoModal
}) => {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()
  const reference = useRef()

  const [postStatus, setPostStatus] = useState(false)

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        newExclusion &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setNewExclusion(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [newExclusion])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  const [dataForm, setDataForm] = useState({
    fromDate: '',
    toDate: getCurrentDate(),
    observations: ''
  })

  const handleOnchange = (event) => {
    setDataForm((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleSendData = async (event) => {
    event.preventDefault()

    const parameters = {
      desde: dataForm.fromDate.replaceAll('-', ''),
      hasta: dataForm.toDate.replaceAll('-', ''),
      obsevaciones: dataForm.observations
    }

    // http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/billetera/a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb/autoexclusion/generar
    // http://localhost:8704/api-operador/admin/billetera/a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb/autoexclusion/generar
    // REACT_APP_WS_GENERAR_EXCLUSION
    const url = process.env.REACT_APP_WS_GENERAR_EXCLUSION.replace(
      ':idBilletera',
      walletId
    )

    try {
      const response = await postData(parameters, url)
      if (response.status === 'ok') {
        setPostStatus(true)
      }
    } catch ({ message, code }) {
      console.log(code)
      if (code === 182) {
        setInfoModal({ isThereInfo: true, errorMessage: message })
      } else {
        setErrorModal({ hadError: true, errorMessage: message })
      }
    }

    postNewExclusion('walletId')
  }

  useEffect(() => {
    const getNewExclusions = async () => {
      if (postStatus) {
        const url = requestPath(dataForm)
        const refreshData = await fetchData(url)

        setFormData((prev) => {
          return {
            ...prev,
            dataFetched: refreshData
          }
        })
        setNewExclusion(false)
      }
    }
    getNewExclusions()
  }, [postStatus])

  const handleCancelAction = (event) => {
    event.preventDefault()
    setNewExclusion(false)
  }

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>Nueva Exclusión</h3>
          <button onClick={() => setNewExclusion(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <form>
            <div className="field--form--wallet form--row">
              <label className="form--label">Fecha desde</label>
              <input
                name="fromDate"
                onChange={(event) => handleOnchange(event)}
                value={dataForm.fromDate}
                className="form--input"
                type="date"
                max={dataForm.toDate}
              />
            </div>
            <div className="field--form--wallet form--row">
              <label className="form--label">Fecha hasta</label>
              <input
                name="toDate"
                onChange={(event) => handleOnchange(event)}
                value={dataForm.toDate}
                className="form--input"
                type="date"
                max={dataForm.toDate}
              />
            </div>
            <div className="field--form--wallet form--row">
              <label className="form--label">Observaciones</label>
              <input
                name="observations"
                onChange={(event) => handleOnchange(event)}
                value={dataForm.observations}
                className="form--input form--input--modal"
                placeholder="Observaciones"
                type="text"
              />
            </div>
          </form>
          <div className="buttons--container">
            <button
              onClick={(event) => handleSendData(event)}
              className="button--form"
            >
              Enviar
            </button>
            <button
              onClick={(event) => handleCancelAction(event)}
              className="button--form"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewExclusion
