import NavFooterBar from './NavFooterBar'
import GoToPage from './GoToPage'

// This component renders and controls the table footer that has as main goal the navigation between tha table pages.
const TableFooter = ({
  range,
  setPage,
  page,
  setData,
  records,
  walletId,
  dataForm,
  name,
  commonUrl,
  setErrorModal
}) => {
  return (
    <div className="table--footer">
      <div className="footer--container">
        <NavFooterBar
          page={page}
          setPage={setPage}
          name={name}
          range={range}
          setData={setData}
          walletId={walletId}
          dataForm={dataForm}
          commonUrl={commonUrl}
          setErrorModal={setErrorModal}
        />
        <GoToPage
          page={page}
          range={range}
          dataForm={dataForm}
          setPage={setPage}
          setData={setData}
          commonUrl={commonUrl}
          setErrorModal={setErrorModal}
        />
        <div> {`(${records} registros)`} </div>
      </div>
    </div>
  )
}

export default TableFooter
