import { MdArrowForwardIos } from 'react-icons/md'
import { useState, useEffect } from 'react'
import React from 'react'

const GGoToPage = ({
  page,
  range,
  setPage,
}: any) => {

  // The holder represent value that the input can hold if the user change it.
  const currentPage = parseInt(page) + 1
  const [holder, setHolder] = useState(currentPage)

  // This function triggers the fetch action onces the user clicked the button to search a page taken by the input.
  const handleOnSearchPage = async (event: any) => {
    event.preventDefault()

    const actualValue: number= holder - 1
    if (holder < range && actualValue >= 0) {
      setPage(actualValue)
    }
  }

  // This function collects the value of the page that the user wants to visit.
  const handleOnChange = (event:any) => {
    setHolder(event.target.value)
  }

  useEffect(() => {
    setHolder(page)
  }, [])

  return (
    <div className="go--to--page--container">
      <p>Ir a pagina</p>
      <input
        type="text"
        value={holder}
        onChange={(event) => handleOnChange(event)}
      />
      <button onClick={(event) => handleOnSearchPage(event)}>
        <MdArrowForwardIos />
      </button>
    </div>
  )
}

export default GGoToPage
