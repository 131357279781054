// react import
import React, { ReactElement, useState } from "react"
// components import
import GForm from "components/forms/generic/GForm"
// icons import
import { FaSearch } from "react-icons/fa"
import { GoSync } from "react-icons/go"
// types import
import { OrgProp } from "types/userData"
import { optionsInput } from "utils/optionsInput"
import systemServices from "services/system/systemServices"
import { STATUS } from "utils/constants"

const status: OrgProp[] = [
  { descripcion: 'Habilitada', value: 'true' },
  { descripcion: 'Deshabilitada', value: 'false' }
]

/**
 * 
 * @returns
 */
const AForm = (props: any): ReactElement => {
  const {
    org,
    itemData,
    setItemData,
    setErrorModal,
    setFormFields,
    formFields
  } = props

  const [statusTrigger, setStatusTrigger] = useState(STATUS.initial)

  const getAgencies = async (formData: any): Promise<void> => {
    setStatusTrigger(STATUS.loading)
    systemServices.getAgencies(formData, 0)
      .then((response) => {
        setItemData(response)
        setStatusTrigger(STATUS.success)
      }).catch((error: any) => {
        setErrorModal({ hadError: true, errorMessage: error.message })
        setStatusTrigger(STATUS.error)
      })
  }

  const syncAgencies = (formData: any): void => {
    systemServices.syncAgencies(formData.organization)
      .then(() => {
        getAgencies(formData)
      }).catch((error) => {
        setErrorModal({ hadError: true, errorMessage: error.message })
        setStatusTrigger(STATUS.error)
      })
  }

  const enableSync = () => {
    if (itemData === null) {
      return true
    } else if (!formFields.organization) {
      return true
    } else {
      return false
    }
  }
  // Section: TSX
  return (
    <GForm
      setFormFields={setFormFields}
    >
      <GForm.SelectInput
        label="Organización"
        formName="organization"
        options={org != null && optionsInput(org)}
      />
      <GForm.TextInput label="Agencia" formName="agencyCode" />
      <GForm.TextInput label="Localidad" formName="location" />
      <GForm.SelectInput
        label="Estado"
        formName="status"
        options={optionsInput(status)}
      />
      <GForm.Button
        title="Sincronizar Agencias"
        status="initial"
        handleOnClick={syncAgencies}
        disabled={enableSync()}
      >
        <GoSync className="edit--icon" />
      </GForm.Button>
      <GForm.Button
        title="Buscar"
        status={statusTrigger}
        handleOnClick={getAgencies}
        disabled={statusTrigger === STATUS.loading}
      >
        <FaSearch />
      </GForm.Button>
    </GForm>
  )
}

export default AForm
