import React from 'react'
import { useAppContext } from 'context/AppContext'
import { Navigate, Outlet } from 'react-router'

const PrivateRoute = () => {
  const { isLogged } = useAppContext()

  return isLogged ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoute
