import React from 'react'
import userItems from '../../utils/userItems'
import { Link } from 'react-router-dom'
import { nanoid } from 'nanoid'

const listElementsContainer = userItems.map((element) => {
  return (
    <Link key={nanoid()} to={element.link} className="data--container">
      <element.icon className="home--container--icon" />
      <h2 className="container--name">{element.name}</h2>
    </Link>
  )
})

export default function Home() {
  return <div className="home--container">{listElementsContainer}</div>
}
