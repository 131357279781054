// React imports
import { useEffect, useRef, useState } from 'react'

// Third party react libraries
import { MdCancel } from 'react-icons/md'
import { useAnimate, usePresence } from 'framer-motion'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'

// Local components imports
import SimpleTable from '../components/tables/SimpleTable'
import ChangePassword from './ChangePassword'

// local imports
import postNewPassword from '../libs/system/postNewPassword'
import getOrganizations from '../libs/getOrganizations'
import postAgency from '../libs/system/postAgency'
import getRoles from '../libs/system/getRoles'
import { fields } from '../utils/operatorUtils'
import getCustomFormFields from '../utils/getCustomFormFields'

const LayoutOperatorAction = ({
  preview,
  setPreview,
  data,
  setData,
  operationType,
  title
}) => {
  const isEditOperationType = operationType === 'edition' ? true : false
  const formItems = fields(isEditOperationType)

  // Hook that controls the modal behavior.
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  // Hooks to control the interactions with the modal.
  const [editOperator, setEditOperator] = useState(!isEditOperationType)
  const [buttonName, setButtonName] = useState(
    isEditOperationType ? 'Editar' : 'Guardar'
  )
  const [editItems, setEditItems] = useState(false)
  const [dataEdited, setDataEdited] = useState({
    id: data.id,
    username: data.username,
    active: data.active,
    nombre: data.nombre,
    apellido: data.apellido,
    bloqueado: data.bloqueado
  })

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        preview &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setPreview(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [preview, setPreview])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [animate, isPresent, safeToRemove, scope])

  const formFields = getCustomFormFields(
    formItems,
    data,
    setDataEdited,
    dataEdited,
    editOperator
  )

  const handleOnClick = (event) => {
    event.preventDefault()

    if (event.target.name === 'Editar') {
      setEditOperator(true)
      setButtonName('Guardar')
    }

    if (event.target.name === 'Guardar') {
      setPreview(false)
      console.log(dataEdited)
      postAgency(dataEdited)
    }

    if (event.target.name === 'cancel') {
      setPreview(false)
    }

    if (event.target.name === 'change--password') {
      setChangePassword(true)
    }
  }

  const handleDeleteOnClick = (event, element) => {
    event.preventDefault()
    if (element.id) {
      setData((prev) => {
        return {
          ...prev,
          roles: prev.roles.filter((item) => item.id !== element.id)
        }
      })
    }
    if (element.codigo) {
      setData((prev) => {
        return {
          ...prev,
          organizaciones: prev.organizaciones.filter(
            (item) => item.codigo !== element.codigo
          )
        }
      })
    }
  }

  const deleteButton = (element) =>
    editOperator && (
      <div>
        <button
          onClick={(event) => handleDeleteOnClick(event, element)}
          title="Eliminar"
          className="button--preview flex--button"
        >
          <MdCancel className="preview--icon" />
        </button>
      </div>
    )

  const rolesHeaders = ['ID', 'Nombre', 'Descripción']
  const orgHeaders = ['ID', 'Descripción']

  const rolesContent = data.roles.map((operator, index) => {
    return (
      <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
        <td>{operator.id}</td>
        <td className="item--start"> {operator.nombre} </td>
        <td className="item--start">{operator.descripcion}</td>
        <td>{deleteButton(operator)}</td>
      </tr>
    )
  })

  const orgContent = data.organizaciones.map((operator, index) => {
    return (
      <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
        <td>{operator.codigo}</td>
        <td className="item--start"> {operator.descripcion} </td>
        <td>{deleteButton(operator)}</td>
      </tr>
    )
  })

  const handleAdderOnClick = (event) => {
    event.preventDefault()
    setEditItems(true)
  }

  const OPTIONS = { roles: 'roles', organizations: 'organizations' }

  const [optionSelected, setOptionSelected] = useState({
    roles: '',
    organizations: ''
  })

  const handleOnChangeOption = (event) => {
    setOptionSelected((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const listOptions = (getter) => {
    const rawElements = getter()
    const elements = rawElements.roles || rawElements
    return {
      options: elements.map((item, index) => (
        <option key={index} value={item.nombre || item.name}>
          {item.nombre || item.name}
        </option>
      )),
      info: rawElements
    }
  }

  const findElement = (element, token) => {
    for (const key in element) {
      if (element[key] === token) return true
    }
  }

  const onClickAdder = (event, infoOptions, name) => {
    event.preventDefault()
    const optionName = optionSelected[name]
    const parsedName = name === OPTIONS.roles ? name : 'organizaciones'
    const parsedInfo =
      name === OPTIONS.roles ? infoOptions[parsedName] : infoOptions
    const parsedData = parsedInfo.find((element) =>
      findElement(element, optionName)
    )

    setData((prev) => {
      return {
        ...prev,
        [parsedName]: [...prev[parsedName], parsedData]
      }
    })
  }

  const adderButton = (name) => {
    let optionData =
      name === OPTIONS.roles
        ? listOptions(getRoles)
        : listOptions(getOrganizations)
    const options = optionData.options
    const infoOptions = optionData.info
    return (
      editOperator &&
      (!editItems ? (
        <div className="flex--container">
          <button
            title={`Agregar ${name}`}
            className="flex--button button--transparent"
            onClick={(event) => handleAdderOnClick(event)}
          >
            <AiOutlinePlusCircle className="icon--style" />
          </button>
        </div>
      ) : (
        <div className="flex--container">
          <select
            title={`Seleccione un item de ${name}`}
            className="form--input"
            name={name}
            value={optionSelected[name]}
            onChange={(event) => handleOnChangeOption(event)}
          >
            {options}
          </select>
          <button
            onClick={(event) => onClickAdder(event, infoOptions, name)}
            className="button--form"
          >
            <AiOutlinePlusCircle className="icon--style" />
          </button>
        </div>
      ))
    )
  }
  const [changePassword, setChangePassword] = useState(false)
  const [formChangePassWord, setFormChangePassWord] = useState({
    newPassword: '',
    confirmPassword: ''
  })

  const handleOChangePassword = (event) => {
    setFormChangePassWord((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const resetPassword = () => {
    postNewPassword(formChangePassWord)
    setChangePassword(false)
  }

  const buildPasswordForm = () => {
    return (
      <>
        <form>
          <div className="field--form form--row">
            <label className="form--label">Nueva Contraseña</label>
            <input
              name="newPassword"
              onChange={(event) => handleOChangePassword(event)}
              type="text"
              value={formChangePassWord.newPassword}
            />
          </div>
          <div className="field--form form--row">
            <label className="form--label">Repetir Contraseña</label>
            <input
              name="confirmPassword"
              onChange={(event) => handleOChangePassword(event)}
              type="text"
              value={formChangePassWord.confirmPassword}
            />
          </div>
        </form>
        <div className="buttons--container">
          <button
            className="button--form"
            onClick={() => setChangePassword(false)}
          >
            {' '}
            Cancelar
          </button>
          <button
            className="button--form"
            onClick={(event) => resetPassword(event)}
          >
            Cambiar
          </button>
        </div>
      </>
    )
  }

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content modal--scroll " ref={reference}>
        <div className="coupon--title">
          <h3>{title}</h3>
          <button onClick={() => setPreview(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <form>
            <legend> Información de usuario </legend>
            {formFields}
          </form>
          <SimpleTable
            addButton={adderButton(OPTIONS.roles)}
            tableHeaders={rolesHeaders}
            tableContent={rolesContent}
            tableName="Roles"
          />
          <SimpleTable
            addButton={adderButton(OPTIONS.organizations)}
            tableHeaders={orgHeaders}
            tableContent={orgContent}
            tableName="Organizaciones"
          />
          <div className="buttons--container bg">
            {isEditOperationType && (
              <button
                name="change--password"
                onClick={(event) => handleOnClick(event)}
                className="button--form"
              >
                Cambiar contraseña
              </button>
            )}
            <button
              name="cancel"
              onClick={(event) => handleOnClick(event)}
              className="button--form"
            >
              Cancelar
            </button>
            <button
              name={buttonName}
              onClick={(event) => handleOnClick(event)}
              className="button--form"
            >
              {buttonName}
            </button>
          </div>
          {changePassword && (
            <ChangePassword
              preview={changePassword}
              setPreview={setChangePassword}
              data={buildPasswordForm()}
              title={'Cambiar Contraseña'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default LayoutOperatorAction
