import { useAnimate, usePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'

const ModalLayout = (props) => {
  // Hook that controls the modal behavior.
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()
  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        props.preview &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) props.setPreview(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [props.preview])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div
        className={`modal--content${props.info ? '--info' : ''} ${
          props.noScroll ? ' ' : 'modal--scroll'
        }`}
        ref={reference}
      >
        {props.title && (
          <div className="coupon--title">
            <h3>{props.title}</h3>
            <button onClick={() => props.setPreview(false)}>
              {' '}
              <AiFillCloseCircle className="close--icon" />{' '}
            </button>
          </div>
        )}
        <div className="solver--data--info">{props.children}</div>
      </div>
    </div>
  )
}

export default ModalLayout
