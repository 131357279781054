// REACT_APP_WS_CONS_JUEGOS=http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/cupon/juegos
// http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/cupon/juegos?codigoOrganizacion=42

const getGamesCode = () => {
  return {
    status: 'ok',
    errores: [],
    juegos: [
      {
        codigo: 0,
        nombre: 'Quiniela'
      },
      {
        codigo: 1,
        nombre: 'Quiniela Instantánea'
      },
      {
        codigo: 6,
        nombre: 'Pozo de la Quiniela'
      },
      {
        codigo: 20,
        nombre: 'Patagonia Minibingo'
      },
      {
        codigo: 21,
        nombre: 'Patagonia Telebingo'
      },
      {
        codigo: 10,
        nombre: 'Loteria Unificada'
      }
    ]
  }
}

export default getGamesCode
