// react imports
import { useState } from 'react'
// locals imports
import LoginButton from '../login/LoginButton'
import LoginHeader from '../login/LoginHeader'
import LoginForm from '../login/LoginForm'

// constants
import { STATUS } from '../../utils/constants'

const Login = ({ setIsLogged, setUserData }) => {
  // using hooks.
  const [postStatus, setPostStatus] = useState(STATUS.initial)
  const [postErrors, setPostErrors] = useState({ status: '', errors: [] })
  // Declaring the use state to handle the form data.
  const [formData, setFromData] = useState({
    user: '',
    password: ''
  })

  return (
    <div className="login--container">
      <div className="login--modal">
        <LoginHeader />
        <LoginForm
          setFromData={setFromData}
          postErrors={postErrors}
          postStatus={postStatus}
          status={STATUS}
        />
        <LoginButton
          setPostStatus={setPostStatus}
          setPostErrors={setPostErrors}
          setIsLogged={setIsLogged}
          formData={formData}
          logStatus={STATUS}
          postStatus={postStatus}
          setUserData={setUserData}
        />
      </div>
    </div>
  )
}

export default Login
