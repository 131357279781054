import { AiFillCloseCircle } from 'react-icons/ai'
import { useEffect, useRef } from 'react'
import { useAnimate, usePresence } from 'framer-motion'
import { FaExclamationCircle, FaCheck } from 'react-icons/fa'

const MassiveChangeModal = ({
  changeResponse,
  setChangeResponse,
  response
}) => {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()
  const reference = useRef()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        changeResponse &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setChangeResponse(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [changeResponse])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  const handleAction = (event) => {
    event.preventDefault()
    setChangeResponse(false)
  }

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content--message solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>Estado cambio</h3>
          <button onClick={() => setChangeResponse(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          {!response ? (
            <div className="cancel--container">
              <FaExclamationCircle className="alert--icon" />
              <p>Error</p>
            </div>
          ) : (
            <div className="cancel--container">
              <FaCheck className="success--icon" />
              <p>Cambio Exitoso</p>
            </div>
          )}
          <div className="buttons--container">
            <button
              onClick={(event) => handleAction(event)}
              className="button--form"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MassiveChangeModal
