import { type IColumn } from './columnStructureTypes'

export const agencySystem: IColumn[] = [
  {
    id: 1,
    label: 'Codigo',
    align: false,
    key: {
      name: 'codigo'
    }
  },
  {
    id: 2,
    label: 'Descripción',
    align: true,
    key: {
      name: 'descripcion'
    }
  },
  {
    id: 3,
    label: 'Localidad',
    align: true,
    key: {
      name: 'localidad'
    }
  },
  {
    id: 4,
    label: 'Habilitada',
    align: true,
    key: {
      name: 'habilitada',
      isCheckBox: true
    }
  },
  {
    id: 5,
    label: 'Activa',
    align: true,
    key: {
      name: 'active',
      isCheckBox: true
    }
  },
  {
    id: 6,
    label: 'Detalle',
    align: true,
    key: {
      name: 'active',
      isButton: true
    }
  }
]

export const operatorsSystem: IColumn[] = [
  {
    id: 1,
    label: 'Usuario',
    align: false,
    key: {
      name: 'username'
    }
  },{
    id: 2,
    label: 'Nombre',
    align: false,
    key: {
      name: 'nombre'
    }
  },{
    id: 3,
    label: 'Apellido',
    align: false,
    key: {
      name: 'apellido'
    }
  },
  {
    id: 4,
    label: 'Detalle',
    align: true,
    key: {
      name: 'active',
      isButton: true
    }
  }
]