const ClientTable = ({ data }) => {
  const tableContent = data.map((element, index) => {
    return (
      <tr key={index}>
        <td>{element.nombreOrganizacion}</td>
        <td className="item--center">
          {' '}
          <input
            type="checkbox"
            defaultChecked={element.principal ? 'checked' : ''}
            disabled
          />{' '}
        </td>
        <td className="item--center"> {element.agAmiga}</td>
      </tr>
    )
  })

  return (
    <div className="table--container">
      <table className="table--style">
        <thead>
          <tr>
            <th>Nombre Organización</th>
            <th>Principal</th>
            <th>Agencia Amiga</th>
          </tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>
    </div>
  )
}

export default ClientTable
