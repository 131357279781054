import React, {
  type ReactNode,
  type ReactElement,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { PiCircleDashedBold } from 'react-icons/pi'
import { STATUS } from 'utils/constants'

type ContextProps = {
  formData: any
  setFormData: any
  handleOnChange: any
  setFormFields: any
}

type GFormProps = {
  children: ReactNode
  setFormFields: any

}

const GFormContext = createContext<ContextProps>({
  formData: null,
  setFormData: null,
  handleOnChange: null,
  setFormFields: null
})

function GForm({ children, setFormFields }: GFormProps): ReactElement {
  const [formData, setFormData] = useState<any>({})

  const handleOnChange = (event: any): any => {
    setFormData((prevData: any) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value
      }
    })
  }

  return (
    <GFormContext.Provider value={{ formData, setFormData, handleOnChange, setFormFields }}>
      <div className="container--form--table--wallet">
        <form>
          <div className="form--row">{children}</div>
        </form>
      </div>
    </GFormContext.Provider>
  )
}

function TextInput({ label, formName, children }: any): ReactElement {
  const { formData, setFormData, handleOnChange } = useContext(GFormContext)

  useEffect(() => {
    setFormData((prev: any) => ({ ...prev, [formName]: '' }))
  }, [])

  return (
    <div className="field--form--wallet">
      <label className="form--label">{label}:</label>
      <input
        name={formName}
        className="form--input"
        type="text"
        value={formData[formName]}
        onChange={(event) => handleOnChange(event)}
      />
    </div>
  )
}

function SelectInput({
  label,
  formName,
  children,
  options
}: any): ReactElement {
  const { formData, setFormData, handleOnChange } = useContext(GFormContext)

  useEffect(() => {
    setFormData((prev: any) => ({ ...prev, [formName]: '' }))
  }, [])

  return (
    <div className="field--form--wallet">
      <label className="form--label">{label}:</label>
      <select
        className="form--input"
        name={formName}
        value={formData[formName]}
        onChange={(event) => handleOnChange(event)}
      >
        <option value="">Seleccione Opción</option>
        {options}
      </select>
    </div>
  )
}

function Button({
  title,
  status,
  children,
  handleOnClick,
  disabled
}: {
  title: string
  status: string
  children: any
  handleOnClick: (FormData: any) => void
  disabled?: boolean
}): React.ReactElement {
  const { formData, setFormFields } = useContext(GFormContext)

  const onClick = useCallback(
    (event: any) => {
      event.preventDefault()
      handleOnClick(formData)
      setFormFields(formData)
    },
    [formData]
  )

  return (
    <button
      title={title}
      className={`button--form${
        disabled === true ? '--disabled' : ''
      } wallet--button`}
      onClick={(event) => {
        onClick(event)
      }}
      disabled={status === STATUS.loading || disabled}
    >
      {status === STATUS.loading ? (
        <PiCircleDashedBold className="icon--spin" />
      ) : (
        children
      )}
    </button>
  )
}

GForm.TextInput = TextInput
GForm.SelectInput = SelectInput
GForm.Button = Button

export default GForm
