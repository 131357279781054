// react imports
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router'

// component imports
import FormClientResults from '../forms/clients/FormClientResults'
import TableWallet from '../tables/clients/TableWallet'
import ErrorHandler from '../../utils/ErrorHandler'

// libs imports
import getFormattedDate from '../../libs/shared/getFormattedDate'
import getCurrentDate from '../../libs/shared/getCurrentDate'

// custom hooks imports
import { useDataClient } from '../../utils/custom-hooks/useDataClient'

// const imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// ====================================================================== || WITHDRAWAL REQUEST || ====================================================================================== //

const movementType = [
  {
    codigo: 'PUNTO_VENTA',
    nombre: 'Punto de Venta'
  },
  {
    codigo: 'TRANSFERENCIA',
    nombre: 'Transferencia'
  }
]

const WithdrawalRequests = ({ setIsLogged }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })
  const [options, setOptions] = useState(false)

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.withDrawal.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate()
  }

  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.withDrawal.form,
    LOCAL_STORAGE_CLIENTS.withDrawal.tablePage,
    formStructure
  )

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  const tableHeaders = [
    'Fecha Pedido',
    'Importe',
    'Estado',
    'Fecha Estado',
    'Agencia Retiro',
    'Agencia Amiga'
  ]
  const [tableContent, setTableContent] = useState(0)

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((request, index) => {
        return (
          <tr
            key={request.id}
            className={index % 2 === 0 ? 'add--row--color' : ''}
          >
            <td>{getFormattedDate(request.fechaPedido)}</td>
            <td className="item--center"> {request.importe} </td>
            <td className="item--center"> {request.estado}</td>
            <td className="item--center">
              {' '}
              {getFormattedDate(request.fechaEstado)}
            </td>
            <td className="item--center"> {request.agRetiro}</td>
            <td className="item--center"> {request.agAmiga}</td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  useEffect(() => {
    const buildingOptions = movementType.map((item) => {
      return (
        <option key={item.codigo} value={item.codigo}>
          {item.nombre}
        </option>
      )
    })
    setOptions(buildingOptions)
  }, [])

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    },
    {
      type: 'select',
      label: 'Tipo',
      name: 'type',
      options: options
    }
  ]

  const requestPath = (dataHolder) => {
    const walletId = data[0].billeteraId

    const parseUrl = process.env.REACT_APP_WS_CONS_SOL_RETIROS.replace(
      ':idBilletera',
      walletId
    )
    const urlDateReplaced = `?desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`

    const filter = dataHolder.type && `&tipo=${dataHolder.type}`

    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: '',
      tipoMovimiento: filter
    }

    setCommonUrl(url)
    localStorage.setItem(
      LOCAL_STORAGE_CLIENTS.withDrawal.url,
      JSON.stringify(url)
    )

    return `${parseUrl}${urlDateReplaced}${0}${filter}`
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          storageName={LOCAL_STORAGE_CLIENTS.withDrawal.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para el rango de fechas ingresada.
          </p>
        )}
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </>
  )
}

export default WithdrawalRequests
