const postLoginData = async (requestBody, url) => {
  let data
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      },
      body: requestBody
    })

    data = await response.json()
  } catch (error) {
    console.error(error)
    return {
      status: 'error',
      errores: [{ error: 'Problemas de conexión' }]
    }
  }

  return data
}

export default postLoginData
