// react imports
import { useState, useEffect } from 'react'

// icon imports
import { FaPencilAlt } from 'react-icons/fa'
import { LuMailCheck } from 'react-icons/lu'

// libs imports
import postData from '../../../libs/postData'
import ModalLayout from '../../../modals/ModalLayout'
import ErrorHandler from '../../../utils/ErrorHandler'
import fetchData from '../../../libs/clients/fetchData'
import locationsLoader from '../../../libs/clients/locationLoader'
import getFormattedDate from '../../../libs/shared/getFormattedDate'

// animations imports
import { AnimatePresence } from 'framer-motion'

// ====================================================================== || FORM PERSONAL DATA || ====================================================================================== //

const FormPersonalData = ({ data, setData, setIsLogged }) => {
  const updatableFormData = () => {
    return {
      nombre: data.nombre,
      apellido: data.apellido,
      direccion: data.direccion,
      localidad: data.localidad,
      nroTelefono: data.nroTelefono,
      cuil: data.cuil,
      fechaAlta: getFormattedDate(data.fechaAlta)
    }
  }
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // This state handles email modal info, when the email was sent appears a modal to inform to the customer.
  const [sendEmailInfo, setSendEmailInfo] = useState(false)

  const [updatableFormFields, setUpdatableFormFields] =
    useState(updatableFormData())
  // This object stores the data that can be edited by the user.
  useEffect(() => {
    setUpdatableFormFields(updatableFormData())
  }, [data])

  // This state stores the actual formData that can be updated.
  const [formData, setFormData] = useState(updatableFormFields)

  // This state checks if the form is being updated.
  const [isEditionStage, setIsEditionStage] = useState(false)

  // When the edition stage is on, it needs to fetch the location data, this state stores that data.
  const [locations, setLocations] = useState(false)

  // To displays the locations array in the form, this state stores the formatted location ready to feature.
  const [locationOptions, setLocationOptions] = useState(false)

  // It builds the location with the needed format.
  const buildingOptions = () => {
    const options = locations.map((option) => (
      <option key={option.id} value={option.nombre}>
        {option.nombre}
      </option>
    ))
    return options
  }

  const getLocations = async () => {
    try {
      const gottenLocations = await locationsLoader()
      setLocations(gottenLocations)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
    }
  }

  // Once it gets the locations, it triggers the building function.
  useEffect(() => {
    if (locations) setLocationOptions(buildingOptions())
  }, [locations])

  useEffect(() => {
    if (locationOptions) setIsEditionStage(true)
  }, [locationOptions])

  // The problem with this approach is that always the edition is triggered the locations are requested to the database.
  // A better approach it'll be store the locations and avoid always request this data.
  const handleOnclickEdition = (event) => {
    event.preventDefault()
    getLocations()
  }

  const postEditedData = async () => {
    const locationFound = locations.find(
      (location) => location.nombre === formData.localidad
    )
    const editedData = { ...formData, localidadId: locationFound.id }

    try {
      const updatedData = await postData(
        editedData,
        process.env.REACT_APP_WS_ACTU_DATOS_USUARIO.replace(
          ':idUsuario',
          data.id
        )
      )
      setData(updatedData)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
    }
  }

  const handleOnclickChanges = async (event) => {
    event.preventDefault()
    const optionClicked = event.target.name

    if (optionClicked === 'cancel') setFormData(updatableFormFields)
    else postEditedData()

    setIsEditionStage(false)
  }

  const handleOnchangeInput = (event) => {
    if (isEditionStage) {
      setFormData((prevData) => {
        return {
          ...prevData,
          [event.target.name]: event.target.value
        }
      })
    }
  }

  const handleSendEmail = async (event) => {
    event.preventDefault()
    const url = process.env.REACT_APP_WS_REENVIO_MAIL_ACTIVACION_CTA.replace(
      ':idUsuario',
      data.id
    )

    try {
      const response = await fetchData(url)
      if (!response.ok) setSendEmailInfo(true)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
    }
  }

  return (
    <>
      <form>
        <div className="button--container--form--client">
          {isEditionStage ? (
            <div className="handle--changes">
              <button
                name="save"
                onClick={(event) => handleOnclickChanges(event)}
                className="button--form"
              >
                Save
              </button>
              <button
                name="cancel"
                onClick={(event) => handleOnclickChanges(event)}
                className="button--form cancel--button"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="edit--button">
              <button
                onClick={(event) => handleOnclickEdition(event)}
                className="button--form"
              >
                <FaPencilAlt className="edit--icon" />
              </button>
            </div>
          )}
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Tipo de Documento: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={'DNI'}
            />
          </div>
          <div className="field--form">
            <label className="form--label--client">Documento: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={data.documento}
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Nombre: </label>
            <input
              name="nombre"
              className={`${
                isEditionStage ? 'on--edit' : 'form--input--client'
              }`}
              type="text"
              value={formData.nombre}
              onChange={(event) => handleOnchangeInput(event)}
            />
          </div>
          <div className="field--form">
            <label className="form--label--client">Apellido: </label>
            <input
              name="apellido"
              className={`${
                isEditionStage ? 'on--edit' : 'form--input--client'
              }`}
              type="text"
              value={formData.apellido}
              onChange={(event) => handleOnchangeInput(event)}
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Provincia: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={data.dataBilletera[0].nombreOrganizacion}
            />
          </div>
          <div className="field--form">
            <label className="form--label--client">Direccion: </label>
            <input
              name="direccion"
              className={`${
                isEditionStage ? 'on--edit' : 'form--input--client'
              }`}
              type="text"
              value={formData.direccion}
              onChange={(event) => handleOnchangeInput(event)}
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Localidad: </label>
            <select
              className={`${
                isEditionStage ? 'on--edit' : 'form--input--client'
              }`}
              name="localidad"
              value={formData.localidad}
              onChange={(event) => handleOnchangeInput(event)}
            >
              <option className="option--default" value="">
                {formData.localidad}
              </option>
              {locationOptions}
            </select>
          </div>
          <div className="field--form">
            <label className="form--label--client">Cuil: </label>
            <input
              name="cuil"
              className="form--input--client"
              type="text"
              defaultValue={data.cuil}
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Genero: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={data.genero}
            />
          </div>
          <div className="field--form">
            <label className="form--label--client">Teléfono: </label>
            <input
              name="nroTelefono"
              className={`${
                isEditionStage ? 'on--edit' : 'form--input--client'
              }`}
              type="text"
              value={formData.nroTelefono}
              onChange={(event) => handleOnchangeInput(event)}
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Fecha Alta: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={formData.fechaAlta}
            />
          </div>
          <div className="field--form">
            <label className="form--label--client">Fecha Baja: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue=""
            />
          </div>
        </div>
        <div className="form--row">
          <div className="field--form">
            <label className="form--label--client">Correo: </label>
            <input
              className="form--input--client"
              type="text"
              defaultValue={data.email}
            />
          </div>
          <div className="field--form">
            <button
              onClick={(event) => handleSendEmail(event)}
              className={
                data.active ? 'button--form--disabled' : 'button--form'
              }
              disabled={data.active}
            >
              Reenviar Correo de Activacion
            </button>
          </div>
        </div>
        <div className="form--row">
          <div className="check--item">
            <label className="form--label--client">Notif. Correo: </label>
            <input
              className={'form--input--client'}
              type="checkbox"
              defaultChecked={data.recibeMsjEmail}
            />
          </div>
          <div className="check--item">
            <label className="form--label--client">Activo: </label>
            <input
              className={'form--input--client'}
              type="checkbox"
              defaultChecked={data.active}
            />
          </div>
          <div className="check--item">
            <label className="form--label--client">Bloqueado: </label>
            <input
              className="form--input--client"
              type="checkbox"
              defaultChecked={data.bloqueado}
            />
          </div>
          <div className="check--item">
            <label className="form--label--client">Apuestas: </label>
            <input
              className="form--input--client"
              type="checkbox"
              defaultChecked={data.enableApuesta}
            />
          </div>
          <div className="check--item">
            <label className="form--label--client">Depositos: </label>
            <input
              className="form--input--client"
              type="checkbox"
              defaultChecked={data.enableDeposito}
            />
          </div>
          <div className="check--item">
            <label className="form--label--client">Retiros: </label>
            <input
              className="form--input--client"
              type="checkbox"
              defaultChecked={data.enableRetiro}
            />
          </div>
        </div>
      </form>
      <AnimatePresence>
        {errorModal.hadError && (
          <ErrorHandler
            setIsLogged={setIsLogged}
            errorMessage={errorModal.errorMessage}
          />
        )}
        {sendEmailInfo && (
          <ModalLayout
            setPreview={setSendEmailInfo}
            preview={sendEmailInfo}
            title={'Correo Enviado'}
            noScroll={true}
            info={true}
          >
            <LuMailCheck className="success--icon" />
            <p className="info--message">
              Se ha vuelto a enviar al cliente el correo de activación de
              cuenta.
            </p>
            <div className="buttons--container">
              <button
                className="button--form"
                onClick={() => setSendEmailInfo(false)}
              >
                OK
              </button>
            </div>
          </ModalLayout>
        )}
      </AnimatePresence>
    </>
  )
}

export default FormPersonalData
