// react imports
import { useState } from 'react'

// icons imports
import { FaSearch } from 'react-icons/fa'

import { PiCircleDashedBold } from 'react-icons/pi'

import { BiSolidErrorCircle } from 'react-icons/bi'

// third party imports
import { useNavigate } from 'react-router'

// local imports
import fetchData from '../../../libs/clients/fetchData'
import { handleOnChange } from '../../../utils/utils'

// constants imports
import { STATUS, VALIDATION_FORM } from '../../../utils/constants'

import ErrorHandler from '../../../utils/ErrorHandler'
import { useAppContext } from 'context/AppContext'

// NOTE: Currently the select field in the form doesn't have any action.

/* It displays a form which collects info needed to search the client desired, it receives two properties. */
const FormClients = ({ organizations }) => {
const {setIsLogged} = useAppContext()

  // This state is used to trigger the form's button animations.
  const [userExist, setUserExits] = useState({
    status: STATUS.initial,
    message: 'Error de conexión'
  })

  // This state is used to handle form's errors
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  // It stores the info loaded in the form, which is going to be utilized to fetch the client.
  const [clientSearch, setClientSearch] = useState({
    user: '',
    organization: ''
  })

  // Function that validate the form data.
  const validateForm = () => {
    const errors = {}

    if (!clientSearch.user.trim()) {
      errors.user = 'Usuario requerido'
    } else if (
      !VALIDATION_FORM.userPattern.test(clientSearch.user) ||
      clientSearch.user.length < VALIDATION_FORM.userLength
    ) {
      errors.user = 'Formato invalido'
    }

    // if (!clientSearch.organization.trim()) errors.user = 'Organización requerida'

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  // Building options to display in the select field
  const selectOptions = organizations.map((element, index) => {
    return (
      <option key={index} value={element.codigo}>
        {element.descripcion}
      </option>
    )
  })

  // It handles the submission of the data getting the data from the endpoint.
  const handleSubmit = async (event) => {
    event.preventDefault()
    const url = `${process.env.REACT_APP_WS_CONS_USUARIOS}?documento=${clientSearch.user}`
    if (validateForm()) {
      setUserExits((prev) => ({ ...prev, status: STATUS.loading }))
      try {
        const data = await fetchData(url)
        navigate(`/clientes/cliente-id/${data.items[0].id}`)
      } catch (error) {
        const sessionExpired =
          parseInt(error.code) ===
          parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION)
        if (sessionExpired) {
          setUserExits((prev) => ({
            status: STATUS.expired,
            message: error.message || prev.message
          }))
        } else {
          setUserExits((prev) => ({
            status: STATUS.error,
            message: error.message || prev.message
          }))
        }
      }
    }
  }

  return (
    <>
      {userExist.status === STATUS.expired ? (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={userExist.message}
        />
      ) : (
        <div>
          <form>
            <div className="form--client-field">
              <label>Nro. Documneto / Correo</label>
              <input
                type="text"
                value={clientSearch.user}
                name="user"
                onChange={(event) => handleOnChange(event, setClientSearch)}
              />
            </div>
            <div className="form--client-field">
              <label>Seleccione Organización</label>
              <select
                name="organization"
                value={clientSearch.orgenization}
                onChange={(event) => handleOnChange(event, setClientSearch)}
              >
                <option value="">--Seleccione una opción--</option>
                {selectOptions}
              </select>
            </div>
            <button
              className="button--form"
              onClick={(event) => handleSubmit(event)}
              disabled={userExist.status === STATUS.loading ? true : false}
            >
              {userExist.status === STATUS.loading ? (
                <PiCircleDashedBold className="icon--spin" />
              ) : (
                <FaSearch />
              )}
            </button>
          </form>
          <div className="container--error--message">
            {(userExist.status === STATUS.error || errors.user) && (
              <p className="pop--message">
                <BiSolidErrorCircle />
                {errors.user ? errors.user : 'Usuario no encontrado'}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FormClients
