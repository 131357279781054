// react imports
import { useState } from 'react'

// components imports
import ItemList from './ItemList'

// libs imports
import getReports from '../../libs/custom-reports/getReports'

// ====================================================================== || CUSTOM REPORTS || ====================================================================================== //

const SideNavbar = ({ items, selected, setInfoModal, setErrorModal }) => {
  // Because of it's necessary that each element of the list behave independent of each other, It is used this parse item to build an object to give that quality to each item.
  const parseItems = items.map((item) => {
    return {
      id: item.id,
      name: item.nombre,
      description: item.descripcion || item.name,
      itemSelected: false,
      key: item.nombre
    }
  })

  // The state of each element is stored in this hook, it allows to track the changes that they can suffer.
  const [itemsList, setItemsList] = useState(parseItems)

  // Getting the mockup data of each item of the list in advance, because they are used in the sideBar and hte FormCustomReports components.
  // Simulating a real fetch form an ENDPOINT.
  const gettingReports = itemsList.map((element) => {
    return getReports(element.id)
  })

  // It stores the data fetch depending on the clicked side navBar field.
  const [reports] = useState(gettingReports)

  // It builds each item list with the item list object created previously and stores it in this array.
  const itemsToDisplay = itemsList.map((element, index) => {
    return (
      <ItemList
        key={element.id}
        listElements={element}
        setItemsList={setItemsList}
        setSelected={selected}
        itemList={itemsList}
        reports={reports[index]}
        setInfoModal={setInfoModal}
        setErrorModal={setErrorModal}
      />
    )
  })

  return <ul className="sideNavbar--list">{itemsToDisplay}</ul>
}

export default SideNavbar
