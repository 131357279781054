// react imports
import React, { useState, useEffect, ReactElement } from 'react'
// third party libraries
import { AnimatePresence } from 'framer-motion'
// custom hooks
import { useTableData } from 'utils/custom-hooks/useDataClient'
// components
import LayoutOperatorAction from '../../../modals/LayoutOperatorAction'
import STable from './STable'
import SForm from './SForm'
// table structure
import { operatorsSystem } from 'components/tables/table-columns-structure/systemTableColumns'
// services
import systemServices from 'services/system/systemServices'
// utils
import { STATUS } from 'utils/constants'
// types
import {
  type FormFieldsOperators,
  type Operator,
  type Operators,
  type SystemProps
} from '../SystemTypes'


// structure
const OPERATION_TYPE = { edition: 'edition', addition: 'addition' }
// structure
const newOperator: Operator = {
  id: '',
  username: '',
  nombre: '',
  apellido: '',
  active: false,
  bloqueado: false,
  roles: [],
  organizaciones: []
}

// ====================== || SYSTEM OPERATORS ||================================
/**
 * @param setIsLogged - It controls the user session
 * @param org - The organization
 * @returns - A component that renders the system operators page
 */
const SystemOperators = ({
  setIsLogged,
  org
}: SystemProps): ReactElement => {

  // Section: State
  const [showTable, setShowTable] = useState<string>(STATUS.initial)
  const [preview, setPreview] = useState<boolean>(false)
  const [operators, setOperators] = useState<Operators | null>(null)
  const [formFields, setFormFields] = useState<FormFieldsOperators | null>(null)
  const [operator, setOperator] = useState<Operator>(newOperator)
  const [addOperatorModal, setAddOperatorModal] = useState<boolean>(false)

  // Section: Using Custom Hook
  const { itemData, setItemData, page, setPage } = useTableData()

  // This status handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // Section: Component Actions
  const handlePreviewOnclick = (id: any) => {
    systemServices.getOperator(id)
      .then((response) => {
        setOperators(response)
      }).catch(() => {
        setErrorModal(errorModal)
      })
  }

  // Section: Effects
  useEffect(() => {
    if (operators !== null) {
      setPreview(true)
    }
  }, [operators])

  useEffect(() => {
    if (itemData !== null) {
      if (!itemData.totalItems) {
        setShowTable(STATUS.error)
      } else {
        setShowTable(STATUS.success)
      }
    }
  }, [itemData])

  useEffect(() => {
    if (formFields !== null) {
      systemServices.getOperatorsList(formFields, page)
        .then((response) => {
          setItemData(response)
        }).catch((error) => {
          setErrorModal({ hadError: true, errorMessage: error.message })
        })
    }
  }, [formFields, page, setItemData])

  // Section: TSX
  return (
    <div className="data--result--container">
      <SForm
        setItemData={setItemData}
        setErrorModal={setErrorModal}
        setFormFields={setFormFields}
        setAddOperatorModal={setAddOperatorModal}
        org={org}
      />
      <STable
        showTable={showTable}
        operatorsSystem={operatorsSystem}
        itemData={itemData}
        handlePreviewOnclick={handlePreviewOnclick}
        page={page}
        setPage={setPage}
      />

      <AnimatePresence>
        {preview && (
          <LayoutOperatorAction
            setPreview={setPreview}
            preview={preview}
            data={operators}
            setData={setOperators}
            operationType={OPERATION_TYPE.edition}
            title={'Detalle de Operador'}
          />
        )}
        {addOperatorModal && (
          <LayoutOperatorAction
            preview={addOperatorModal}
            setPreview={setAddOperatorModal}
            data={operator}
            setData={setOperator}
            operationType={OPERATION_TYPE.addition}
            title={'Alta Nuevo Operador'}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SystemOperators
