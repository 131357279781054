// react imports
import { useState, useEffect } from 'react'

// components imports
import SideNavbar from '../side-nav-bar/SideNavBar'
import ErrorHandler from '../../utils/ErrorHandler'
import Information from '../../modals/Information'
import FormCustomReports from '../forms/custom-reports/FormCustomReports'

// libs imports
import getGroups from '../../libs/custom-reports/getGroups'
import fetchData from '../../libs/clients/fetchData'

// third party libraries
import { AnimatePresence } from 'framer-motion'

import { PiCircleDashedBold } from 'react-icons/pi'

const CUSTOM_REPORTS_TITLE = 'Reportes a medida'

const storageName = 'reportsGroups'

// ====================================================================== || CUSTOM REPORTS || ====================================================================================== //

const CustomReports = ({ setIsLogged, organizations }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // This state handles information about the action the user wants to execute.
  const [infoModal, setInfoModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const storageData = JSON.parse(localStorage.getItem(storageName))

  // Getting the mockup of the data, by using a structure of a normal fetch.
  const [groups, setGroups] = useState(storageData || null)

  const getGroups = async () => {
    const url = process.env.REACT_APP_WS_GRUPOS_REPORTES

    try {
      const response = await fetchData(url)
      setGroups(response.grupos)
      localStorage.setItem(storageName, JSON.stringify(response.grupos))
    } catch (error) {
      const sessionExpired = parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION)
      if (error.code === sessionExpired) {
        setErrorModal({ hadError: true, errorMessage: error.message })
      } else {
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
      }
    }
  }

  useEffect(() => {
    if (groups === null) getGroups()
  }, [])

  // It stores the value of the selected item and listens whether there's a change or not.
  const [itemSelected, setItemSelected] = useState(false)

  // It stores the value that the title has to feature.
  const [containerName, setContainerName] = useState(CUSTOM_REPORTS_TITLE)

  // It sets the current value that the title, depending on the item selected in the sideBar.
  useEffect(() => {
    if (itemSelected) {
      const currentItem = itemSelected.reports.find(
        (item) => item.id === itemSelected.id
      )
      setContainerName(currentItem.titulo)
    } else {
      setContainerName(CUSTOM_REPORTS_TITLE)
    }
  }, [itemSelected])

  return (
    <div className="report--container forms--template">
      <h1> {containerName} </h1>

      <div className="report--content--container">
        {groups !== null ? (
          <SideNavbar
            items={groups}
            selected={setItemSelected}
            setInfoModal={setInfoModal}
            setErrorModal={setErrorModal}
          />
        ) : (
          <PiCircleDashedBold className="icon--spin" />
        )}

        {itemSelected && (
          <FormCustomReports
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            organizations={organizations}
            setInfoModal={setInfoModal}
            setErrorModal={setErrorModal}
          />
        )}
      </div>
      <AnimatePresence>
        {errorModal.hadError && (
          <ErrorHandler
            setIsLogged={setIsLogged}
            errorMessage={errorModal.errorMessage}
          />
        )}
        {infoModal.isThereInfo && (
          <Information setInfoModal={setInfoModal} infoModal={infoModal} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default CustomReports
