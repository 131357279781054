// icons imports
import React from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

const GNavFooterBar = ({
  page,
  setPage,
  range,
}: any) => {
  // This function handle the onClick action in the arrows that move the page as forward as backward.
  const handleOnclick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, arrow: string) => {
    event.preventDefault()

    if (arrow === 'back-arrow' && page - 1 >= 0) setPage(page - 1)

    if (arrow === 'forward-arrow' && page + 1 < range) setPage(page + 1)
  }

  return (
    <div className="arrows--container">
      <button onClick={(event) => handleOnclick(event, 'back-arrow')}>
        <MdArrowBackIosNew className="arrow--footer--table" />
      </button>
      <div className="current--page">
        <p>{`${page === 0 ? 1 : parseInt(page) + 1} de ${range}`}</p>
      </div>
      <button onClick={(event) => handleOnclick(event, 'forward-arrow')}>
        <MdArrowForwardIos className="arrow--footer--table" />
      </button>
    </div>
  )
}

export default GNavFooterBar
