function downloadBlob(blob, name, type) {
  // Create a Blob object with the specified MIME type
  const newBlob = new Blob([blob], { type })

  // Check if the browser supports msSaveOrOpenBlob (for Internet Explorer)
  if (window.navigator?.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link element and set its attributes
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = name

  // Trigger a click event on the link to initiate the download
  link.click()

  // Delay revoking the ObjectURL for compatibility, especially for Firefox
  setTimeout(() => {
    window.URL.revokeObjectURL(data)
  }, 100)
}

export function showPDF(blob, name) {
  downloadBlob(blob, name + '.pdf', 'application/pdf')
}

export function showXLS(blob, name) {
  downloadBlob(blob, name + '.xls', 'application/vnd.ms-excel')
}
