// react import
import React, { useState } from 'react'
import type { ReactElement } from 'react'

// react router import
import { Outlet, useNavigate } from 'react-router'

// nav bar items import
import getOptionsNavBarSystem from 'libs/system/getOptionsNavBarSystem'

// types imports
import { type SystemOption } from './systemTypes'

/**
 * @returns - An JSX element that displays the systemAgency page as index
 */
const System = (): ReactElement => {
  const navigate = useNavigate()
  // Section: State
  const [options, setOptions] = useState(getOptionsNavBarSystem)

  // Section: OnClick functions
  // It handles the actions if the user click on of the options.
  const handleOnclick = (element: SystemOption): void => {
    navigate(element.path)
    setOptions((options) => {
      const newOptions = options.map((option) => {
        return {
          ...option,
          clicked: option.name === element.name
        }
      })

      return newOptions
    })
  }

  // Section: Composing
  // it composes the options that are displayed in the navBar
  const systemOptions = options.map((element, index) => {
    return (
      <button
        key={index}
        onClick={() => {
          handleOnclick(element)
        }}
        className={
          element.clicked
            ? 'client--buttons selected--button'
            : 'client--buttons'
        }
      >
        {element.name}
      </button>
    )
  })

  return (
    <div className="forms--template">
      <h1> Sistema </h1>
      <div className="client--result--container">
        <div className="side--navbar--system">{systemOptions}</div>
        <Outlet />
      </div>
    </div>
  )
}

export default System
