//  http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/billetera/a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb/cupones?desde=20220627&hasta=20230817&page=0

const getCoupons = () => {
  return {
    status: 'ok',
    errores: [],
    totalItems: 1193,
    totalPages: 24,
    currentPage: 0,
    items: [
      {
        id: '24934677-e2b1-4af8-bd04-d53421b9de42',
        codigoJuego: 0,
        cantidadApuestas: 4,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-12T15:09:07.000+00:00',
        total: 60.0,
        nuc: 124857,
        sorteo: {
          sorteado: false,
          fechaEvento: '2023-10-13T00:00:00.000+00:00'
        }
      },
      {
        id: 'ceddc18f-c163-41ef-920c-90ffe9da4e2e',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 70.0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-31T13:54:02.000+00:00',
        total: 30.0,
        nuc: 125057,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-31T13:54:02.479+00:00'
        }
      },
      {
        id: '4bafea2a-54b8-477f-9b51-1ed322f63bb3',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-31T13:15:06.000+00:00',
        total: 30.0,
        nuc: 125056,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-31T13:15:06.314+00:00'
        }
      },
      {
        id: '477f8ed1-2e5e-414c-9781-bc58018e3e5b',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-31T13:15:00.000+00:00',
        total: 30.0,
        nuc: 125055,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-31T13:15:00.358+00:00'
        }
      },
      {
        id: '2554d52a-69aa-490b-8569-d398f65784ca',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-31T13:14:54.000+00:00',
        total: 30.0,
        nuc: 125054,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-31T13:14:54.666+00:00'
        }
      },
      {
        id: 'e930f49c-31f4-41ea-85c8-edaf44ec258a',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T17:22:23.000+00:00',
        total: 30.0,
        nuc: 125053,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T17:22:23.416+00:00'
        }
      },
      {
        id: '33ee3cd4-86fe-4524-8a23-1c737725d936',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T17:22:14.000+00:00',
        total: 30.0,
        nuc: 125052,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T17:22:14.653+00:00'
        }
      },
      {
        id: '1aed5751-f297-450d-a895-64c65bec76d1',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T17:22:08.000+00:00',
        total: 30.0,
        nuc: 125051,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T17:22:08.650+00:00'
        }
      },
      {
        id: '9e8b1ec4-8d01-49e3-965e-5f609a3f53d8',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T15:38:57.000+00:00',
        total: 30.0,
        nuc: 125050,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T15:38:57.683+00:00'
        }
      },
      {
        id: '5871ba1c-9019-4988-b5bb-6cab0728d217',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T15:38:49.000+00:00',
        total: 30.0,
        nuc: 125049,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T15:38:49.850+00:00'
        }
      },
      {
        id: '55f21681-727c-4323-8389-e68cb74ed82a',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 70.0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:42:51.000+00:00',
        total: 30.0,
        nuc: 125048,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:42:51.948+00:00'
        }
      },
      {
        id: '7a0005c3-22b2-4027-8ab9-97a9937c5335',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:34:16.000+00:00',
        total: 30.0,
        nuc: 125047,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:34:16.074+00:00'
        }
      },
      {
        id: '15f2c8ed-47bb-4639-a74e-fbd901fb7536',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:34:08.000+00:00',
        total: 30.0,
        nuc: 125046,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:34:08.670+00:00'
        }
      },
      {
        id: 'df09b57f-9aa8-47f0-838b-2cf24ed1b222',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:34:02.000+00:00',
        total: 30.0,
        nuc: 125045,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:34:02.567+00:00'
        }
      },
      {
        id: 'fb0164a8-503f-45a3-99b8-4ef6b728508c',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:27:39.000+00:00',
        total: 30.0,
        nuc: 125044,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:27:39.853+00:00'
        }
      },
      {
        id: '4a732c84-cdec-4f3e-b8d0-bd267f99e022',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:27:23.000+00:00',
        total: 30.0,
        nuc: 125043,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:27:23.713+00:00'
        }
      },
      {
        id: '0938110f-c75f-4a4e-ab9b-9cd9befbca7c',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T14:27:17.000+00:00',
        total: 30.0,
        nuc: 125042,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T14:27:17.619+00:00'
        }
      },
      {
        id: 'e179614d-a4bc-4035-bc14-f5c09c5637bb',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:49:02.000+00:00',
        total: 30.0,
        nuc: 125041,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:49:02.277+00:00'
        }
      },
      {
        id: '2a1e11ae-012d-4d66-a921-8e71ebb3a6f4',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:48:58.000+00:00',
        total: 30.0,
        nuc: 125040,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:48:58.764+00:00'
        }
      },
      {
        id: 'a6b11290-622e-42d9-a176-a384c30f6549',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:48:52.000+00:00',
        total: 30.0,
        nuc: 125039,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:48:52.354+00:00'
        }
      },
      {
        id: '44af50e3-e024-44a7-9c2a-36cfee80502a',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:48:47.000+00:00',
        total: 30.0,
        nuc: 125038,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:48:47.189+00:00'
        }
      },
      {
        id: 'f162ee9a-b868-4445-b045-990f906e846e',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:47:20.000+00:00',
        total: 10.0,
        nuc: 125037,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:47:20.730+00:00'
        }
      },
      {
        id: '5b0a24de-2ed3-4046-b4a0-7be5a371ae77',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:47:13.000+00:00',
        total: 10.0,
        nuc: 125036,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:47:13.242+00:00'
        }
      },
      {
        id: '2d341ccb-1116-4cb9-8dab-fc7fe52f4169',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:47:04.000+00:00',
        total: 10.0,
        nuc: 125035,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:47:04.845+00:00'
        }
      },
      {
        id: '7df3cc39-0edf-44be-85d0-0be7ff1cd569',
        codigoJuego: 1,
        cantidadApuestas: 4,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:45:52.000+00:00',
        total: 130.0,
        nuc: 125034,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:45:52.608+00:00'
        }
      },
      {
        id: '050e310f-604d-4375-8adb-bec97a57999c',
        codigoJuego: 1,
        cantidadApuestas: 4,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:45:44.000+00:00',
        total: 130.0,
        nuc: 125033,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:45:44.673+00:00'
        }
      },
      {
        id: 'e09447e6-98af-4aae-8aba-9e2fa53f6439',
        codigoJuego: 1,
        cantidadApuestas: 4,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:45:38.000+00:00',
        total: 130.0,
        nuc: 125032,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:45:38.058+00:00'
        }
      },
      {
        id: '642ef5b6-182c-49e6-8d6a-a7aa75074bdb',
        codigoJuego: 1,
        cantidadApuestas: 4,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-27T13:45:27.000+00:00',
        total: 130.0,
        nuc: 125031,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-27T13:45:27.676+00:00'
        }
      },
      {
        id: '035db6fe-41ce-449b-8e32-934624db75d2',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:29:12.000+00:00',
        total: 30.0,
        nuc: 125030,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:29:12.618+00:00'
        }
      },
      {
        id: 'b80fa91f-28a8-4177-a547-5b37ae73a856',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:28:35.000+00:00',
        total: 20.0,
        nuc: 125029,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:28:35.857+00:00'
        }
      },
      {
        id: '7decf5d0-5751-42a5-a226-2756f8aba606',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:28:28.000+00:00',
        total: 20.0,
        nuc: 125028,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:28:28.895+00:00'
        }
      },
      {
        id: 'f6f3dba9-779a-449a-a035-61cb5b1cd5df',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:28:24.000+00:00',
        total: 20.0,
        nuc: 125027,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:28:24.795+00:00'
        }
      },
      {
        id: 'e0505bce-f0f6-4345-ac81-b38b72e3ea92',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:28:20.000+00:00',
        total: 20.0,
        nuc: 125026,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:28:20.572+00:00'
        }
      },
      {
        id: '889265c0-4faa-46e3-8f97-4989478ad92d',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:28:12.000+00:00',
        total: 20.0,
        nuc: 125025,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:28:12.427+00:00'
        }
      },
      {
        id: '846fc156-cc23-4b2f-94a2-b0e510a56ea2',
        codigoJuego: 1,
        cantidadApuestas: 2,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:27:53.000+00:00',
        total: 20.0,
        nuc: 125024,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:27:53.637+00:00'
        }
      },
      {
        id: '6ac0ac42-7022-4843-84ce-6ec8c5432ebf',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:14:01.000+00:00',
        total: 100.0,
        nuc: 125023,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:14:01.453+00:00'
        }
      },
      {
        id: '0c287f34-0cb0-47ba-b9d4-50374054141b',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:13:25.000+00:00',
        total: 10.0,
        nuc: 125022,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:13:25.631+00:00'
        }
      },
      {
        id: 'ce13d810-e28f-4832-8a69-77cbb452212a',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:13:18.000+00:00',
        total: 10.0,
        nuc: 125021,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:13:18.944+00:00'
        }
      },
      {
        id: 'e70bd1c6-aeb5-474c-b469-9084f597e043',
        codigoJuego: 1,
        cantidadApuestas: 1,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-25T16:13:08.000+00:00',
        total: 10.0,
        nuc: 125020,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-25T16:13:08.300+00:00'
        }
      },
      {
        id: '29963827-60fb-4822-8964-e14885b72b8b',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-24T18:28:44.000+00:00',
        total: 30.0,
        nuc: 125019,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-24T18:28:44.890+00:00'
        }
      },
      {
        id: '1eeff64c-a4aa-4562-a768-b8f77d046d96',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-24T18:28:38.000+00:00',
        total: 30.0,
        nuc: 125018,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-24T18:28:38.945+00:00'
        }
      },
      {
        id: 'c3d41a8b-bae2-479b-b3b2-b81c9480b3f4',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 70.0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-24T18:27:15.000+00:00',
        total: 30.0,
        nuc: 125017,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-24T18:27:15.149+00:00'
        }
      },
      {
        id: '1c8a1bb1-7ba6-4bdf-bfb2-c93b671ad25b',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 70.0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-24T18:26:39.000+00:00',
        total: 30.0,
        nuc: 125016,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-24T18:26:39.773+00:00'
        }
      },
      {
        id: 'f83adc7d-1afa-4fb1-9e5c-34ef67cb212a',
        codigoJuego: 1,
        cantidadApuestas: 3,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-17T11:16:12.000+00:00',
        total: 30.0,
        nuc: 125010,
        sorteo: {
          sorteado: true,
          fechaEvento: '2023-07-17T11:16:12.713+00:00'
        }
      },
      {
        id: 'a9935ff7-047d-4f7e-b1ff-b1f985aa41cf',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-17T14:46:51.000+00:00',
        total: 2000.0,
        nuc: 125015,
        sorteo: {
          sorteado: false,
          fechaEvento: '2024-01-20T20:00:00.000+00:00'
        }
      },
      {
        id: '7bbe2474-c83f-44f2-8cfb-26c0128734f6',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-17T14:46:50.000+00:00',
        total: 4000.0,
        nuc: 125014,
        sorteo: {
          sorteado: false,
          fechaEvento: '2024-01-13T20:00:00.000+00:00'
        }
      },
      {
        id: 'a65724a8-0630-4f1b-828a-9bc0ec91672b',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-17T14:46:50.000+00:00',
        total: 7000.0,
        nuc: 125013,
        sorteo: {
          sorteado: false,
          fechaEvento: '2024-01-06T20:00:00.000+00:00'
        }
      },
      {
        id: 'f69e0baa-fb9d-45cd-9a5a-9090569b3d2c',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-17T14:46:50.000+00:00',
        total: 10000.0,
        nuc: 125012,
        sorteo: {
          sorteado: false,
          fechaEvento: '2023-12-30T20:00:00.000+00:00'
        }
      },
      {
        id: 'e43defdf-9088-4ae8-b3c2-3f9cee62df1b',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-12T14:54:55.000+00:00',
        total: 2000.0,
        nuc: 124856,
        sorteo: {
          sorteado: false,
          fechaEvento: '2024-01-20T20:00:00.000+00:00'
        }
      },
      {
        id: '59a0c882-2330-4a9e-a4e2-66156fe98f1d',
        codigoJuego: 10,
        cantidadApuestas: 0,
        importeGanado: 0,
        estadoCupon: 'CORRECTO',
        fecha: '2023-07-12T14:54:54.000+00:00',
        total: 4000.0,
        nuc: 124855,
        sorteo: {
          sorteado: false,
          fechaEvento: '2024-01-13T20:00:00.000+00:00'
        }
      }
    ]
  }
}

export default getCoupons
