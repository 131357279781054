// react imports
import { useState, useEffect } from 'react'

// third party imports
import { useOutletContext } from 'react-router-dom'

// components imports
import FormClientResults from '../../forms/clients/FormClientResults'
import TableWallet from '../../tables/clients/TableWallet'
import WalletBalance from './WalletBalance'

// libs imports
import getFormattedDate from '../../../libs/shared/getFormattedDate'
import getCurrentDate from '../../../libs/shared/getCurrentDate'
import getMovements from '../../../libs/clients/getMovements'

// local hook
import { useDataClient } from '../../../utils/custom-hooks/useDataClient'
import ErrorHandler from '../../../utils/ErrorHandler'

// const imports
import { LOCAL_STORAGE_CLIENTS } from '../../../utils/constants'
import { STATUS } from '../../../utils/constants'
import { useSelectOptions } from '../../../utils/custom-hooks/useSelectOptions'

// ====================================================================== || WALLET || ====================================================================================== //

const Wallet = ({ setIsLogged }) => {
  // const org = localStorage.getItem('userOrgCod')
  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate(),
    type: 'Todos'
  }

  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.wallet.form,
    LOCAL_STORAGE_CLIENTS.wallet.tablePage,
    formStructure
  )

  const [rawData] = useOutletContext()

  const data = rawData.dataBilletera

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.wallet.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  // It stores the movementTypes to features them as option in the form.
  // To make the fetch it requires the organization cod as parameter.
  // const [movementTypes, setMovementTypes] = useState(false)
  const [options, setOptions] = useState()
  const { movementTypes } = useSelectOptions(
    setErrorModal,
    process.env.REACT_APP_WS_TIPOS_MOV_BILLETERA
  )

  useEffect(() => {
    if (movementTypes) {
      // Based on the movementTypes it builds the options with this data.
      const buildingOptions = movementTypes.tiposMovimientos.map((movement) => {
        return (
          <option key={movement.id} value={movement.descripcion}>
            {movement.descripcion}
          </option>
        )
      })
      setOptions(buildingOptions)
    }
  }, [movementTypes])

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    },
    {
      type: 'select',
      label: 'Tipo',
      name: 'type',
      default: 'Todos',
      options
    }
  ]

  const tableHeader = ['Fecha', 'Importe', 'Concepto', 'Tipo Movimiento']
  const [tableContent, setTableContent] = useState(0)

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      // This function build an array where each item represent a row of the table.
      const content = itemData.dataFetched.items.map((movement, index) => {
        return (
          <tr
            key={movement.id}
            className={index % 2 === 0 ? 'add--row--color' : ''}
          >
            <td>{getFormattedDate(movement.fecha)}</td>
            <td className="item--center"> {movement.importe} </td>
            <td className="item--center"> {movement.concepto}</td>
            <td className="item--center"> {movement.tipoMovimiento}</td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const typeId = (dataHolder) => {
    const types = movementTypes.tiposMovimientos
    let typeSelected =
      dataHolder.type === 'Todos'
        ? ''
        : types.find((item) => item.descripcion === dataHolder.type)
    return typeSelected ? `&tipo=${typeSelected.id}` : ''
  }

  const requestPath = (dataHolder) => {
    const walletId = data[0].billeteraId
    const urlType = typeId(dataHolder)

    const parseUrl = process.env.REACT_APP_WS_CONS_BILLETERA.replace(
      ':idBilletera',
      walletId
    )
    const urlDateReplaced = `?desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`

    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: urlType
    }

    setCommonUrl(url)
    localStorage.setItem(LOCAL_STORAGE_CLIENTS.wallet.url, JSON.stringify(url))

    return `${parseUrl}${urlDateReplaced}${0}${urlType}`
  }

  return (
    <>
      <div className="data--result--container">
        <WalletBalance
          balance={data[0].saldo}
          withdrawableBalance={data[0].saldoRetirable}
        />
        <div className="container--results">
          <FormClientResults
            formFields={formFields}
            data={itemData}
            setFormData={setItemData}
            setPage={setPage}
            storageName={LOCAL_STORAGE_CLIENTS.wallet.form}
            setCommonUrl={setCommonUrl}
            setErrorModal={setErrorModal}
            requestPath={requestPath}
          />
          {dataStatus === STATUS.success ? (
            <TableWallet
              data={itemData}
              setFormData={setItemData}
              page={page}
              setPage={setPage}
              tableHeaders={tableHeader}
              tableContent={tableContent}
              fetchData={getMovements}
              commonUrl={commonUrl}
              setErrorModal={setErrorModal}
            />
          ) : dataStatus === STATUS.initial ? (
            <p></p>
          ) : (
            <p className="flex--container--message">
              No hay datos disponibles para el rango de fechas ingresada.
            </p>
          )}
        </div>
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </>
  )
}

export default Wallet
