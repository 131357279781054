// react imports
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router'

// icon imports
import { FaPen, FaCheck } from 'react-icons/fa'

// third party imports
import { AnimatePresence } from 'framer-motion'

// local components imports
import SolverModal from '../../modals/SolverModal'
import TableWallet from '../tables/clients/TableWallet'
import FormClientResults from '../forms/clients/FormClientResults'
import ErrorHandler from '../../utils/ErrorHandler'

// libs imports
import getUfiList from '../../libs/clients/getUfiList'
import getUfiStates from '../../libs/clients/getUfiStates'
import getCurrentDate from '../../libs/shared/getCurrentDate'
import getFormattedDate from '../../libs/shared/getFormattedDate'

// constants imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// hooks imports
import { useDataClient } from '../../utils/custom-hooks/useDataClient'

// ====================================================================== || UIF LIST || ====================================================================================== //

const UfiList = ({ setIsLogged }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.uifList.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate(),
    state: 'Todos'
  }
  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.uifList.form,
    LOCAL_STORAGE_CLIENTS.uifList.tablePage,
    formStructure
  )

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  const [solverModal, setSolverModal] = useState(false)
  // It stores the movementTypes to features them as option in the form.
  // To make the fetch it requires the organization cod as parameter.
  const ufiStates = getUfiStates()

  // Based on the gamesType it builds the options with this data.
  const buildingOptions = ufiStates.map((game, index) => {
    return (
      <option key={index} value={game}>
        {game}
      </option>
    )
  })

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    },
    {
      type: 'select',
      label: 'Estado',
      name: 'state',
      default: 'Todos',
      options: buildingOptions
    }
  ]
  const tableHeaders = [
    'Nombre',
    'Documento',
    'Estado',
    'Fecha',
    'Importe',
    'Resolver'
  ]
  const [tableContent, setTableContent] = useState(0)

  const [ufiData, setUfidata] = useState(0)

  const handlePreviewOnclick = (event, id) => {
    const findUfi = itemData.dataFetched.items.find((item) => item.id === id)
    const parsedData = {
      id: findUfi.usuarioId,
      date: getFormattedDate(findUfi.fecha)
    }
    setUfidata(parsedData)
    event.preventDefault()
    setSolverModal(true)
  }

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((ufi, index) => {
        return (
          <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
            <td>{ufi.nombre}</td>
            <td className="item--center"> {ufi.documento} </td>
            <td className="item--center"> {ufi.estado}</td>
            <td className="item--center">
              {' '}
              {getFormattedDate(ufi.fechaEstado)}
            </td>
            <td className="item--center"> {ufi.importe}</td>
            <td className="item--center">
              {ufi.estado === 'PENDIENTE' ? (
                <button
                  className="button--preview"
                  onClick={(event) => handlePreviewOnclick(event, ufi.id)}
                >
                  <FaPen className="pen--icon" />
                </button>
              ) : (
                <FaCheck />
              )}
            </td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const requestPath = (dataHolder) => {
    const org = localStorage.getItem('userOrgCod')

    const urlType =
      dataHolder.state === 'Todos' ? '' : dataHolder.state.toUpperCase()
    const urlStatus = `&estado=${urlType}`

    const walletId = data[0].billeteraId

    const parseUrl = `${process.env.REACT_APP_WS_LISTADO_UIF}?codigoOrganizacion=${org}&billeteraId=${walletId}`
    const urlDateReplaced = `&desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`
    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: urlStatus
    }

    setCommonUrl(url)
    localStorage.setItem(LOCAL_STORAGE_CLIENTS.uifList.url, JSON.stringify(url))

    return `${parseUrl}${urlDateReplaced}${0}${urlStatus}`
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          storageName={LOCAL_STORAGE_CLIENTS.uifList.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para el la consulta ingresada.
          </p>
        )}
        <AnimatePresence>
          {solverModal && (
            <SolverModal
              setSolverModal={setSolverModal}
              solverModal={solverModal}
              data={ufiData}
            />
          )}
        </AnimatePresence>
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </>
  )
}

export default UfiList
