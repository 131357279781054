// Mockup of the data fetched from the endpoint.
// ENDPOINT: http://billetera.tecnoaccion.com.ar:8705/reportes/grupos/2

// Depending on the id of the element, the fetch function is going to request the data.
const getReports = (id) => {
  return [
    {
      id: 201,
      key: 'uif',
      titulo: 'Premios mayores para información UIF',
      argumentos: [
        {
          nombre: 'importe',
          descripcion: 'Importe límite de premios diarios',
          tipo: 'java.math.BigDecimal',
          tipoSql: 2,
          obligatorio: true,
          defecto: '140000',
          orden: 1
        },
        {
          nombre: 'desde',
          descripcion: 'Fecha desde',
          tipo: 'java.util.Date',
          tipoSql: 12,
          obligatorio: false,
          defecto: '13/07/2023',
          orden: 2
        },
        {
          nombre: 'hasta',
          descripcion: 'Fecha Hasta',
          tipo: 'java.util.Date',
          tipoSql: 12,
          obligatorio: false,
          defecto: '14/07/2023',
          orden: 3
        }
      ]
    },
    {
      id: 202,
      key: 'sospe',
      titulo: 'Control de posible lavado',
      argumentos: [
        {
          nombre: 'desde',
          descripcion: 'Días desde',
          tipo: 'java.lang.Integer',
          tipoSql: 4,
          obligatorio: true,
          defecto: '1',
          orden: 1
        },
        {
          nombre: 'cuanto',
          descripcion: 'Cantidad de días',
          tipo: 'java.lang.Integer',
          tipoSql: 4,
          obligatorio: true,
          defecto: '15',
          orden: 2
        }
      ]
    }
  ]
}
export default getReports
