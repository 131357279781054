import { useState } from 'react'
import MassiveChangeModal from '../../modals/MassiveChangesModal'
import { AnimatePresence } from 'framer-motion'
import FormMassiveChange from '../forms/massive-changes/FormMassiveChange'

const MassiveChange = ({ setIsLogged, organizations }) => {
  const [changeResponse, setChangeResponse] = useState(false)
  const [response, setResponse] = useState(false)

  return (
    <div className="forms--template">
      <h1> Cambio Masivo Agencias </h1>
      <FormMassiveChange
        setChangeResponse={setChangeResponse}
        setResponse={setResponse}
        setIsLogged={setIsLogged}
        organizations={organizations}
      />
      <AnimatePresence>
        {changeResponse && (
          <MassiveChangeModal
            changeResponse={changeResponse}
            setChangeResponse={setChangeResponse}
            response={response}
          />
        )}
      </AnimatePresence>
    </div>
  )
}
export default MassiveChange
