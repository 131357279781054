import {
  MotivosResponse,
  TransferenciaRechazadoRequest,
  TransferenciaResponse
} from 'types/Transferencia.types'
import { HttpClient } from './http-services/HttpClient'
import { HttpRequest } from './http-services/HttpRequest'

const enum URL {
  API_OPERADOR_ADMIN_MOTIVOS = '/api-operador/admin/billetera/pedidos-retiro-transferencia/motivos',
  API_OPERADOR_ADMIN_TRANSFERENCIAS = '/api-operador/admin/billetera/pedidos-retiro-transferencia',
  API_OPERADOR_ADMIN_CONFIRMAR_RETIRO = '/api-operador/admin/billetera/pedido-retiro/confirmar-retiro',
  API_OPERADOR_ADMIN_RECHAZAR_RETIRO = '/api-operador/admin/billetera/pedido-retiro-transferencia/rechazar'
}

const createRequest = () => {
  const httpConfig: HttpRequest = {
    headers: {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('keySession') || '""')}`
    }
  }
  return httpConfig
}

export default class TransferenciaServices {
  private httpClient = new HttpClient(createRequest())

  getMotivos(organizationCode: number): Promise<MotivosResponse> {
    return this.httpClient.get(URL.API_OPERADOR_ADMIN_MOTIVOS, {
      codigoOrganizacion: organizationCode
    })
  }

  getTransferencias(
    organizationCode: number,
    page: number = 0,
    size: number = 10
  ): Promise<TransferenciaResponse> {
    return this.httpClient.get(URL.API_OPERADOR_ADMIN_TRANSFERENCIAS, {
      codigoOrganizacion: organizationCode,
      page,
      size
    })
  }

  postProcessTransferConfirm(
    codigoOrganizacion: number,
    transferList: string[]
  ): Promise<any> {
    return this.httpClient.post(URL.API_OPERADOR_ADMIN_CONFIRMAR_RETIRO, {
      idsRetiro: transferList,
      organizacion: codigoOrganizacion
    })
  }

  postProcessTransferReject(
    transferRequest: TransferenciaRechazadoRequest
  ): Promise<any> {
    return this.httpClient.post(
      URL.API_OPERADOR_ADMIN_RECHAZAR_RETIRO,
      transferRequest
    )
  }
}
