import React from 'react'
import NavBar from './nav-bar/NavBar'
import { Outlet } from 'react-router-dom'
import BackgroundLandingPage from './BackgroundLandingPage'
import { useAppContext } from 'context/AppContext'

export default function Root({version, name }) {
  const {isLogged, setIsLogged} = useAppContext()
  return (
    <>
      <div className="root--container">
        <NavBar
          setIsLogged={setIsLogged}
          isLogged={isLogged}
          version={version}
          name={name}
        />
      </div>
      <div className="body--container">
        <BackgroundLandingPage isLogged={isLogged} />
        <Outlet />
      </div>
    </>
  )
}
