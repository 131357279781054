const getOptionsNavBarClient = [
  {
    name: 'Datos Personales',
    clicked: true,
    path: '.'
  },
  {
    name: 'Billetera',
    clicked: false,
    path: 'billetera'
  },
  {
    name: 'Cupones',
    clicked: false,
    path: 'cupones'
  },
  {
    name: 'Solicitud de Retiros',
    clicked: false,
    path: 'solicitud-retiros'
  },
  {
    name: 'Cambio de Agencias',
    clicked: false,
    path: 'cambio-agencias'
  },
  {
    name: 'Listado UIF',
    clicked: false,
    path: 'listado-uif'
  },
  {
    name: 'Exclusión',
    clicked: false,
    path: 'exclusion'
  },
  {
    name: 'Reclamos',
    clicked: false,
    path: 'reclamos'
  }
]

export default getOptionsNavBarClient
