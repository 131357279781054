export const fields = (isOperatorEdition) => [
  {
    name: 'Id',
    item: 'id',
    isEditable: !isOperatorEdition
  },
  {
    name: 'Usuario',
    item: 'username',
    isEditable: true
  },
  {
    name: 'Nombre',
    item: 'nombre',
    isEditable: true
  },
  {
    name: 'Apellido',
    item: 'apellido',
    isEditable: true
  },
  {
    name: 'Bloqueado',
    item: 'bloqueado',
    isEditable: true
  },
  {
    name: 'Activo',
    item: 'active',
    isEditable: true
  }
]

export const rolesFields = (isOperatorEdition) => [
  {
    name: 'Id',
    item: 'id',
    isEditable: isOperatorEdition
  },
  {
    name: 'Nombre',
    item: 'nombre',
    isEditable: true
  },
  {
    name: 'Descripcion',
    item: 'descripcion',
    isEditable: true
  }
]

export const newRolesFields = () => [
  {
    name: 'Nombre',
    item: 'nombre',
    isEditable: true
  },
  {
    name: 'Descripcion',
    item: 'descripcion',
    isEditable: true
  }
]
