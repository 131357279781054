// It receives the as props the balance and withdrawableBalance to feature in teh walletBalance component.
const WalletBalance = ({ balance, withdrawableBalance }) => {
  // Since we receives both balances that have to be displayed as a currency format, It is used the numberFormat class.
  let numberFormat = new Intl.NumberFormat('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  })

  const formattedBalance = numberFormat.format(balance)
  const formattedWithdrawableBalance = numberFormat.format(withdrawableBalance)

  return (
    <div className="wallet--balance--container">
      <h2>Balance</h2>
      <div className="balance--amounts--container">
        <div className="withdrawable--balance--container">
          <p className="balance--description">Saldo</p>
          <p className="balance--amount">{formattedBalance}</p>
        </div>
        <div className="withdrawable--balance--container">
          <p className="balance--description">Retirable</p>
          <p className="balance--amount">{formattedWithdrawableBalance}</p>
        </div>
      </div>
    </div>
  )
}

export default WalletBalance
