// react imports
import { useState, useEffect } from 'react'

// libs imports
import fetchData from '../../libs/clients/fetchData'

const getMovementTypes = async (setSelectOptions, setErrorModal, path) => {
  const org = localStorage.getItem('userOrgCod')

  const url = `${path}?codigoOrganizacion=${org}`
  try {
    const fetchedData = await fetchData(url)
    setSelectOptions(fetchedData)
  } catch (error) {
    setErrorModal({ hadError: true, errorMessage: error.message })
  }
}

export const useSelectOptions = (setErrorModal, path, org) => {
  const [movementTypes, setSelectOptions] = useState(false)
  useEffect(() => {
    getMovementTypes(setSelectOptions, setErrorModal, path, org)
  }, [])

  return {
    movementTypes,
    setSelectOptions
  }
}
