// react imports
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'

// third party react libraries
import { useAnimate, usePresence } from 'framer-motion'
import { AiFillCloseCircle } from 'react-icons/ai'

// local imports
import { GetError, type AgencyResponse, GetInfo, ADataEditable } from 'components/system/SystemTypes'
import systemServices from 'services/system/systemServices'
import { formItems } from 'utils/agency-edition/agencyEditionObject'

// Types
type AEdition = {
  id: string
  preview: boolean
  data: AgencyResponse
  setPreview: Dispatch<SetStateAction<boolean>>
  setErrorModal: Dispatch<SetStateAction<GetError>>
  setInfoModal: Dispatch<SetStateAction<GetInfo>>
  getAgencies: () => Promise<void>
}

// ========================= || AGENCY EDITION || ==============================

const AgencyEdition = (props: AEdition): ReactElement => {
  // destructing props
  const {
    id,
    data,
    setPreview,
    getAgencies
  } = props

  // Hook that controls the modal behavior.
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  // Hooks to control the interactions with the modal.
  const [editAgency, setEditAgency] = useState(false)
  const [buttonName, setButtonName] = useState('Editar')
  const [dataEdited, setDataEdited] = useState<ADataEditable>({
    descripcion: data.descripcion,
    active: data.active
  })

  // Section: Effect
  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [animate, isPresent, safeToRemove, scope])

  // Section: Callback
  const formFields = useCallback(() => {

    return (
      formItems.map((element, index) => {
        let elementFound = null

        for (const key in data) {
          if (key === element.item) elementFound = data[key as keyof AgencyResponse]
        }

        return (
          <div key={index} className="field--form form--row">
            <label className="form--label">{element.name}</label>
            {typeof elementFound === 'boolean' ? (
              <input
                className={
                  element.isEditable && editAgency
                    ? 'form--input'
                    : 'form--input--client'
                }
                defaultChecked={elementFound}
                type="checkbox"
                name={element.item}
                checked={element.isEditable
                  ? dataEdited.active
                  : elementFound as boolean
                }
                value={element.name}
                onChange={(event) => handleOnChange(event)}
              />
            ) : (
              <input
                className={
                  element.isEditable && editAgency
                    ? 'form--input'
                    : 'form--input--client'
                }
                type="text"
                name={element.item}
                value={
                  element.isEditable
                    ? dataEdited.descripcion as string
                    : elementFound as string
                }
                onChange={(event) => handleOnChange(event)}
              />
            )}
          </div>
        )
      })
    )
  }, [data, dataEdited.active, dataEdited.descripcion, editAgency])

  // Section: Services
  const postAgency = async () => {
    const parameters = {
      descripcion: dataEdited.descripcion,
      activa: dataEdited.active
    }

    systemServices.postAgency(parameters, id)
      .then(() => {
        getAgencies()
      })
      .catch(() => console.log('error while posting'))
  }

  // Section: Handlers
  const handleOnChange = (event: any) => {
    setDataEdited((prev) => {
      return {
        ...prev,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? !prev[event.target.name as keyof ADataEditable]
            : event.target.value
      }
    })
  }

  const handleOnClick = (event: any) => {
    event.preventDefault()

    if (event.target.name === 'Editar') {
      setEditAgency(true)

      setButtonName('Guardar')
    }

    if (event.target.name === 'Guardar') {
      setPreview(false)
      postAgency()
    }

    if (event.target.name === 'cancel') {
      setPreview(false)
    }
  }

  // Section: TSX
  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content solver--content">
        <div className="coupon--title solver--title">
          <h3>Detalle de Agencia</h3>
          <button onClick={() => setPreview(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <form>{formFields()}</form>
          <div className="buttons--container">
            <button
              name={buttonName}
              onClick={(event) => handleOnClick(event)}
              className="button--form"
            >
              {buttonName}
            </button>
            <button
              name="cancel"
              onClick={(event) => handleOnClick(event)}
              className="button--form"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgencyEdition
