import React, { ReactNode } from 'react'
import { Table } from 'react-bootstrap'

export type Column<T> = {
  header: string
  accessor: keyof T
  center: boolean
  render?: (value: any, row: T) => ReactNode
}

type TablaProps<T> = {
  columns: Column<T>[]
  data: T[]
}

const SimpleTable = <T,>({ columns, data }: TablaProps<T>) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index} className={col.center ? 'text-center' : ''}>
              {col.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td
                key={colIndex}
                className={`${col.center ? 'text-center' : ''} align-middle`}
              >
                {col.render
                  ? col.render(row[col.accessor], row)
                  : (row[col.accessor] as ReactNode)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SimpleTable
