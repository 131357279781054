// icons imports
import { BiSolidRightArrow } from 'react-icons/bi'
import { PiCircleDashedBold } from 'react-icons/pi'

// react imports
import { useEffect, useState } from 'react'

// libs imports
import fetchData from '../../libs/clients/fetchData'
import { STATUS } from '../../utils/constants'

// ====================================================================== || ITEM LIST || ====================================================================================== //

const ItemList = ({
  listElements,
  setItemsList,
  setSelected,
  setInfoModal,
  setErrorModal
}) => {
  const { description, itemSelected, id, name } = listElements
  const getLocalReports = JSON.parse(localStorage.getItem(name))
  // It holds the reports data fetched
  const [reports, setReports] = useState(getLocalReports || null)

  const [status, setStatus] = useState(STATUS.initial)

  useEffect(() => {
    if (itemSelected && reports === null) getReport(id)
  }, [itemSelected])

  // It sets an object that contains the data to feature the corresponding form.
  const handleItemListOnClick = (itemId) => {
    const selected = {
      id: itemId,
      reports: reports,
      key: reports.find((element) => element.id === itemId).key
    }

    setSelected(selected)
  }
  // This state builds each of the items of the current report item list.
  const [options, setOptions] = useState(null)

  useEffect(() => {
    setStatus(STATUS.success)
    if (reports !== null) {
      // It creates an array of list elements, to be feature once the description clicked.
      const buildingOptions = reports.map((element) => {
        return (
          <li
            key={element.id}
            onClick={() => handleItemListOnClick(element.id)}
            className="collapse--list--item"
          >
            {element.titulo}
          </li>
        )
      })

      setOptions(buildingOptions)
    }
  }, [reports])

  // It displays the items inside of the description item in the side bar
  const handleArrowOnClick = () => {
    setItemsList((prev) => {
      const newValue = prev.map((element) => {
        if (element.id === id) {
          return { ...element, itemSelected: !element.itemSelected }
        }
        return element
      })
      return newValue
    })
  }

  // It sets the itemSelecte to false, it is a behavior inherited from the original page, can be modify.
  const handleDescriptionOnClick = () => {
    setSelected(false)
  }

  const getReport = async (idGroup) => {
    const url = process.env.REACT_APP_WS_REPORTES_DEL_GRUPO.replace(
      ':idGrupo',
      idGroup
    )

    setStatus(STATUS.loading)
    try {
      const response = await fetchData(url)
      setReports(response.reportes)
      localStorage.setItem(name, JSON.stringify(response.reportes))
    } catch (error) {
      setStatus(STATUS.initial)

      if (error.code === process.env.REACT_APP_CODIGO_CERRO_SESION) {
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
      } else {
        setErrorModal({ hadError: true, errorMessage: error.message })
      }
    }
  }

  return (
    <ul className="sideNavbar--item">
      <div>
        {status === STATUS.loading ? (
          <PiCircleDashedBold className="icon--spin--small" />
        ) : (
          <BiSolidRightArrow
            onClick={() => handleArrowOnClick()}
            className={
              itemSelected ? 'arrow--rotate' : 'arrow--initial--position'
            }
          />
        )}
        <div onClick={() => handleDescriptionOnClick()}>{description}</div>
      </div>
      {itemSelected && options}
    </ul>
  )
}

export default ItemList
