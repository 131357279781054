import GForm from "components/forms/generic/GForm"
import React, { useState } from "react"
import { FaOctopusDeploy, FaSearch } from "react-icons/fa"
import systemServices from "services/system/systemServices"
import { STATUS } from "utils/constants"

const SForm = (props: any) => {
  const { 
    setItemData,
    setErrorModal,
    setFormFields,
    setAddOperatorModal,
    org
  } = props


  // Section: States
  const [status, setStatus] = useState(STATUS.initial)
  const [statusNewOperator] =  useState<any>(null)
  
  const handleOnClick = async (formData: any) => {
    const page: number = 0;
    setStatus(STATUS.loading)
    systemServices.getOperatorsList(formData, page)
    .then((response) => {
      setStatus(STATUS.success)
      setItemData(response)
    }).catch((error) => {
      setErrorModal({ hadError: true, errorMessage: error.message })
      setStatus(STATUS.error)
    })
  }

  // Section: Building Options
  const optionsBuilder = (element: any) => {
    const data = element.map((element: any, index: any) => {
      return (
        <option key={index} value={element.codigo}>
          {element.descripcion}
        </option>
      )
    })
    return data
  }

  const handleNewOperatorButton = (id: any) => {
    setAddOperatorModal(true)
  }

  // TSX
  return (
    <GForm
        setFormFields={setFormFields}
      >
        <GForm.SelectInput 
          label="Organización"
          formName="organization"
          options={optionsBuilder(org)}
        />
        <GForm.TextInput 
          label="Usuario"
          formName='user'
        />
        <GForm.TextInput 
          label="Nombre"
          formName='name'
        />
        <GForm.TextInput 
          label="Apellido"
          formName='lastName'
        />
        <GForm.Button
          title="Buscar"
          status={status}
          handleOnClick={handleOnClick}
          disabled={status === STATUS.loading}
        >
          <FaSearch />
        </GForm.Button>
        <GForm.Button
          title="Alta operador"
          status={statusNewOperator}
          handleOnClick={handleNewOperatorButton}
          disabled={statusNewOperator === STATUS.loading}
        >
          <FaOctopusDeploy className="trigger--icon" />
        </GForm.Button>
      </GForm>
  )
}

export default SForm