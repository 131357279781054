const getFormattedDate = (date) => {
  if (!date) return ''

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  const dateObj = new Date(date)

  return dateObj.toLocaleDateString('en-US', options)
}

export default getFormattedDate
