import React from 'react'
import { Outlet } from 'react-router-dom'

/* This component is the enter point which is in charge to handle all about client section.
 * What's missing?
    1. Including the corresponding sass to this component.
 */
const Clients = () => {
  return (
    <div className="clients--container forms--template">
      <h1>Panel Clientes</h1>
      <Outlet />
    </div>
  )
}

export default Clients
