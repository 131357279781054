// components imports
import TablePersonalData from '../tables/clients/TablePersonalData'
import FormPersonalData from '../forms/clients/FormPersonalData'

// third party imports
import { useOutletContext } from 'react-router-dom'

// ====================================================================== || PERSONAL DATA || ====================================================================================== //

const PersonalData = ({ setIsLogged }) => {
  const [data, setData] = useOutletContext()

  return (
    <div className="data--result--container">
      <FormPersonalData
        data={data}
        setData={setData}
        setIsLogged={setIsLogged}
      />
      <TablePersonalData data={data.dataBilletera} />
    </div>
  )
}

export default PersonalData
