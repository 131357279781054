// react imports
import { useState, useEffect } from 'react'

// libs imports
import fetchData from '../../../libs/clients/fetchData'

// components imports
import TriggerButton from '../../shared/TriggerButton'

// constants imports
import { STATUS } from '../../../utils/constants'

// ====================================================================== || FORM CLIENT RESULTS || ====================================================================================== //

// Refactoring this component to reuse it in others pages.
// It receives as props walletId and setFormData that is in charge to change the fromData value.
const FormClientResults = ({
  formFields,
  data,
  setFormData,
  setPage,
  extraButtons,
  storageName,
  setErrorModal,
  requestPath
}) => {
  const [status, setStatus] = useState(STATUS.initial)

  // // This hook stores the form data.
  const [dataHolder, setDataHolder] = useState(data.dataForm)
  const [dataFetched, setDataFetched] = useState(false)

  const handleOnChange = (event) => {
    setDataHolder((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value
      }
    })
  }

  // When the search button is clicked this function fetch the data with the info gotten from the form always fetch the first page of the table.
  const handleOnClick = async (event) => {
    event.preventDefault()

    localStorage.removeItem(storageName)

    const url = requestPath(dataHolder)

    // setCommonUrl({ parseUrl: parseUrl, urlDateReplaced: urlDateReplaced, urlType: urlType });
    setStatus(STATUS.loading)
    try {
      const holder = await fetchData(url)
      setDataFetched(holder)
      setStatus(STATUS.success)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
      setStatus(STATUS.error)
    }
  }

  useEffect(() => {
    if (dataFetched) {
      // Its format is a object that contains the data fetched and the form info to keep fetching other pages of the table.
      const dataPackage = {
        dataForm: dataHolder,
        dataFetched: dataFetched
      }
      setFormData(dataPackage)
      setPage(0)
      localStorage.setItem(storageName, JSON.stringify(dataPackage))
    }
  }, [dataFetched])

  const fields = formFields.map((field, index) => {
    if (field.type === 'select') {
      return (
        <div key={index} className="field--form--wallet">
          <label className="form--label">{field.label}: </label>
          <select
            className="form--input"
            name={field.name}
            value={dataHolder.type}
            onChange={(event) => handleOnChange(event)}
          >
            <option value="">Seleccione Opción</option>
            {field.options}
          </select>
        </div>
      )
    }

    return (
      <div key={index} className="field--form--wallet">
        <label className="form--label">{field.label}: </label>
        <input
          max={dataHolder.toDate}
          name={field.name}
          className="form--input"
          type={field.type}
          value={dataHolder[field.name]}
          onChange={(event) => handleOnChange(event)}
        />
      </div>
    )
  })

  return (
    <div className="container--form--table--wallet">
      <form>
        <div className="form--row">
          {fields}
          {extraButtons}
          <TriggerButton status={status} handleOnClick={handleOnClick} />
        </div>
      </form>
    </div>
  )
}

export default FormClientResults
