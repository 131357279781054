import { useRouteError } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import ModalWarning from '../modals/ModalWarning'

const ErrorHandler = ({ setIsLogged, errorMessage }) => {
  const navigate = useNavigate()
  const error = useRouteError()
  const message = error || errorMessage
  const handleOnClick = () => {
    setIsLogged(false)
    navigate('/login')
  }

  const [triggerPreview, setTriggerPreview] = useState(true)
  return (
    <ModalWarning
      preview={triggerPreview}
      setPreview={setTriggerPreview}
      title="Ups!"
    >
      <div className="error--container">
        <h3>Oops!</h3>
        <p className="error--message">{message}</p>
        <div className="warning--button">
          <button
            className="button--form--warning"
            onClick={() => handleOnClick()}
          >
            {' '}
            Ingresar de nuevo
          </button>
        </div>
      </div>
    </ModalWarning>
  )
}

export default ErrorHandler
