// local libs imports
import useTable from '../../../hooks/useTable'

// local components imports
import TableFooter from '../table-footer/TableFooter'

// This component is in charge of render the table with its content. It receives the walletId and data: {dataFetched, dataForm}
const TableWallet = ({
  data,
  setFormData,
  page,
  setPage,
  tableHeaders,
  tableContent,
  commonUrl,
  setErrorModal
}) => {
  // This custom hook is in charge of defines the slice of data to display, the range of the info and the total records int the table
  const { slice, range, totalRecords } = useTable(
    data.dataFetched.items,
    data.dataFetched.totalPages,
    data.dataFetched.totalItems
  )

  const header = tableHeaders.map((title, index) => {
    return <th key={index}> {title} </th>
  })

  return (
    <>
      <div className="table--wallet">
        <table className="table--style">
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      </div>
      <TableFooter
        range={range}
        slice={slice}
        page={page}
        records={totalRecords}
        setData={setFormData}
        setPage={setPage}
        commonUrl={commonUrl}
        setErrorModal={setErrorModal}
      />
    </>
  )
}

export default TableWallet
