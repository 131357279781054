import React, { useEffect, useState } from 'react'
import CreateBonusForm from 'components/forms/bonus/create-bonus-form/CreateBonusForm'
import SimpleTable, { Column } from 'components/tables/simple-table/SimpleTable'
import { useAppContext } from 'context/AppContext'
import ModalCustom from 'modals/modal-custom/ModalCustom'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import BonusServices from 'services/BonusServices'
import { FaCircle } from 'react-icons/fa'
import { Bonus, Organization } from 'types/Bonus.types'
import { PiCircleDashedBold } from 'react-icons/pi'
import { BsEyeFill } from 'react-icons/bs'
import { BsFillXCircleFill } from 'react-icons/bs'
import { dateddmmyyyy } from 'utils/formatDate'
import { BonusDetails } from './BonusDetails'
import { STATUS } from 'utils/constants'
import ErrorHandler from 'utils/ErrorHandler'

type RowData = {
  id: string
  nombre: string
  tipo: string
  fechaInicio: string
  fechaFin: string
  activo: boolean
  estado: string
}

export const BonusManager = () => {
  const { openModal, setOpenModal, setIsLogged } = useAppContext()
  const bonusServices = new BonusServices()
  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const [selectedOrg, setSelectedOrg] = useState<number | undefined>()
  const [bonusList, setBonusList] = useState<Bonus[]>([])
  const [loading, setLoading] = useState(true)
  const [openModalDetails, setOpenModalDetails] = useState(false)
  const [bonusSelected, setBonusSelected] = useState('')
  const [userExist, setUserExist] = useState({
    status: STATUS.initial,
    message: 'Error de conexión'
  })

  useEffect(() => {
    getOrganizations()
  }, [])

  useEffect(() => {
    selectedOrg && getBonusList()
  }, [selectedOrg])

  const getOrganizations = () => {
    setLoading(true)
    bonusServices
      .getOrganizations()
      .then((response) => {
        setOrganizationList(response.organizaciones)
        setSelectedOrg(response.organizaciones[0].codigo)
      })
      .catch((err) => {
        handleError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getBonusList = () => {
    if (selectedOrg !== undefined) {
      setLoading(true)
      bonusServices
        .getBonus(selectedOrg)
        .then((response) => {
          setBonusList(response)
        })
        .catch((err) => {
          setBonusList([])
          handleError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleCreate = () => {
    setOpenModal(true)
  }

  const handleDisable = (id: string) => {
    setLoading(true)
    bonusServices
      .updateBonusStatus(id)
      .then(() => {
        getBonusList()
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const handleError = (error: any) => {
    const sessionExpired =
      error.errores[0].codigo ===
      parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION || '0')

    if (sessionExpired) {
      setUserExist((prev) => ({
        status: STATUS.expired,
        message: error.errores[0].error || prev.message
      }))
    } else {
      setUserExist((prev) => ({
        status: STATUS.error,
        message: error.errores[0].error || prev.message
      }))
    }
  }

  const dataMap = () => {
    return bonusList.map((bonus) => ({
      id: bonus.id,
      nombre: bonus.nombre,
      tipo: bonus.tipoBonificacion.nombre,
      fechaInicio: dateddmmyyyy(bonus.fechaInicio),
      fechaFin: dateddmmyyyy(bonus.fechaFin),
      activo: bonus.activo,
      estado: bonus.activo ? 'Activado' : 'Desactivado'
    }))
  }

  const columns: Column<RowData>[] = [
    { header: 'Nombre', accessor: 'nombre', center: false },
    { header: 'Tipo', accessor: 'tipo', center: false },
    { header: 'Fecha Inicio', accessor: 'fechaInicio', center: true },
    { header: 'Fecha Fin', accessor: 'fechaFin', center: true },
    {
      header: 'Estado',
      accessor: 'estado',
      center: false,
      render: (_: any, row: RowData) => (
        <div>
          {row.activo ? (
            <>
              <FaCircle
                style={{ fontSize: '1rem', color: 'green', marginRight: '5px' }}
              />
              <label>Habilitado</label>
            </>
          ) : (
            <>
              <FaCircle
                style={{ fontSize: '1rem', color: 'red', marginRight: '5px' }}
              />
              <label>Deshabilitado</label>
            </>
          )}
        </div>
      )
    },
    {
      header: 'Acciones',
      accessor: 'acciones' as keyof RowData,
      center: true,
      render: (_: any, row: RowData) => (
        <div className="d-flex justify-content-around align-items-center">
          <OverlayTrigger
            overlay={row.activo ? <Tooltip>Deshabilitar</Tooltip> : <></>}
          >
            <span
              className={!row.activo ? 'text-muted' : ''}
              onClick={() => (row.activo ? handleDisable(row.id) : null)}
            >
              <BsFillXCircleFill
                size={24}
                style={{
                  cursor: !row.activo ? 'default' : 'pointer',
                  color: row.activo ? '#C95656' : 'grey'
                }}
              />
            </span>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip>Detalles</Tooltip>}>
            <span onClick={() => handleDetail(row.id)}>
              <BsEyeFill
                size={28}
                style={{
                  cursor: 'pointer',
                  color: '#2C69E2'
                }}
              />
            </span>
          </OverlayTrigger>
        </div>
      )
    }
  ]

  const handleDetail = (idBonus: string) => {
    setBonusSelected(idBonus)
    setOpenModalDetails(true)
  }

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 'calc(100vh - 90px)' }}
    >
      <PiCircleDashedBold
        className="icon--spin"
        style={{ fontSize: '100px' }}
      />
    </div>
  ) : (
    <div className="forms--template">
      <div>
        <h1>Bonificaciones</h1>
      </div>
      <div
        className="d-flex mb-4"
        style={{ justifyContent: 'space-between', padding: '0px 2.25rem' }}
      >
        <select
          className="form-select"
          style={{ width: '15.625rem' }}
          value={selectedOrg}
          onChange={(e) => setSelectedOrg(Number(e.target.value))}
        >
          {organizationList.map((org) => (
            <option key={org.codigo} value={org.codigo?.toString()}>
              {org.descripcion}
            </option>
          ))}
        </select>
        <Button
          style={{ marginLeft: '10px' }}
          variant="success"
          onClick={handleCreate}
        >
          Crear bonificación
        </Button>
      </div>
      {bonusList.length === 0 ? (
        <div
          className="d-flex justify-content-center"
          style={{ padding: '0px 2.25rem' }}
        >
          <label style={{ fontSize: '1.5rem' }}>
            No hay bonificaciones para esta organización o esta deshabilitada.
          </label>
        </div>
      ) : (
        <div style={{ padding: '0px 2.25rem' }}>
          <SimpleTable<RowData> columns={columns} data={dataMap()} />
        </div>
      )}

      <ModalCustom
        title="Crear bonificación"
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      >
        <CreateBonusForm
          closeModal={() => setOpenModal(false)}
          getBonusList={getBonusList}
          //La organización elegida es la que se usa para crear una nueva bonificación
          organizationCode={selectedOrg}
          handleError={handleError}
        />
      </ModalCustom>

      <ModalCustom
        title="Detalles de Bonificación"
        openModal={openModalDetails}
        onClose={() => setOpenModalDetails(false)}
      >
        <BonusDetails bonusId={bonusSelected} handleError={handleError} />
      </ModalCustom>

      {userExist.status === STATUS.expired && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={userExist.message}
        />
      )}
    </div>
  )
}
