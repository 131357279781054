// react imports
import { useState } from 'react'
// third party libraries
import { AnimatePresence } from 'framer-motion'
// libs imports
import getCustomFormFields from '../../../utils/getCustomFormFields'
import { rolesFields, newRolesFields } from '../../../utils/operatorUtils'
// local components imports
import ModalLayout from '../../../modals/ModalLayout'
import SystemRolesModalContent from '../SystemRolesModalContent'
// icons imports
import { MdCancel } from 'react-icons/md'

const ModalVisualizer = ({
  triggerPreview,
  triggerNewRole,
  dataEdited,
  roleData,
  setRoleData,
  setTriggerPreview,
  setTriggerNewRole,
  setDataEdited
}) => {
  // content edition state
  const [editOperator, setEditOperator] = useState(false)
  const [editNewRole, setEditNewRole] = useState(true)
  const [newRoleData, setNewRoleData] = useState({ privilegios: [] })
  const [dataNewRole, setNewRole] = useState({
    nombre: ' ',
    descripcion: ''
  })

  const formItems = rolesFields(false)
  const formNewRoleItems = newRolesFields()

  // building form fields for both new role and view modal
  const formFields = getCustomFormFields(
    formItems,
    roleData,
    setDataEdited,
    dataEdited,
    editOperator
  )

  const handleDeleteOnClick = (event, element) => {
    event.preventDefault()

    setRoleData((prev) => {
      return {
        ...prev,
        privilegios: prev.privilegios.filter((item) => item.id !== element.id)
      }
    })
  }

  const deleteButton = (element) =>
    editOperator && (
      <div>
        <button
          onClick={(event) => handleDeleteOnClick(event, element)}
          title="Eliminar"
          className="button--preview flex--button"
        >
          <MdCancel className="preview--icon" />
        </button>
      </div>
    )

  const formFieldsNew = getCustomFormFields(
    formNewRoleItems,
    newRoleData,
    setNewRole,
    dataNewRole,
    editNewRole
  )
  const infoRoles = (data) => {
    return data.privilegios.map((privilege, index) => {
      return (
        <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
          <td className="item--start"> {privilege.nombre} </td>
          <td className="item--start">{privilege.id}</td>
          <td>{deleteButton(privilege)}</td>
        </tr>
      )
    })
  }

  return (
    <AnimatePresence>
      {triggerPreview && (
        <ModalLayout
          preview={triggerPreview}
          setPreview={setTriggerPreview}
          title="Detalles Role"
        >
          <SystemRolesModalContent
            editOperator={editOperator}
            formFields={formFields}
            infoRoles={infoRoles(roleData)}
            isAdditionAction={false}
            setRoleData={setRoleData}
            setEditOperator={setEditOperator}
            setTriggerPreview={setTriggerPreview}
          />
        </ModalLayout>
      )}
      {triggerNewRole && (
        <ModalLayout
          preview={triggerNewRole}
          setPreview={setTriggerNewRole}
          title="Nuevo Role"
        >
          <SystemRolesModalContent
            isAdditionAction={true}
            editOperator={editNewRole}
            formFields={formFieldsNew}
            infoRoles={infoRoles(newRoleData)}
            setRoleData={setNewRoleData}
            setEditOperator={setEditNewRole}
            setTriggerPreview={setTriggerNewRole}
          />
        </ModalLayout>
      )}
    </AnimatePresence>
  )
}

export default ModalVisualizer
