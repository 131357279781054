import { nanoid } from 'nanoid'

const getFormFields = (currentArguments, organizations) => {
  const aux = currentArguments.map((element) => {
    return {
      preFixed: element.defecto,
      description: element.descripcion,
      required: element.obligatorio,
      order: element.orden,
      dataType:
        element.tipo === 'java.math.BigDecimal' ||
        element.tipo === 'java.lang.Integer'
          ? 'text'
          : 'date',
      sqlType: element.tipoSql,
      value: element.defecto,
      name: element.nombre,
      id: nanoid()
    }
  })

  return [
    {
      preFixed: '',
      description: 'Organización',
      required: true,
      order: '',
      dataType: 'select',
      sqlType: '',
      value: '',
      options: [...organizations],
      name: 'organization',
      id: nanoid()
    },
    ...aux,
    {
      preFixed: '',
      description: 'Salida',
      required: true,
      order: '',
      dataType: 'select',
      sqlType: '',
      value: '',
      options: [
        { codigo: 'pdf', descripcion: 'PDF' },
        { codigo: 'xls', descripcion: 'EXCEL' }
      ],
      name: 'output',
      id: nanoid()
    }
  ]
}

export default getFormFields
