// icon imports
import { FaCheckCircle } from 'react-icons/fa'

// libs imports
import getReverseDate from '../../../libs/shared/getReverseDate'
import getCurrentDate from '../../../libs/shared/getCurrentDate'

// ====================================================================== || FORM FIELD || ====================================================================================== //

// FormField is a regular combination between label and input to feature a field in the form.
// It receives the data to load the inputs requirements
const FormField = ({ data, setDataForm }) => {
  const handleOnChange = (event) => {
    setDataForm((prev) => {
      const nwValues = prev.map((element) => {
        if (element.name === event.target.name) {
          return {
            ...element,
            value: event.target.value
          }
        }
        return element
      })
      return nwValues
    })
  }

  const parseValue = () => {
    let value = data.value

    if (data.dataType === 'date') {
      value = getReverseDate(data.value, '-')
    }

    return value
  }

  return (
    <div className="form--level">
      <label className="form--label">
        {' '}
        {data.description} {data.required ? <span> *</span> : ''}{' '}
      </label>
      <div className="form--input--container">
        <input
          max={data.dataType === 'date' ? getCurrentDate() : null}
          onChange={(event) => handleOnChange(event)}
          className="form--input"
          name={data.name}
          value={parseValue()}
          type={data.dataType}
        />
        <span className={data.value ? 'form--checked' : 'form--not--checked '}>
          {' '}
          <FaCheckCircle />{' '}
        </span>
      </div>
    </div>
  )
}
export default FormField
