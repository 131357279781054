import { useEffect, useRef, useState } from 'react'
import { useAnimate, usePresence } from 'framer-motion'

import postSolveUfi from '../libs/clients/postSolveUfi'

import { AiFillCloseCircle } from 'react-icons/ai'

const SolverModal = ({ solverModal, setSolverModal, data }) => {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  const reference = useRef()

  const [dataFrom, setDataForm] = useState({
    voucher: ''
  })

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        solverModal &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setSolverModal(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [solverModal])

  const handleOnChange = (event) => {
    setDataForm((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleCancelEvent = () => {
    setSolverModal(false)
  }

  const handleOnClickEvent = () => {
    postSolveUfi(data.id, data.date, dataFrom.voucher)
    console.log(`${data.id} ${data.date} ${dataFrom.voucher}`)
    setSolverModal(false)
  }

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content solver--content" ref={reference}>
        <div className="coupon--title solver--title">
          <h3>Resolver UIF</h3>
          <button onClick={() => setSolverModal(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">
          <form>
            <div className="field--form--wallet form--row">
              <label className="form--label"> Usuario id: </label>
              <input
                className="form--input--solver"
                defaultValue={data.id}
                type="text"
              />
            </div>
            <div className="field--form--wallet form--row">
              <label className="form--label"> Fecha: </label>
              <input
                className="form--input--solver"
                defaultValue={data.date}
                type="text"
              />
            </div>
            <div className="field--form--textarea form--row">
              <label className="form--label"> Comprobante: </label>
              <textarea
                name="voucher"
                value={dataFrom.voucher}
                onChange={(event) => handleOnChange(event)}
                className="form--textarea"
                rows="4"
                cols="100"
                form="usrform"
              ></textarea>
            </div>
          </form>
          <div className="solver--button--container">
            <button
              onClick={() => handleOnClickEvent()}
              className="button--form"
            >
              Resolver
            </button>
            <button
              onClick={() => handleCancelEvent()}
              className="button--form"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolverModal
