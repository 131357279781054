// It reverses the any date and replace the slash token in it with the token passed by parameter.
const getReverseDate = (data, token) => {
  if (data) {
    const element = data.split('/').reverse().join('/')
    const value = element.replaceAll('/', token)

    return value
  }
  return ''
}

export default getReverseDate
