// local components imports
import ModalLayout from './ModalLayout'

// icons imports
import { FaInfoCircle } from 'react-icons/fa'

// ====================================================================== || MODAL INFORMATION || ====================================================================================== //

const Information = ({ setInfoModal, infoModal }) => {
  return (
    <ModalLayout
      setPreview={setInfoModal}
      preview={infoModal}
      noScroll={true}
      info={true}
    >
      <FaInfoCircle className="info--icon" />
      <p>{infoModal.errorMessage}</p>
      <div className="buttons--container">
        <button className="button--form" onClick={() => setInfoModal(false)}>
          OK
        </button>
      </div>
    </ModalLayout>
  )
}

export default Information
