type FormItem = {
  name: string,
  item: string,
  isEditable: boolean
}

export const formItems: FormItem[] = [
  {
    name: 'Codigo',
    item: 'codigo',
    isEditable: false
  },
  {
    name: 'Descripción',
    item: 'descripcion',
    isEditable: true
  },
  {
    name: 'Dirección',
    item: 'direccion',
    isEditable: false
  },
  {
    name: 'Localidad',
    item: 'localidad',
    isEditable: false
  },
  {
    name: 'Sub Agencia',
    item: 'subAgencia',
    isEditable: false
  },
  {
    name: 'Activa',
    item: 'active',
    isEditable: true
  },
  {
    name: 'Habilitada',
    item: 'habilitada',
    isEditable: false
  }
]
