// http://billetera.tecnoaccion.com.ar:8704/api-operador/admin/billetera/a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb/movimientos?desde=20230702&hasta=20230811&page=0

// http://testnqn.tecnoaccion.com.ar:8704/api-operador/admin/billetera/:idBilletera/movimientos
// http://testnqn.tecnoaccion.com.ar:8704/api-operador/admin/billetera/tipos-movimientos

/*
const Required data = {
    idAdministrator,
    FromDate,
    ToDate,
    PageNumber
}
*/

/* These are the parameters needed to fetch this data from:
Depending on the type of movement selected, fetch the data from two different ENDPOINTS.
    1. REACT_APP_WS_TIPOS_MOV_BILLETERA -> If the user selected a type of movement different to 'TODOS'.
    2. REACT_APP_WS_CONS_BILLETERA -> If the user selected a 'TODOS' movement.
*/
const getMovements = (pageNumber, walletId, fromDate, toDate, movementType) => {
  return {
    status: 'ok',
    errores: [],
    billeteraId: 'a4f5cc9f-0f13-4bdd-b73c-2a93da867ddb',
    saldoAnterior: 16550210.74,
    saldo: 1909169.81,
    saldoRetirable: 1906563.81,
    totalItems: 2305,
    totalPages: 47,
    currentPage: 0,
    items: [
      {
        id: '5fa71b77-2e69-4b4c-a0fc-3a3eecedca49',
        fecha: '2023-07-31T13:54:02.737+00:00',
        importe: 70.0,
        concepto: 'Premio jugada - Quiniela Instantánea',
        tipoMovimiento: `Obtuvo premios ${pageNumber}`
      },
      {
        id: 'c58c937f-7aba-4760-ab4e-8165b7147a9e',
        fecha: '2023-07-31T13:54:02.732+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'ed499cf9-8305-4e53-a426-ffbaeb074e7d',
        fecha: '2023-07-31T13:15:06.362+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '0b05ec41-2182-49a9-be57-bfe80579fd04',
        fecha: '2023-07-31T13:15:00.408+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'd2051665-6a1f-4ecc-aa2e-e26152c5b627',
        fecha: '2023-07-31T13:14:54.728+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '673adcb5-d3e2-4687-b8bb-85c39ad4466e',
        fecha: '2023-07-27T17:22:23.468+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'e6ffe09a-3097-4365-b5f8-8831543f1ebe',
        fecha: '2023-07-27T17:22:14.699+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '7a1e1a34-4095-4c29-a1f2-a957fe098d60',
        fecha: '2023-07-27T17:22:08.707+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '9d54db08-ed09-4a56-8f90-4181e9721049',
        fecha: '2023-07-27T15:38:57.733+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '512c272f-34df-45a2-9653-60b9a9fb939b',
        fecha: '2023-07-27T15:38:49.899+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'fe870053-7a33-41bd-9f4b-63f91ca3b8ba',
        fecha: '2023-07-27T14:42:52.214+00:00',
        importe: 70.0,
        concepto: 'Premio jugada - Quiniela Instantánea',
        tipoMovimiento: `Obtuvo premios ${pageNumber}`
      },
      {
        id: '12b95072-4e40-4924-b1e3-75efb9dc004c',
        fecha: '2023-07-27T14:42:52.210+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '3fd3836b-8da2-4da1-8c3b-03e94954e886',
        fecha: '2023-07-27T14:34:16.122+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'f542794b-6eaf-40e5-bf28-1a401f4a49f2',
        fecha: '2023-07-27T14:34:08.726+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'd6d7b37a-b0da-4854-8cdd-b122306ace68',
        fecha: '2023-07-27T14:34:02.624+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'e7649341-d017-4979-918c-716b73983257',
        fecha: '2023-07-27T14:27:39.912+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '4639cf1e-6556-4323-bf7d-b7931b665ee5',
        fecha: '2023-07-27T14:27:23.763+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '99b9d2f6-3dfc-4931-ae74-07a4ba5b9de5',
        fecha: '2023-07-27T14:27:17.679+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'a6f15272-e1ea-4f21-9219-9d234c25cfe0',
        fecha: '2023-07-27T13:49:02.342+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '109e1dba-d386-47ac-94a8-57c9dea69f00',
        fecha: '2023-07-27T13:48:58.814+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'dbe84681-d9e3-4c01-9d1c-69ee167417c6',
        fecha: '2023-07-27T13:48:52.400+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '5f559c0d-db32-4c70-a3b5-9d809c119d3e',
        fecha: '2023-07-27T13:48:47.249+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'b564af15-2b05-4cee-a110-1ea523b011b4',
        fecha: '2023-07-27T13:47:20.782+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'acaeceba-543c-4e2a-a6b6-3dbad00cb4c3',
        fecha: '2023-07-27T13:47:13.297+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'cc89b423-8dca-4e2c-b4b2-85fc458fd488',
        fecha: '2023-07-27T13:47:04.899+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '2504c84d-3a72-433a-a862-aa5349fc6f09',
        fecha: '2023-07-27T13:45:52.662+00:00',
        importe: -130.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '757d1e5a-1edb-4743-8861-f769c71ec3c5',
        fecha: '2023-07-27T13:45:44.727+00:00',
        importe: -130.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'b118f1d6-1013-4bd2-8403-ed7e36fdebf7',
        fecha: '2023-07-27T13:45:38.116+00:00',
        importe: -130.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'a5412042-00a9-437b-a5c5-b2bedd24f70a',
        fecha: '2023-07-27T13:45:27.774+00:00',
        importe: -130.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'ffedfab9-fe65-48d7-8ca1-b553c1629b7b',
        fecha: '2023-07-25T16:29:12.662+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '9f39c29e-383e-4731-9509-adf064050fa7',
        fecha: '2023-07-25T16:28:35.898+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '8f69905f-d528-4a56-ad86-3d26d9ca3b2a',
        fecha: '2023-07-25T16:28:28.940+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '01d47bc4-57f7-4819-b6ca-4b6fdf5c4626',
        fecha: '2023-07-25T16:28:24.838+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'f2996094-71ea-43ad-bb7c-b80f3e6fb09e',
        fecha: '2023-07-25T16:28:20.614+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '82a565ec-91ab-4560-8756-8b023817f6fb',
        fecha: '2023-07-25T16:28:12.469+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'b596da3a-c216-4b3a-a263-046a8677f1bf',
        fecha: '2023-07-25T16:27:53.686+00:00',
        importe: -20.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '84b1d5aa-f365-4a07-a2b0-510dfa3cf6a0',
        fecha: '2023-07-25T16:14:01.500+00:00',
        importe: -100.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '5288f827-0bf6-4e1f-9ce1-23f88c592008',
        fecha: '2023-07-25T16:13:25.681+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '9d7c09ae-a0f9-4769-810c-523bfda35ba3',
        fecha: '2023-07-25T16:13:18.987+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'c7dd1ead-6865-466e-82ca-0fea9d0efc91',
        fecha: '2023-07-25T16:13:08.356+00:00',
        importe: -10.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '11d20ac6-635f-4419-94fc-4ac59dc150c8',
        fecha: '2023-07-24T18:28:44.932+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '56697fee-7fcf-4c2a-89b8-d08d9e51a38f',
        fecha: '2023-07-24T18:28:38.988+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '701904f8-edcb-41e3-95f7-3ccadfb8600d',
        fecha: '2023-07-24T18:27:15.243+00:00',
        importe: 70.0,
        concepto: 'Premio jugada - Quiniela Instantánea',
        tipoMovimiento: `Obtuvo premios ${pageNumber}`
      },
      {
        id: 'a093099c-d0e3-440c-8ef6-97cbd332642c',
        fecha: '2023-07-24T18:27:15.239+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'efd7fdc1-9981-478f-aece-75d2ae45d035',
        fecha: '2023-07-24T18:26:40.074+00:00',
        importe: 70.0,
        concepto: 'Premio jugada - Quiniela Instantánea',
        tipoMovimiento: `Obtuvo premios ${pageNumber}`
      },
      {
        id: 'cd31a71e-946d-4d68-b273-5e8f561fd995',
        fecha: '2023-07-24T18:26:40.069+00:00',
        importe: -30.0,
        concepto: 'Jugada - Quiniela Instantánea',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'bb001191-28bb-4d2b-be69-b51190553226',
        fecha: '2023-07-17T14:46:51.103+00:00',
        importe: -2000.0,
        concepto: 'Jugada - Loteria Unificada',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: 'f932e372-ce37-48fe-8ae9-f016cbcaa3c9',
        fecha: '2023-07-17T14:46:51.103+00:00',
        importe: 2000.0,
        concepto:
          'Cupon bonificado para el evento: 29298 para el cupon: 375077277',
        tipoMovimiento: `Devolución promoción ${pageNumber}`
      },
      {
        id: '8b628179-7041-48c3-bf97-f9d530074d94',
        fecha: '2023-07-17T14:46:50.934+00:00',
        importe: -4000.0,
        concepto: 'Jugada - Loteria Unificada',
        tipoMovimiento: `Apuesta ${pageNumber}`
      },
      {
        id: '3ec93596-6d26-42d8-80d1-fc67abf82b94',
        fecha: '2023-07-17T14:46:50.774+00:00',
        importe: -7000.0,
        concepto: 'Jugada - Loteria Unificada',
        tipoMovimiento: `Apuesta ${pageNumber}`
      }
    ],
    agAmiga: '21_0'
  }
}

export default getMovements
