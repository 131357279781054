import logoNav from '../../assets/img/tiplotblanco.png'
import { Link } from 'react-router-dom'
import { FaUserCircle } from 'react-icons/fa'
import userItems from '../../utils/userItems'
import React from 'react'
import UserMenu from './UserMenu'
import { nanoid } from 'nanoid'

// Building the options that apears in the navBar after the user logged.
export default function NavBar({ isLogged, version, setIsLogged, name }) {
  const navUserItems = userItems.map((element) => {
    return (
      <li key={nanoid()}>
        <Link className="navbar--login" to={element.link}>
          {' '}
          {element.name}{' '}
        </Link>
      </li>
    )
  })

  return (
    <nav className="navbar">
      <ul className="navbar--items">
        <li>
          <Link to={isLogged ? '/home' : '/'} className="navbar--logo">
            <img src={logoNav} alt="logo" width="90px" />
            <span className="project--version"> Ver: {version}</span>
          </Link>
        </li>

        <div className="navbar--user--item">{isLogged && navUserItems}</div>

        <li>
          {isLogged ? (
            <UserMenu setIsLogged={setIsLogged} name={name} />
          ) : (
            <Link className="navbar--login" to="/login">
              <span>Ingresar</span>
              <FaUserCircle className="navbar--icon" />
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}
