import React, { createContext, useState, type ReactElement } from 'react'
import NavFooterBar from '../table-footer/NavFooterBar'
import GoToPage from '../table-footer/GoToPage'
import useTable from 'hooks/useTable'
import GNavFooterBar from '../table-footer/GNavFooterBar'
import GGoToPage from '../table-footer/GGoToPage'

type GTableContextProps = {
  value: any
  setValue: any
}

type GTableProps = {
  children: any
}

type HeaderProps = {
  columns: any
}

type BodyProps = {
  content: any
  columns: any
  Icon: any
  handleOnClick: any
}

const value: GTableContextProps = {
  value: 0,
  setValue: 0
}

type PaginatorProps = {
  data: any
  page: any
  setPage: any
}

const GTableContext = createContext(value)

function GTable(props: GTableProps): ReactElement {
  const [value, setValue] = useState<GTableContextProps>({
    value: 0,
    setValue: 0
  })

  return (
    <GTableContext.Provider value={{ value, setValue }}>
      <div className="table--wallet">
        <table className="table--style">
          {props.children[0]}
          {props.children[1]}
        </table>
        {props.children[2]}
      </div>
    </GTableContext.Provider>
  )
}

function Header(props: HeaderProps): ReactElement {
  return (
    <thead>
      <tr>
        {props.columns.map(({ id, label, align }: any) => (
          <th key={id}>{label}</th>
        ))}
      </tr>
    </thead>
  )
}

function Body(props: BodyProps): ReactElement {
  function renderingButton(data: any): any {
    return (
      <button
        className="button--preview"
        onClick={(event) => {
          event.preventDefault()
          props.handleOnClick(data.id)
        }}
      >
        <props.Icon className="preview--icon" />
      </button>
    )
  }

  function renderingCell(element: any, data: any): any {
    if (element.isCheckBox === true) {
      return (
        <input
          className="checkbox--table"
          type="checkbox"
          defaultChecked={data[element.name]}
          disabled
        />
      )
    } else if (element.isButton === true) {
      return renderingButton(data)
    } else {
      return data[element.name]
    }
  }

  return (
    <tbody>
      {props.content.map((row: any, index: number): any => (
        <tr key={row.id} className={index % 2 === 0 ? 'add--row--color' : ''}>
          {props.columns.map((column: any) => (
            <td className="item--center" key={column.id}>
              {renderingCell(column.key, row)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

function Paginator(props: PaginatorProps): any {
  const { data, page, setPage} = props
  const { range, totalRecords } = useTable(
    data.items,
    data.totalPages,
    data.totalItems
  )
  return (
    <div className="table--footer">
      <div className="footer--container">
        <GNavFooterBar
          page={page}
          setPage={setPage}
          range={range}
        />
        <GGoToPage
          range={range}
          page={page}
          setPage={setPage}
        />
        <div> {`(${totalRecords} registros)`} </div>
      </div>
    </div>
  )
}

GTable.Body = Body
GTable.Header = Header
GTable.Paginator = Paginator

export default GTable
