const getCurrentDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const fullDate = `${year}-${month}-${day}`

  return fullDate
}

export default getCurrentDate
