// react imports
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router'

// components imports
import FormClientResults from '../forms/clients/FormClientResults'
import TableWallet from '../tables/clients/TableWallet'
import ErrorHandler from '../../utils/ErrorHandler'

// libs imports
import getFormattedDate from '../../libs/shared/getFormattedDate'
import getCurrentDate from '../../libs/shared/getCurrentDate'
import { useDataClient } from '../../utils/custom-hooks/useDataClient'
import getAgenciesChanges from '../../libs/clients/getAgenciesChange'

// constants imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// ====================================================================== || AGENCIES CHANGE || ====================================================================================== //

const AgenciesChange = ({ setIsLogged }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.withDrawal.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const formStructure = {
    dataForm: '',
    fromDate: '',
    toDate: getCurrentDate()
  }
  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.agencies.form,
    LOCAL_STORAGE_CLIENTS.agencies.page,
    formStructure
  )

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    }
  ]
  const tableHeaders = [
    'Fecha Solicitud',
    'Agencia',
    'Pendiente',
    'Fecha Cambio'
  ]
  const [tableContent, setTableContent] = useState(0)

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((agency, index) => {
        return (
          <tr
            key={agency.id}
            className={index % 2 === 0 ? 'add--row--color' : ''}
          >
            <td>{getFormattedDate(agency.fechaSolicitud)}</td>
            <td className="item--center"> {agency.agencia} </td>
            <td className="item--center">
              {' '}
              <input
                type="checkbox"
                defaultChecked={agency.pendiente ? 'checked' : ''}
                disabled
              />{' '}
            </td>
            <td className="item--center">
              {' '}
              {getFormattedDate(agency.fechaCambio)}
            </td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const requestPath = (dataHolder) => {
    const walletId = data[0].billeteraId

    const parseUrl = process.env.REACT_APP_WS_CONS_CAMBIOS_AGENCIAS.replace(
      ':idBilletera',
      walletId
    )
    const urlDateReplaced = `?desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`
    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: 0
    }

    setCommonUrl(url)
    localStorage.setItem(
      LOCAL_STORAGE_CLIENTS.withDrawal.url,
      JSON.stringify(url)
    )

    return `${parseUrl}${urlDateReplaced}${0}`
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          storageName={LOCAL_STORAGE_CLIENTS.agencies.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para el rango de fechas ingresada.
          </p>
        )}
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </>
  )
}

export default AgenciesChange
