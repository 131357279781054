import { useState, useEffect } from 'react'

// This custom hook handle the table data.
const useTable = (data, totalPages, records) => {
  // This hooks store and update the data that is going to be displayed.
  const [tableRange, setTableRange] = useState([])
  const [slice, setSlice] = useState([])
  const [recordsAmount, setRecordsAmounts] = useState()

  // Checked if there are any changes as regards the data.
  useEffect(() => {
    setRecordsAmounts(records)
    setTableRange(totalPages)
    setSlice(data)
  }, [data, totalPages, records, setTableRange, setSlice, setRecordsAmounts])

  // It returns an object that has the whole information to use in the table.
  return { slice, range: tableRange, totalRecords: recordsAmount }
}

export default useTable
