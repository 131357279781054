const getOptionsNavBarSystem = [
  {
    name: 'Agencias',
    path: './',
    clicked: true
  },
  {
    name: 'Operadores',
    path: 'operadores',
    clicked: false
  },
  {
    name: 'Roles',
    path: 'roles',
    clicked: false
  }
]

export default getOptionsNavBarSystem
