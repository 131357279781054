// react imports
import { useState, useEffect } from 'react'

// libs imports
import getDynamicReports from '../../../libs/custom-reports/getDynamicReports'
import getFormFields from '../../../libs/custom-reports/getFormsFields'

// icons imports
import { BiSolidErrorCircle } from 'react-icons/bi'
import { PiCircleDashedBold } from 'react-icons/pi'

// components imports
import FormSelectionField from './FormSelectionField'
import LoadingSuccess from '../../shared/LoadingSuccess'
import FormField from './FormField'
import fetchData from '../../../libs/clients/fetchData'
import fetchDataReports from '../../../libs/clients/fetchDataReports'
import { showPDF, showXLS } from '../../../libs/clients/generateReportDoc'
import { convertDateFormat } from '../../../utils/utils'

// constants imports
import { STATUS } from '../../../utils/constants'

// constants
const FORM_NOT_LOADED_STATE = 2
const FORM_INITIAL_STATE = 0
const FORM_LOADED_STATE = 1

// ====================================================================== || FORM CUSTOM REPORTS || ====================================================================================== //

const FormCustomReports = ({
  itemSelected,
  setItemSelected,
  organizations,
  setInfoModal,
  setErrorModal
}) => {
  // it handle the status of the component
  const [status, setStatus] = useState(STATUS.initial)

  // The itemSelected has the required data to build the form.
  const gettingArguments = itemSelected.reports.find(
    (element) => element.id === itemSelected.id
  )
  const currentArguments = gettingArguments.argumentos

  // It checks if the form is populated
  const [isFormPopulated, setIsFormPopulated] = useState(FORM_INITIAL_STATE)

  // It store the data loaded by the user.
  const [dataForm, setDataForm] = useState(
    getFormFields(currentArguments, organizations)
  )

  useEffect(() => {
    setIsFormPopulated(FORM_INITIAL_STATE)
  }, [itemSelected])

  useEffect(() => {
    setDataForm(getFormFields(currentArguments, organizations))
  }, [currentArguments])

  const getCustomReports = async (data) => {
    const org = data.find((item) => item.name === 'organization').value
    const output = data.find((item) => item.name === 'output').value

    let url = process.env.REACT_APP_WS_REPORTES_DINAMICOS.replace(
      ':key',
      itemSelected.key
    )
      .replace(':codOrganizacion', org)
      .replace(':out', output)

    const top = data.length - 1
    let parameters = ''

    for (let i = 1; i < top; i++) {
      if (data[i].dataType === 'date')
        data[i].value = convertDateFormat(data[i].value)
      parameters =
        parameters + `&${data[i].name}=${encodeURIComponent(data[i].value)}`
    }

    url = url + parameters
    setStatus(STATUS.loading)
    try {
      const response = await fetchDataReports(url)

      const blob = await response.blob()
      setStatus(STATUS.success)
      const fileName = `reporte-a-medida-${itemSelected.key}`.toUpperCase()
      if (output === 'pdf') showPDF(blob, fileName)
      else showXLS(blob, fileName)

      setIsFormPopulated(FORM_LOADED_STATE)
    } catch (error) {
      setStatus(STATUS.initial)

      if (
        error.code === process.env.REACT_APP_CODIGO_CERRO_SESION ||
        error.code === 500
      ) {
        setErrorModal({ hadError: true, errorMessage: error.message })
      } else {
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
      }
    }
  }

  // It verify that the form has been loaded and construct the link to post the form data.
  const handleOnClick = (event) => {
    event.preventDefault()

    const isFilled = dataForm.find((element) => element.value === '')

    if (!isFilled) getCustomReports(dataForm)
    else setIsFormPopulated(FORM_NOT_LOADED_STATE)

    // It is missing the post action, it can be done once the access is given.
  }

  const formFields = () => {
    const nwElement = dataForm.map((element) => {
      return element.dataType !== 'select' ? (
        <FormField key={element.id} data={element} setDataForm={setDataForm} />
      ) : (
        <FormSelectionField
          key={element.id}
          data={element}
          setDataForm={setDataForm}
        />
      )
    })
    return nwElement
  }

  return (
    <div className="content--item">
      {isFormPopulated === FORM_LOADED_STATE ? (
        <LoadingSuccess />
      ) : (
        <form>
          {formFields()}
          <div className="container--button">
            <button
              onClick={(event) => handleOnClick(event)}
              className="button--form"
              disabled={status === STATUS.loading ? true : false}
            >
              {status === STATUS.loading ? (
                <PiCircleDashedBold className="icon--spin" />
              ) : (
                'Generar'
              )}
            </button>
          </div>
          {isFormPopulated === FORM_NOT_LOADED_STATE ? (
            <div className="pop--message">
              {' '}
              <BiSolidErrorCircle />{' '}
              <p>Por favor, complete todos los campos del formulario.</p>
            </div>
          ) : (
            ''
          )}
        </form>
      )}
    </div>
  )
}

export default FormCustomReports
