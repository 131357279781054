// icons imports
import { PiCircleDashedBold } from 'react-icons/pi'
// third party imports
import { useNavigate } from 'react-router'
// locals imports
import postLoginData from '../../libs/postLoginData'

const LoginButton = ({
  setPostStatus,
  setPostErrors,
  setIsLogged,
  formData,
  logStatus,
  postStatus,
  setUserData
}) => {
  const navigate = useNavigate()

  // it controls whether the user was logged or not.
  const isNotLogged =
    postStatus === logStatus.initial || postStatus === logStatus.error

  // this function handles the form submit.
  const handleSubmit = (event) => {
    event.preventDefault()

    const body = `username=${encodeURIComponent(
      formData.user
    )}&password=${encodeURIComponent(formData.password)}`

    const url = process.env.REACT_APP_WS_LOGIN

    setPostStatus(logStatus.loading)

    // Posting data.
    const postResponse = async () => {
      const response = await postLoginData(body, url)
      if (response.status === 'ok') {
        setPostStatus(logStatus.susses)
        setIsLogged(true)
        setUserData(response)
        navigate('/home')
      }

      if (response.status === 'error') {
        setPostStatus(logStatus.error)
        setPostErrors(response)
      }
      return response
    }
    postResponse()
  }

  return (
    <div className="container--button">
      <button
        className="button--form--style"
        onClick={(event) => handleSubmit(event)}
        disabled={postStatus === logStatus.loading ? true : false}
        type="submit"
      >
        {isNotLogged ? 'LOGIN' : <PiCircleDashedBold className="icon--spin" />}
      </button>
    </div>
  )
}

export default LoginButton
