import { MdArrowForwardIos } from 'react-icons/md'
import { useState, useEffect } from 'react'
import fetchData from '../../../libs/clients/fetchData'

const GoToPage = ({
  page,
  range,
  setData,
  setPage,
  commonUrl,
  setErrorModal
}) => {
  const { parseUrl, urlDateReplaced, urlType } = commonUrl

  // The holder represent value that the input can hold if the user change it.
  const currentPage = parseInt(page) + 1
  const [holder, setHolder] = useState(currentPage)
  const [dataFetched, setDataFetched] = useState(false)

  // This function triggers the fetch action onces the user clicked the button to search a page taken by the input.
  const handleOnSearchPage = async (event) => {
    event.preventDefault()
    const actualValue = parseInt(holder - 1)
    if (holder < range && actualValue >= 0) {
      // const dataFetched = fetchData(holder, walletId, dataForm.fromDate, dataForm.toDate, dataForm.type);

      const url = `${parseUrl}${urlDateReplaced}${actualValue}${urlType}`

      try {
        const data = await fetchData(url)
        setDataFetched(data)
        setPage(actualValue)
      } catch (error) {
        setErrorModal({ hadError: true, errorMessage: error.message })
      }
    }
  }

  useEffect(() => {
    if (dataFetched) {
      setData((prev) => {
        return {
          ...prev,
          dataFetched: dataFetched
        }
      })
    }
  }, [dataFetched])

  // This function collects the value of the page that the user wants to visit.
  const handleOnChange = (event) => {
    setHolder(event.target.value)
  }

  useEffect(() => {
    setHolder(page)
  }, [])

  return (
    <div className="go--to--page--container">
      <p>Ir a pagina</p>
      <input
        type="text"
        value={holder}
        onChange={(event) => handleOnChange(event)}
      />
      <button onClick={(event) => handleOnSearchPage(event)}>
        <MdArrowForwardIos />
      </button>
    </div>
  )
}

export default GoToPage
