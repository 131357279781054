const SimpleTable = ({ tableHeaders, tableContent, tableName, addButton }) => {
  const header = tableHeaders.map((title, index) => {
    const thStyle = 'item--start'
    return (
      <th className={thStyle} key={index}>
        {' '}
        {title}{' '}
      </th>
    )
  })

  return (
    <div className="table--container">
      <table className="table--style--no--borders">
        {tableName && (
          <caption>
            {tableName}
            {addButton}
          </caption>
        )}
        <thead>
          <tr>{header}</tr>
        </thead>
        <tbody>{tableContent}</tbody>
      </table>
    </div>
  )
}

export default SimpleTable
