import fetchData from './fetchData'

const locationsLoader = async () => {
  const orgCode = JSON.parse(localStorage.getItem('userOrgCod'))
  const locationUrl = process.env.REACT_APP_WS_LISTADO_LOCALIDADES.replace(
    ':codigo',
    orgCode
  )

  try {
    const locations = await fetchData(locationUrl)
    return locations.localidades
  } catch (error) {
    throw Error(error.message)
  }
}
export default locationsLoader
