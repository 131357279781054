import React from 'react'
import { Modal } from 'react-bootstrap'

interface Props {
  title?: string
  children?: React.JSX.Element
  openModal: boolean
  onClose?: () => void
}

const ModalCustom = ({ openModal, onClose, title, children }: Props) => {
  return (
    <Modal centered show={openModal} onHide={onClose}>
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: '#28323E', color: 'white' }}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

export default ModalCustom
