export const STATUS = {
  loading: 'loading',
  error: 'error',
  success: 'success',
  initial: 'initial',
  expired: 'expired'
}

export enum Status {
  loading = 'loading',
  error = 'error',
  success = 'success',
  initial = 'initial',
  expired = 'expired',
}

export const VALIDATION_FORM = {
  userLength: 8,
  userPattern: /^\d+|[^@\s]+@[^@\s]+\.\w{2,}$/
}

export const LOCAL_STORAGE_CLIENTS = {
  wallet: {
    form: 'walletFormData',
    tablePage: 'walletPageTable',
    url: 'walletUrl'
  },
  coupons: {
    form: 'couponsFormData',
    tablePage: 'couponsTablePage',
    url: 'couponsUrl'
  },
  withDrawal: {
    form: 'withDrawalFormData',
    tablePage: 'withDrawalTablePage',
    url: 'withDrawalUrl'
  },
  agencies: {
    form: 'agenciesFormData',
    tablePage: 'agenciesPage',
    url: 'agenciesUrl'
  },
  uifList: {
    form: 'uifFormData',
    tablePage: 'uifTablePage',
    url: 'uifUrl'
  },
  exclusion: {
    form: 'exclusionFormData',
    tablePage: 'exclusionTablePage',
    url: 'exclusionUrl'
  },
  reclaim: {
    form: 'reclaimFormData',
    tablePage: 'reclaimTablePage',
    url: 'reclaimUrl'
  }
}

export const LOCAL_STORAGE_SYSTEM = {
  agency: {
    form: 'agencyFromData',
    tablePage: 'agencyPageTable',
    url: 'agencyUrl'
  },
  operators: {
    form: 'operatorsFormData',
    tablePage: 'operatorsTablePage',
    url: 'operatorsUrl'
  },
  roles: {
    form: 'rolesFormData',
    tablePage: 'rolesTablePage',
    url: 'rolesUrl'
  }
}
