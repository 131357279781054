// react imports
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router'

// icon imports
import { FaEye } from 'react-icons/fa'
import { FaPen } from 'react-icons/fa'
import { AiOutlineFileAdd } from 'react-icons/ai'

// third party imports
import { AnimatePresence } from 'framer-motion'

// local components imports
import NewReclaim from '../../modals/NewReclaim'
import ReclaimView from '../../modals/ReclaimView'
import EditReclaim from '../../modals/EditReclaim'
import TableWallet from '../tables/clients/TableWallet'
import FormClientResults from '../forms/clients/FormClientResults'
import ErrorHandler from '../../utils/ErrorHandler'
import Information from '../../modals/Information'

// libs imports
import fetchData from '../../libs/clients/fetchData'
import getCurrentDate from '../../libs/shared/getCurrentDate'
import getFormattedDate from '../../libs/shared/getFormattedDate'
import getReclaimsState from '../../libs/clients/getReclaimsState'

// constants imports
import { LOCAL_STORAGE_CLIENTS, STATUS } from '../../utils/constants'

// hooks imports
import { useDataClient } from '../../utils/custom-hooks/useDataClient'

// ====================================================================== || RECLAIM || ====================================================================================== //

const Reclaims = ({ setIsLogged }) => {
  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const [infoModal, setInfoModal] = useState({
    isThereInfo: false,
    errorMessage: 'Error conexión'
  })

  const localUrl = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CLIENTS.reclaim.url)
  )

  const [commonUrl, setCommonUrl] = useState(localUrl)

  const formStructure = {
    fromDate: '',
    toDate: getCurrentDate()
  }
  const { itemData, setItemData, page, setPage } = useDataClient(
    LOCAL_STORAGE_CLIENTS.reclaim.form,
    LOCAL_STORAGE_CLIENTS.reclaim.tablePage,
    formStructure
  )

  const [newReclaim, setNewReclaim] = useState(false)

  const [rawData] = useOutletContext()
  const data = rawData.dataBilletera

  // This state is true if the form was filled correctly. On it depends whether the table is shown or not.
  const [dataStatus, setDataStatus] = useState(STATUS.initial)

  const [itemSelectedId, setItemSelectedId] = useState(false)

  const [previewReclaim, setPreviewReclaim] = useState(false)

  const [editReclaim, setEditReclaim] = useState(false)

  const [contentReclaim, setContentReclaim] = useState(false)

  const [flaws, setFlaws] = useState(false)

  const [tableContent, setTableContent] = useState(0)

  // It stores the movementTypes to features them as option in the form.
  // To make the fetch it requires the organization cod as parameter.
  const states = getReclaimsState()

  // Based on the gamesType it builds the options with this data.
  const buildingOptions = states.map((game, index) => {
    return (
      <option key={index} value={game}>
        {game}
      </option>
    )
  })

  const formFields = [
    {
      type: 'date',
      label: 'Desde',
      name: 'fromDate'
    },
    {
      type: 'date',
      label: 'Hasta',
      name: 'toDate'
    },
    {
      type: 'select',
      label: 'Estado',
      name: 'state',
      default: 'Todos',
      options: buildingOptions
    }
  ]
  const tableHeaders = [
    'Fecha Apertura',
    'Fecha Modificacion',
    'Abieto',
    'Nombre Falla',
    'Ver Reclamo',
    'Editar Reclamo'
  ]

  const getFlaws = async () => {
    const url = process.env.REACT_APP_WS_LISTADO_FALLAS
    try {
      const response = await fetchData(url)
      setFlaws(response)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
    }
  }

  useEffect(() => {
    getFlaws()
  }, [])

  const getReclaim = async (id) => {
    const url = process.env.REACT_APP_WS_VER_RECLAMO.replace(':idReclamo', id)
    let response = null

    try {
      response = await fetchData(url)
      setContentReclaim(response)
      setPreviewReclaim(true)
    } catch (error) {
      if (error.cod === process.env.REACT_APP_CODIGO_CERRO_SESION)
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
      else setErrorModal({ hadError: true, errorMessage: error.message })
    }

    return response
  }

  const handlePreviewOnclick = (event, id) => {
    event.preventDefault()

    getReclaim(id)
  }

  const handleEditOnClick = (event, id) => {
    event.preventDefault()

    setItemSelectedId(id)
    setEditReclaim(true)
  }

  // This hook listens if there is a change in the formData state, and if was so change the isValidate state.
  useEffect(() => {
    if (itemData.dataFetched) {
      if (itemData.dataFetched.totalItems !== 0) setDataStatus(STATUS.success)
      else setDataStatus(STATUS.error)

      const content = itemData.dataFetched.items.map((reclaim, index) => {
        return (
          <tr key={index} className={index % 2 === 0 ? 'add--row--color' : ''}>
            <td>{getFormattedDate(reclaim.fechaApertura)}</td>
            <td className="item--center">
              {' '}
              {getFormattedDate(reclaim.fechaModificacion)}{' '}
            </td>
            <td className="item--center">
              {' '}
              <input
                type="checkbox"
                defaultChecked={reclaim.abierto ? 'checked' : ''}
                disabled
              />{' '}
            </td>
            <td className="item--center"> {reclaim.nombreFalla} </td>
            <td className="item--center">
              {' '}
              <button
                className="button--preview"
                onClick={(event) => handlePreviewOnclick(event, reclaim.id)}
              >
                {' '}
                <FaEye className="preview--icon" />{' '}
              </button>
            </td>
            <td className="item--center">
              {' '}
              <button
                className="button--preview"
                onClick={(event) => handleEditOnClick(event, reclaim.id)}
              >
                {' '}
                <FaPen className="pen--icon" />{' '}
              </button>
            </td>
          </tr>
        )
      })
      setTableContent(content)
    }
  }, [itemData])

  const handleNewButton = (event) => {
    event.preventDefault()

    setNewReclaim(true)
  }

  const extraButtons = [
    <button
      title="Nuevo reclamo"
      className="button--form wallet--button"
      onClick={(event) => handleNewButton(event)}
    >
      <AiOutlineFileAdd className="edit--icon" />
    </button>
  ]

  const requestPath = (dataHolder) => {
    const walletId = data[0].billeteraId

    const parseUrl = `${process.env.REACT_APP_WS_LISTADO_RECLAMOS}?billeteraId=${walletId}`

    const urlDateReplaced = `&desde=${dataHolder.fromDate.replaceAll(
      '-',
      ''
    )}&hasta=${dataHolder.toDate.replaceAll('-', '')}&page=`
    const url = {
      parseUrl: parseUrl,
      urlDateReplaced: urlDateReplaced,
      urlType: ''
    }

    setCommonUrl(url)
    localStorage.setItem(LOCAL_STORAGE_CLIENTS.reclaim.url, JSON.stringify(url))

    return `${parseUrl}${urlDateReplaced}${0}`
  }

  return (
    <>
      <div className="data--result--container">
        <FormClientResults
          formFields={formFields}
          data={itemData}
          setFormData={setItemData}
          setPage={setPage}
          extraButtons={extraButtons}
          storageName={LOCAL_STORAGE_CLIENTS.reclaim.form}
          setCommonUrl={setCommonUrl}
          setErrorModal={setErrorModal}
          requestPath={requestPath}
        />
        <AnimatePresence>
          {newReclaim && (
            <NewReclaim
              newReclaim={newReclaim}
              setNewReclaim={setNewReclaim}
              walletId={data[0].billeteraId}
              flaws={flaws}
              setErrorModal={setErrorModal}
              setInfoModal={setInfoModal}
            />
          )}

          {previewReclaim && (
            <ReclaimView
              previewReclaim={previewReclaim}
              setPreviewReclaim={setPreviewReclaim}
              data={contentReclaim}
            />
          )}

          {editReclaim && (
            <EditReclaim
              editReclaim={editReclaim}
              setEditReclaim={setEditReclaim}
              flaws={flaws}
              reclaimId={itemSelectedId}
              setInfoModal={setInfoModal}
              setErrorModal={setErrorModal}
            />
          )}
        </AnimatePresence>

        {dataStatus === STATUS.success ? (
          <TableWallet
            data={itemData}
            setFormData={setItemData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            tableContent={tableContent}
            commonUrl={commonUrl}
            setErrorModal={setErrorModal}
          />
        ) : dataStatus === STATUS.initial ? (
          <p></p>
        ) : (
          <p className="flex--container--message">
            No hay datos disponibles para la consulta ingresada.
          </p>
        )}
      </div>
      {errorModal.hadError && (
        <ErrorHandler
          setIsLogged={setIsLogged}
          errorMessage={errorModal.errorMessage}
        />
      )}
      {infoModal.isThereInfo && (
        <Information setInfoModal={setInfoModal} infoModal={infoModal} />
      )}
    </>
  )
}

export default Reclaims
