// third party libs imports
import { nanoid } from 'nanoid'

// icons imports
import { FaCheckCircle } from 'react-icons/fa'

// ====================================================================== || FORM SELECTION FIELD || ====================================================================================== //

// It is in charge to construct dynamically the options on the select input, with the data gotten from parameter
const FormSelectionField = ({ data, setDataForm }) => {
  // Modulating the data to build the options elements with the correct data.
  const options = data.options.map((element) => {
    return (
      <option key={nanoid()} value={element.codigo}>
        {element.descripcion}
      </option>
    )
  })

  const handleOnChange = (event) => {
    setDataForm((prev) => {
      const nwValues = prev.map((element) => {
        if (element.name === event.target.name) {
          return {
            ...element,
            value: event.target.value
          }
        }
        return element
      })
      return nwValues
    })
  }

  return (
    <div className="form--level">
      <label className="form--label">
        {data.description}
        {data.required ? <span> *</span> : ''}
      </label>
      <div className="form--input--container">
        <select
          className="form--input"
          name={data.name}
          value={data.value}
          onChange={(event) => handleOnChange(event)}
        >
          <option value="">--Seleccione una opcion--</option>
          {options}
        </select>
        <span className={data.value ? 'form--checked' : 'form--not--checked'}>
          {' '}
          <FaCheckCircle />{' '}
        </span>
      </div>
    </div>
  )
}

export default FormSelectionField
