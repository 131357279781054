import getFormattedDate from '../libs/shared/getFormattedDate'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useEffect, useRef } from 'react'
import { useAnimate, usePresence } from 'framer-motion'

const Preview = ({ setPreview, couponData, preview }) => {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()
  const reference = useRef()

  const formatCurrency = (value) => {
    let numberFormat = new Intl.NumberFormat('es-ar', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2
    })
    return numberFormat.format(value)
  }

  const couponResume = couponData.cupon.payload.apuestas.map(
    (gamble, index) => {
      return (
        <tr key={index}>
          <td>{gamble.nro}</td>
          <td>{gamble.alc}</td>
          <td>{gamble.imp}</td>
        </tr>
      )
    }
  )

  const lotteries = couponData.cupon.payload.sorteos.map((lottery, index) => {
    return (
      <tr key={index}>
        <td>{lottery.sorteo}</td>
        <td>{lottery.loteria}</td>
      </tr>
    )
  })

  const extracts = couponData.cupon.payload.extractos !== null ? true : false
  const excerpts = !extracts
    ? ''
    : couponData.cupon.payload.extractos[0].extractosQuiniela.map(
        (excerpt, index) => {
          return (
            <tr key={index}>
              <td>{excerpt.posicion}</td>
              <td>{excerpt.numero}</td>
            </tr>
          )
        }
      )

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        preview &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setPreview(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [preview])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])
  return (
    <div id="modal-container" className="modal--container" ref={scope}>
      <div className="modal--content modal--scroll" ref={reference}>
        <div className="coupon--title">
          <h3>{couponData.cupon.evento}</h3>
          <button onClick={() => setPreview(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="coupon--header">
          <div>
            <p>
              <span>Emitido:</span> {getFormattedDate(couponData.cupon.fecEmi)}
            </p>
            <p>
              <span>Sorteo:</span>{' '}
              {getFormattedDate(couponData.cupon.fecSorteo)}
            </p>
          </div>
          <div>
            <p>
              <span>Total del Cupón:</span>{' '}
              {formatCurrency(couponData.cupon.total)}
            </p>
            {couponData.cupon.premio && (
              <p>
                <span>Premio:</span>
                {formatCurrency(couponData.cupon.premio)}
              </p>
            )}
          </div>
        </div>
        <div className="coupon--data">
          <div>
            <p>
              {' '}
              <span>Número de control:</span> {couponData.cupon.ctrlp}
            </p>
            <p>
              {' '}
              <span>Número de cupón:</span> {couponData.cupon.nuc}
            </p>
          </div>
          <div className="table--container">
            <table className="table--style">
              <caption>Apuestas</caption>
              <thead>
                <tr>
                  <th>Número</th>
                  <th>Alcance</th>
                  <th>Importe</th>
                </tr>
              </thead>
              <tbody>{couponResume}</tbody>
            </table>
          </div>
          <div className="table--container">
            <table className="table--style">
              <caption>Sorteos</caption>
              <thead>
                <tr>
                  <th>Sorteo</th>
                  <th>Extracto</th>
                </tr>
              </thead>
              <tbody>{lotteries}</tbody>
            </table>
          </div>
          {extracts ? (
            <div className="table--container">
              <table className="table--style">
                <caption>Extractos</caption>
                <thead>
                  <tr>
                    <th>Posición</th>
                    <th>Número</th>
                  </tr>
                </thead>
                <tbody>{excerpts}</tbody>
              </table>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default Preview
