const postData = async (requestBody, url) => {
  const keySession = JSON.parse(sessionStorage.getItem('keySession'))

  const controller = new AbortController()
  const timeout = process.env.REACT_APP_FETCH_TIMEOUT
  const { signal } = controller

  const timeoutId = setTimeout(() => {
    controller.abort()
  }, timeout)

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keySession}`
      },
      body: JSON.stringify(requestBody),
      signal
    })

    clearTimeout(timeoutId)

    // if (!response.ok) {
    //     const isSessionExpired = parseInt(response.status) === parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION)
    //     if (isSessionExpired) throw Error('Sesión expirada')
    // }

    const data = await response.json()

    if (data.status === 'error') {
      console.log(data)

      // Handle specific errors here based on the error code or message.
      const errorMessage = data.errores[0].error
      const errorCode = data.errores[0].codigo

      const error = new Error(errorMessage)
      error.code = errorCode
      throw error
    }

    return data
  } catch (error) {
    console.log(error)
    const e = new Error(error.message)
    e.code = error.code
    throw e
  } finally {
    clearTimeout(timeoutId)
  }
}

export default postData
