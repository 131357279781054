import { FormFieldsAgencies } from "components/system/SystemTypes";
import fetchData from "libs/clients/fetchData"
import { ADataEdited } from 'components/system/SystemTypes'
import postData from "libs/postData"

// ========================== | AGENCY |========================================

const getAgencies = async (data: FormFieldsAgencies, page: number) => {
  const { organization, agencyCode, location, status } = data

  const baseUrl = process.env.REACT_APP_WS_LISTADO_AGENCIAS;
  let url = `${baseUrl}?codigoOrganizacion=${organization}`

  if (agencyCode) {
    url += `&codigo=${agencyCode}`
  }
  if (location) {
    url += `&localidad=${location}`
  }
  if (status) {
    url += `&habilitada=${status}`
  }
  url += `&page=${page}`

  url += `&size=10`

  try {
    const response = await fetchData(url)
    return response
  } catch (error: any) {
    throw (error)
  }
}

const syncAgencies = async (organization: any) => {
  const baseUrl = process.env.REACT_APP_WS_SINCRONIZAR_AGENCIAS
  const parameters = `?codigoOrganizacion=${organization}`
  const url = baseUrl + parameters

  try {
    const response = await fetchData(url)
    return response
  } catch (error: any) {
    throw (error)
  }

}

const getAgency = async (id: string) => {
  const url = process.env.REACT_APP_WS_DETALLE_AGENCIAS?.replace(
    ':idAgencia',
    id
  )

  try {
    const response: any = await fetchData(url)
    return response
  } catch (error: any) {
    throw error
  }
}
type FormFields = {
  organization: string
  user: string
  name: string
  lastName: string
}

// ======================= | AGENCY OPERATOR | =================================

const getOperatorsList = async (formFields: FormFields, page: number) => {
  const { organization, user, name, lastName } = formFields
  const base = process.env.REACT_APP_WS_LISTADO_OPERADORES
  let url: string = `${base}?codigoOrganizacion=${organization}`

  if (user) {
    url += `&username=${user}`
  }

  if (name) {
    url += `&nombre=${name}`
  }

  if (lastName) {
    url += `&apellido=${lastName}`
  }

  url += `&page=${page}`
  url += `&size=10`

  try {
    const response = await fetchData(url)
    return response
  } catch (error: any) {
    throw error
  }

}

const getOperator = async (id: any) => {
  const url = process.env.REACT_APP_WS_DETALLE_OPERADORES?.replace(':idOperador', id)

  try {
    const response = await fetchData(url)
    return response
  } catch (error: any) {
    throw error
  }
}

// ======================= | AGENCY EDITOR | =================================

const postAgency = async (parameters: ADataEdited, id: string) => {
  const url = process.env.REACT_APP_WS_GUARDAR_DETALLE_AGENCIAS !== null
    ? process.env.REACT_APP_WS_GUARDAR_DETALLE_AGENCIAS?.replace(
      ':idAgencia',
      id
    )
    : 'invalid'

  try {
    const response = await postData(parameters, url)
    return response
  } catch (error: any) {
    throw error
  }

}
const systemServices = {
  getAgencies,
  syncAgencies,
  getAgency,
  getOperatorsList,
  getOperator,
  postAgency
}

export default systemServices
