const getCustomFormFields = (
  formItems,
  data,
  setDataEdited,
  dataEdited,
  editOperator
) => {
  const handleOnChange = (event) => {
    setDataEdited((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const elementsArray = formItems.map((element, index) => {
    let elementFound = ' '

    for (const key in data) {
      if (key === element.item) elementFound = data[key]
    }

    return (
      <div key={index} className="field--form form--row">
        <label className="form--label">{element.name}</label>
        {typeof elementFound === 'boolean' ? (
          <input
            className={
              element.isEditable && editOperator
                ? 'form--input'
                : 'form--input--client'
            }
            defaultChecked={elementFound ? 'checked' : ''}
            type="checkbox"
            name={element.item}
            value={element.isEditable ? dataEdited.active : elementFound}
            onChange={(event) => handleOnChange(event)}
          />
        ) : (
          <input
            className={
              element.isEditable && editOperator
                ? 'form--input'
                : 'form--input--client'
            }
            type="text"
            name={element.item}
            value={element.isEditable ? dataEdited[element.item] : elementFound}
            onChange={(event) => handleOnChange(event)}
          />
        )}
      </div>
    )
  })
  return elementsArray
}

export default getCustomFormFields
