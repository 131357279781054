// icons imports
import { BiKey } from 'react-icons/bi'

const LoginHeader = () => {
  return (
    <>
      <div className="key--icon--container">
        <BiKey className="key--icon" />
      </div>
      <div className="form--title">ADMIN PANEL</div>
    </>
  )
}
export default LoginHeader
