import { useEffect, useRef, useState } from 'react'
import { useAnimate, usePresence } from 'framer-motion'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'

const ChangePassword = ({ preview, setPreview, data, title }) => {
  // Hook that controls the modal behavior.
  const reference = useRef()
  const [scope, animate] = useAnimate()
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    const collapse = (e) => {
      const closeModal =
        preview &&
        reference.current &&
        !reference.current.contains(e.target) &&
        e.target.id === 'modal-container'
      if (closeModal) setPreview(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [preview])

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.1 }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [1, 0] },
          { duration: 0.5, delay: 0.1 },
          { ease: 'easeInOut' }
        )
        safeToRemove()
      }
      exitAnimation()
    }
  }, [isPresent])

  return (
    <div
      id="modal-container"
      className="modal--container bg--transparent"
      ref={scope}
    >
      <div className="modal--content modal--scroll " ref={reference}>
        <div className="coupon--title">
          <h3>{title}</h3>
          <button onClick={() => setPreview(false)}>
            {' '}
            <AiFillCloseCircle className="close--icon" />{' '}
          </button>
        </div>
        <div className="solver--data">{data}</div>
      </div>
    </div>
  )
}

export default ChangePassword
