import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const LoadingSuccess = () => {
  return (
    <div className="message--loading--container">
      <FaCheckCircle className="success--icon" />
      <div>
        <h2>Generado!</h2>
        <p>El archivo fue generador éxitosamente.</p>
      </div>
      <Link className="button--form" to={'/home'}>
        {' '}
        Ir al menú principal{' '}
      </Link>
    </div>
  )
}

export default LoadingSuccess
