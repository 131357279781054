// react import
import React from 'react'

// react dom import
import ReactDOM from 'react-dom/client'

// styles import
import './index.css'

// component import
import App from './App'

import "bootstrap/dist/css/bootstrap.min.css";
import { AppProvider } from 'context/AppContext';

// ============================ | INDEX | ======================================

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
)
