// react imports
import { useState } from 'react'

// libs imports
import postChange from '../../../libs/massive-change/postChange'
import postData from '../../../libs/postData'

// components imports
import ErrorHandler from '../../../utils/ErrorHandler'
import Information from '../../../modals/Information'

// third party libraries
import { AnimatePresence } from 'framer-motion'
import { STATUS } from '../../../utils/constants'

// icons imports
import { PiCircleDashedBold } from 'react-icons/pi'
import { handleOnChange } from '../../../utils/utils'

// ====================================================================== || FORM MASSIVE CHANGE || ====================================================================================== //

const FormMassiveChange = ({
  setChangeResponse,
  setResponse,
  setIsLogged,
  organizations
}) => {
  // it handle the status of the component
  const [status, setStatus] = useState(STATUS.initial)

  // This state handles the errors
  const [errorModal, setErrorModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  // This state handles information about the action the user wants to execute.
  const [infoModal, setInfoModal] = useState({
    hadError: false,
    errorMessage: 'Error conexión'
  })

  const initialFormData = {
    organization: organizations[0].codigo,
    oldAgency: '',
    newAgency: ''
  }

  const [formData, setFormData] = useState(initialFormData)

  const postChangeData = async () => {
    const parameters = {
      agenciaAntigua: formData.oldAgency,
      agenciaNueva: formData.newAgency,
      codigoOrganizacion: formData.organization
    }

    const url = process.env.REACT_APP_WS_CAMBIO_MASIVO_AGENCIAS
    setStatus(STATUS.loading)
    try {
      await postData(parameters, url)
      setChangeResponse(true)
      setFormData(initialFormData)
      setStatus(STATUS.initial)
    } catch (error) {
      setStatus(STATUS.initial)
      const sessionExpired = parseInt(process.env.REACT_APP_CODIGO_CERRO_SESION)
      if (error.code === sessionExpired) {
        setErrorModal({ hadError: true, errorMessage: error.message })
      } else {
        setInfoModal({
          isThereInfo: true,
          errorMessage: error.message
        })
      }
    }
  }

  const handleOnClick = (event) => {
    event.preventDefault()
    const isFill = formData.newAgency && formData.oldAgency ? true : false
    setResponse(isFill)
    if (isFill) postChangeData()
  }

  const options = organizations.map((organization, index) => (
    <option key={index} value={organization.codigo}>
      {organization.descripcion}
    </option>
  ))

  return (
    <>
      <form>
        <div className="field--form">
          <label className="form--label">Seleccione Organización</label>
          <select
            className="form--select"
            name="organization"
            value={formData.organization}
            onChange={(event) => handleOnChange(event, setFormData)}
          >
            {options}
          </select>
        </div>
        <div className="field--form">
          <label className="form--label">Agencia vieja</label>
          <input
            name="oldAgency"
            value={formData.oldAgency}
            onChange={(event) => handleOnChange(event, setFormData)}
            type="text"
          />
        </div>
        <div className="field--form">
          <label className="form--label">Agencia nueva</label>
          <input
            name="newAgency"
            value={formData.newAgency}
            onChange={(event) => handleOnChange(event, setFormData)}
            type="text"
          />
        </div>
      </form>
      <div className="container--button">
        <button
          onClick={(event) => handleOnClick(event)}
          className="button--form"
          disabled={status === STATUS.loading ? true : false}
        >
          {status === STATUS.loading ? (
            <PiCircleDashedBold className="icon--spin" />
          ) : (
            'Ejecutar Cambio'
          )}
        </button>
      </div>
      <AnimatePresence>
        {errorModal.hadError && (
          <ErrorHandler
            setIsLogged={setIsLogged}
            errorMessage={errorModal.errorMessage}
          />
        )}
        {infoModal.isThereInfo && (
          <Information setInfoModal={setInfoModal} infoModal={infoModal} />
        )}
      </AnimatePresence>
    </>
  )
}

export default FormMassiveChange
