import { FaUsers, FaFileAlt, FaFileSignature, FaRegSun, FaPlus, FaExchangeAlt } from 'react-icons/fa'

const clientOptions = [
  {
    name: 'Clientes ',
    icon: FaUsers,
    link: '/clientes'
  },
  {
    name: 'Bonificaciones',
    icon: FaPlus,
    link: '/bonus'
  },
  {
    name: 'Transferencias',
    icon: FaExchangeAlt,
    link: '/transferencias'
  },
  {
    name: 'Reportes a medida',
    icon: FaFileSignature,
    link: '/reportes-a-medida'
  },
  {
    name: 'Cambio masivo agencias',
    icon: FaFileAlt,
    link: '/cambio-masivos'
  },
  {
    name: 'Sistemas',
    icon: FaRegSun,
    link: '/sistemas'
  }
]

export default clientOptions
