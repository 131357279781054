import React, { createContext, ReactNode, useContext, useState } from 'react'
import { OrgProp } from 'types/userData'

interface AppContextType {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  organizations: OrgProp[]
  setOrganizations: (value: OrgProp[]) => void
  isLogged: boolean
  setIsLogged: (value: boolean) => void
}

const AppContext = createContext<AppContextType | undefined>(undefined)

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [organizations, setOrganizations] = useState<OrgProp[]>([])
  const [isLogged, setIsLogged] = useState<boolean>(false)

  return (
    <AppContext.Provider
      value={{
        openModal,
        setOpenModal,
        organizations,
        setOrganizations,
        isLogged,
        setIsLogged
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}
