const fetchDataReports = async (url) => {
  const keySession = JSON.parse(sessionStorage.getItem('keySession'))

  const controller = new AbortController()
  const timeout = 20000
  const { signal } = controller

  const timeoutId = setTimeout(() => {
    controller.abort()
  }, timeout)

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${keySession}`
      },
      signal
    })

    clearTimeout(timeoutId)

    const data = response
    console.log(data)

    if (!data.ok) {
      // Handle specific errors here based on the error code or message.
      const errorMessage =
        data.statusText ||
        'No se pudo acceder al servidor. Puede ser debido a que la sesión ha expirado'
      const errorCode = data.status

      const error = new Error(errorMessage)
      error.code = errorCode
      throw error
    }

    return data
  } catch (error) {
    const e = new Error(error.message)
    e.code = error.code
    throw e
  } finally {
    clearTimeout(timeoutId)
  }
}

export default fetchDataReports
