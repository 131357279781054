import React from 'react'
import LogoBg from '../assets/img/logocompleto.png'

export default function BackgroundLandingPage({ isLogged }) {
  return (
    <div>
      {!isLogged && (
        <div className="landing--page--bg">
          <div>
            <img
              className="landing--img"
              id="logoLotline"
              src={LogoBg}
              alt="logo"
            />
          </div>
        </div>
      )}
    </div>
  )
}
