import React from 'react'
import { Pagination } from 'react-bootstrap'

interface PaginatorProps {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Paginator = ({
  totalPages,
  currentPage,
  onPageChange
}: PaginatorProps) => {
  if (totalPages <= 1) return null // No mostrar si hay una sola página

  const handlePageChange = (page: number) => {
    if (page >= 0 && page <= totalPages) {
      onPageChange(page)
    }
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 0}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 0}
      />

      {Array.from({ length: totalPages }, (_, i) => (
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      ))}

      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage + 1 === totalPages}
      />
      <Pagination.Last
        onClick={() => handlePageChange(totalPages - 1)}
        disabled={currentPage + 1 === totalPages}
      />
    </Pagination>
  )
}
