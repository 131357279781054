import {
  Bonus,
  BonusRequest,
  GameResponse,
  ResponseOrganization,
  Types,
  UserName
} from 'types/Bonus.types'
import { HttpClient } from './http-services/HttpClient'
import { HttpRequest } from './http-services/HttpRequest'

const enum URL {
  PROMO_TYPES = '/promo/types',
  PROMO = '/promo',
  PROMO_DISABLE = '/promo/disable',
  CUPON_JUEGOS = '/cupon/juegos',
  ORGANIZACIONES = '/organizaciones',
  PROMO_INACTIVE_USERS_PREWIEW = '/promo/inactive/users/preview',
  PROMO_ID = '/promo/id'
}

const API_OPERADOR_ADMIN = '/api-operador/admin'
//const REPORTES = '/reportes'

const createRequest = () => {
  const httpConfig: HttpRequest = {
    headers: {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('keySession') || '""')}`
    }
  }
  return httpConfig
}

export default class BonusServices {
  private httpClient = new HttpClient(createRequest())

  getOrganizations(): Promise<ResponseOrganization> {
    return this.httpClient.get(`${API_OPERADOR_ADMIN}${URL.ORGANIZACIONES}`, {
      activo: true
    })
  }

  getTypes(): Promise<Types[]> {
    return this.httpClient.get(`${API_OPERADOR_ADMIN}${URL.PROMO_TYPES}`)
  }

  getBonus(idOrganizacion: number): Promise<Bonus[]> {
    return this.httpClient.get(
      `${API_OPERADOR_ADMIN}${URL.PROMO}/${idOrganizacion}`,
      {}
    )
  }

  getBonusId(bonusId: string): Promise<Bonus> {
    return this.httpClient.get(
      `${API_OPERADOR_ADMIN}${URL.PROMO_ID}/${bonusId}`,
      {}
    )
  }

  getGames(idOrganizacion: number): Promise<GameResponse> {
    return this.httpClient.get(`${API_OPERADOR_ADMIN}${URL.CUPON_JUEGOS}`, {
      codigoOrganizacion: idOrganizacion
    })
  }

  getUsersInTypeInactive(
    organizationCode: number,
    date: string,
    subTipo: string
  ): Promise<UserName[]> {
    return this.httpClient.get(
      `${API_OPERADOR_ADMIN}${URL.PROMO_INACTIVE_USERS_PREWIEW}`,
      {
        organizacionCodigo: organizationCode,
        fechaLimite: date,
        subTipo: subTipo
      }
    )
  }

  postBonus(bonus: BonusRequest): Promise<Bonus> {
    return this.httpClient.post(`${API_OPERADOR_ADMIN}${URL.PROMO}`, bonus)
  }

  updateBonusStatus(idBonus: string): Promise<Bonus> {
    return this.httpClient.post(
      `${API_OPERADOR_ADMIN}${URL.PROMO_DISABLE}/${idBonus}`
    )
  }
}
