// react imports
import { useEffect, useRef, useState } from 'react'

// third party library imports
import { Link } from 'react-router-dom'

// icon imports
import { FaRegUser, FaRegBell } from 'react-icons/fa'
import { LuSettings, LuLogOut } from 'react-icons/lu'

// ====================================================================== | USER-MENU | ====================================================================================== //

const UserMenu = ({ setIsLogged, name }) => {
  const reference = useRef()

  const [dropDown, setDropDown] = useState(false)

  const handleDropDown = () => {
    setDropDown((prevState) => !prevState)
  }

  useEffect(() => {
    const collapse = (e) => {
      if (
        dropDown &&
        reference.current &&
        !reference.current.contains(e.target)
      )
        setDropDown(false)
    }

    document.body.addEventListener('click', collapse)
    return () => document.body.removeEventListener('click', collapse)
  }, [dropDown])

  const handleCloseSession = () => {
    localStorage.clear()
    setIsLogged(false)
  }

  return (
    <div className="drop--down--menu" ref={reference}>
      <button onClick={() => handleDropDown()} className="menu--title">
        Hola, {name}
      </button>
      <div className={!dropDown ? 'collapse--content' : 'dropdown-content'}>
        <Link>
          {' '}
          <FaRegUser /> Perfil
        </Link>
        <Link>
          {' '}
          <FaRegBell /> Notificaciones
        </Link>
        <Link>
          {' '}
          <LuSettings /> Configuraciones
        </Link>
        <a onClick={() => handleCloseSession()}>
          {' '}
          <LuLogOut /> Cerrar sesión{' '}
        </a>
      </div>
    </div>
  )
}

export default UserMenu
