// react imports
import { useEffect, useState } from 'react'

// icons imports
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

// libs imports
import fetchData from '../../../libs/clients/fetchData'

const NavFooterBar = ({
  page,
  setPage,
  range,
  setData,
  commonUrl,
  setErrorModal
}) => {
  const { parseUrl, urlDateReplaced, urlType, tipoMovimiento } = commonUrl

  const [dataFetched, setDataFetched] = useState(false)

  const getNewPage = async (newPage) => {
    const url = `${parseUrl}${urlDateReplaced}${newPage}${urlType}${tipoMovimiento}`

    try {
      const holder = await fetchData(url)
      setDataFetched(holder)
      setPage(newPage)
    } catch (error) {
      setErrorModal({ hadError: true, errorMessage: error.message })
    }
  }

  // This function handle the onClick action in the arrows that move the page as forward as backward.
  const handleOnclick = async (event, arrow) => {
    event.preventDefault()

    if (arrow === 'back-arrow' && page - 1 >= 0) getNewPage(page - 1)

    if (arrow === 'forward-arrow' && page + 1 < range) getNewPage(page + 1)
  }

  useEffect(() => {
    if (dataFetched) {
      setData((prev) => {
        return {
          ...prev,
          dataFetched: dataFetched
        }
      })
    }
  }, [dataFetched])

  return (
    <div className="arrows--container">
      <button onClick={(event) => handleOnclick(event, 'back-arrow')}>
        <MdArrowBackIosNew className="arrow--footer--table" />
      </button>
      <div className="current--page">
        <p>{`${page === 0 ? 1 : parseInt(page) + 1} de ${range}`}</p>
      </div>
      <button onClick={(event) => handleOnclick(event, 'forward-arrow')}>
        <MdArrowForwardIos className="arrow--footer--table" />
      </button>
    </div>
  )
}

export default NavFooterBar
